import { Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { FaDotCircle } from "react-icons/fa";
import { colors } from "../../utils/constants";

export default function PollStats({ item }) {
  return (
    <Stack gap={1} flexDirection="row" alignItems="center">
      <Typography fontSize={14} fontWeight={500} color={colors.tableBlack}>
        {item.options?.length !== 0 &&
          item.options?.reduce((a, b) => {
            return a + b.votes.length;
          }, 0)}{" "}
        Votes
      </Typography>
      <FaDotCircle size={6} color="#383535" />
      <Typography fontSize={14} fontWeight={500} color={colors.tableBlack}>
        {moment()
          .tz("Asia/Kolkata")
          .isAfter(moment.tz(item.endDate, "Asia/Kolkata"))
          ? "Poll has ended"
          : `${Math.floor(
              moment
                .duration(
                  moment
                    .tz(item.endDate, "Asia/Kolkata")
                    .diff(moment.tz(new Date().toUTCString(), "Asia/Kolkata"))
                )
                .asDays()
            )} days ${
              Math.floor(
                moment
                  .duration(
                    moment
                      .tz(item.endDate, "Asia/Kolkata")
                      .diff(moment.tz(new Date().toUTCString(), "Asia/Kolkata"))
                  )
                  .asHours()
              ) % 24
            } hours ${
              Math.floor(
                moment
                  .duration(
                    moment
                      .tz(item.endDate, "Asia/Kolkata")
                      .diff(moment.tz(new Date().toUTCString(), "Asia/Kolkata"))
                  )
                  .asMinutes()
              ) % 60
            } minutes left`}
      </Typography>
    </Stack>
  );
}
