import React, { useContext } from "react";
import { UserDetailsContext } from "../../Context/UserDetailsContext";
import axios from "axios";
import CustomSwitch from "../../Components/CustomSwitch";
import PageWrapper from "../../Layout/PageWrapper";
import PageTitle from "../../Components/PageTitle";
import CardContainer from "../../Components/CardContainer";
import { Grid, Stack, Typography } from "@mui/material";

export default function Settings() {
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);

  const emailSwitches = [
    {
      name: "Comments",
      desc: "Comments on your post and replies to comments.",
      code: "comments",
    },
    {
      name: "Keep Track",
      desc: "These are notifications to keep you updated about new comments or replies on a post.",
      code: "tacking",
    },
    {
      name: "Mentions & Tags",
      desc: "These are notifications for when someone tags you in a comment, post or replies.",
      code: "mentions",
    },
    {
      name: "Reminders",
      desc: "These are notifications to remind you of updates you might have missed.",
      code: "reminders",
    },
    {
      name: "More activity about you",
      desc: "These are notifications for likes, karma and other reactions to your posts, and more.",
      code: "activity",
    },
  ];

  const _updateEmailNotification = async (obj) => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const data = {
      obj,
    };
    await axios.put(
      process.env.REACT_APP_URL + "updateEmailNotification",
      data,
      options
    );
  };

  const onChange = (code) => {
    let obj = {
      comments:
        code === "comments"
          ? !userDetails.email_notifications.comments
          : userDetails.email_notifications.comments,
      tacking:
        code === "tacking"
          ? !userDetails.email_notifications.tacking
          : userDetails.email_notifications.tacking,
      mentions:
        code === "mentions"
          ? !userDetails.email_notifications.mentions
          : userDetails.email_notifications.mentions,
      reminders:
        code === "reminders"
          ? !userDetails.email_notifications.reminders
          : userDetails.email_notifications.reminders,
      activity:
        code === "activity"
          ? !userDetails.email_notifications.activity
          : userDetails.email_notifications.activity,
    };
    setUserDetails({
      ...userDetails,
      email_notifications: obj,
    });
    _updateEmailNotification(obj);
  };

  return (
    <PageWrapper hideRightCards>
      <PageTitle
        title="Notification Settings"
        subTitle="Select the kinds of notifications you get about your activities and recommendations."
      />

      <CardContainer>
        <Grid container>
          <Grid xs={12} md={5} item>
            <PageTitle
              title="Email Notifications"
              subTitle="Get emails to find out what’s going on when you’re not online. You can turn these off."
            />
          </Grid>

          <Grid xs={12} md={7}>
            <Stack gap={1}>
              {emailSwitches.map((s) => (
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <CustomSwitch
                    onChange={() => onChange(s.code)}
                    checked={userDetails?.email_notifications[s.code]}
                  />
                  <Stack gap="2px">
                    <Typography fontSize={16} fontWeight={500}>
                      {s.name}
                    </Typography>
                    <Typography fontSize={14} fontWeight={400}>
                      {s.desc}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </CardContainer>
    </PageWrapper>
  );
}
