import React from "react";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import CreateProfanity from "./Components/CreateProfanity";
import { IoIosRemoveCircle } from "react-icons/io";
import {
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { colors } from "../../utils/constants";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../../Layout/PageWrapper";

export default function Profanity() {
  const [profanities, setProfanities] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const navigate = useNavigate();

  const _getprofanities = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getprofanities",
      options
    );
    if (res.status == 200) {
      setProfanities(res.data);
    }
  };

  const _deleteProfanity = async (id) => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.delete(
      process.env.REACT_APP_URL + "deleteProfanity/" + id,
      options
    );
    if (res.status == 200) {
      _getprofanities();
    }
  };

  useEffect(() => {
    _getprofanities();
  }, []);

  return (
    <PageWrapper hideRightCards>
      <Modal footer={null} closeIcon={null} open={createModal}>
        <CreateProfanity
          fetch={_getprofanities}
          setCreateModal={setCreateModal}
        />
      </Modal>

      <Stack
        gap={2}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <IconButton onClick={() => navigate(-1)} sx={{ p: 0.2 }}>
          <ChevronLeft sx={{ color: colors.tableBlack, fontSize: 34 }} />
        </IconButton>
        <Typography fontSize={24} fontWeight={500} color={colors.tableBlack}>
          Banned Words
        </Typography>
        <Divider sx={{ flex: 1 }} />
        <Button
          size="large"
          type="primary"
          onClick={() => setCreateModal(true)}
        >
          Add Profanity
        </Button>
      </Stack>

      <TableContainer sx={{ bgcolor: colors.WHITE }}>
        <Table>
          <TableHead sx={{ bgcolor: "#deecfa" }}>
            <TableCell>S.No</TableCell>
            <TableCell>Word</TableCell>
            <TableCell>Creation Date</TableCell>
            <TableCell />
          </TableHead>

          <TableBody>
            {profanities.length > 0 &&
              profanities.map((item, index) => (
                <TableRow key={index} style={{ height: 50 }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{moment(item.createdAt).format("ll")}</TableCell>
                  <TableCell>
                    <IoIosRemoveCircle
                      onClick={(id) => _deleteProfanity(item.id)}
                      size={24}
                      style={{ cursor: "pointer" }}
                      color="red"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageWrapper>
  );
}
