import Input from "../Components/Input";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Stack, Typography } from "@mui/material";
import LoginCard from "./Components/LoginCard";
import LoginHeader from "./Components/LoginHeader";
import { Button } from "antd";
import { colors } from "../utils/constants";

function CreatePassword() {
  const location = useLocation();
  const [successVisible, setSuccessVisible] = useState(false);

  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const token = params.get("token");
  const [errorVisible, setErrorVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    Password: "",
    repeatPassword: "",
  });

  const _createPassword = async (e) => {
    e.preventDefault();
    if (data.Password !== data.repeatPassword) {
      return setErrorVisible("The password entered does not match.");
    }
    try {
      setLoading(true);
      let res = await axios.post(process.env.REACT_APP_URL + "createpassword", {
        Password: data.Password,
        token: token,
      });
      if (res.status == 200) {
        setSuccessVisible("Password Created successfully.");
        setLoading(false);
      } else if (res.status == 202) {
        setLoading(false);
        setErrorVisible(res.data.msg);
        setTimeout(() => {
          setErrorVisible(false);
        }, 3000);
      }
    } catch (err) {
      if (err) {
        setLoading(false);
        setErrorVisible(err.response.data);
        setTimeout(() => {
          setErrorVisible(false);
        }, 3000);
      }
    }
  };

  return (
    <LoginCard>
      <LoginHeader
        title="Create Password"
        subTitle="Create your own password"
      />

      <Input
        label="New password"
        onChange={(e) => setData({ ...data, Password: e.target.value })}
        value={data.Password}
        mb={48}
        type="password"
        placeholder=""
      />
      <Input
        label="Re-Enter New Password"
        onChange={(e) => setData({ ...data, repeatPassword: e.target.value })}
        value={data.repeatPassword}
        type="password"
        placeholder=""
      />

      <Stack height={2} justifyContent="center">
        <Typography fontSize={14} fontWeight={500} color={colors.themeRed}>
          {errorVisible}
        </Typography>
      </Stack>

      <Button
        size="large"
        type="primary"
        loading={loading}
        onClick={_createPassword}
      >
        Create Password
      </Button>
    </LoginCard>
  );
}

export default CreatePassword;
