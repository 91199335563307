import React, { useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import CustomDropdown from '../../Components/CustomDropdown'
import styles from "./Styles/Home.module.css" 

export default function Home() {

  const [projectsCurve, setProjectsCurve] = useState([])
  const [leadsCurve, setLeadsCurve] = useState([])

  return (
    <div className={styles.container}>
        <div className={styles.header}>
            <h1>Dashboard</h1>
        </div>

        <div className={styles.circularCharts}>
            <div className={styles.circularChart}>
                <h3>CULTeX</h3>
                <div>
                <CircularProgressbar styles={buildStyles({
                    pathColor: '#317AEC',
                    trailColor: '#fff',
                    textSize:14,
                    textColor:'#6A6A6A',
                    strokeLinecap:'butt'
                    })} counterClockwise={true} strokeWidth={22} value={12} text="12%" />
                </div>
            </div>
            <div className={styles.circularChart}>
                <h3>TOXiN</h3>
                <div>
                <CircularProgressbar styles={buildStyles({
                    pathColor: '#FC3263',
                    trailColor: '#fff',
                    textSize:14,
                    textColor:'#6A6A6A',
                    strokeLinecap:'butt'
                    })} counterClockwise={true} strokeWidth={22} value={3} text="3%" />
                </div>
            </div>
            <div className={styles.circularChart}>
                <h3>RETiN</h3>
                <div>
                <CircularProgressbar styles={buildStyles({
                    pathColor: '#40E089',
                    trailColor: '#fff',
                    textSize:14,
                    textColor:'#6A6A6A',
                    strokeLinecap:'butt'
                    })} counterClockwise={true} strokeWidth={22} value={7} text="7%" />
                </div>
            </div>
            <div className={styles.circularChart}>
                <h3>EMPeS</h3>
                <div>
                <CircularProgressbar styles={buildStyles({
                    pathColor: '#094178',
                    trailColor: '#fff',
                    textSize:14,
                    textColor:'#6A6A6A',
                    strokeLinecap:'butt'
                    })} counterClockwise={true} strokeWidth={22} value={1} text="1%" />
                </div>
            </div>
            <div className={styles.circularChart}>
                <h3>PRODuS</h3>
                <div>
                <CircularProgressbar styles={buildStyles({
                    pathColor: '#FF5925',
                    trailColor: '#fff',
                    textSize:14,
                    textColor:'#6A6A6A',
                    strokeLinecap:'butt'
                    })} counterClockwise={true} strokeWidth={22} value={4} text="4%" />
                </div>
            </div>
            <div className={styles.circularChart}>
                <h3>MANeFF</h3>
                <div>
                <CircularProgressbar styles={buildStyles({
                    pathColor: '#731395',
                    trailColor: '#fff',
                    textSize:14,
                    textColor:'#6A6A6A',
                    strokeLinecap:'butt'
                    })} counterClockwise={true} strokeWidth={22} value={8} text="8%" />
                </div>
            </div>
            <div className={styles.circularChart}>
                <h3>EMWeB</h3>
                <div>
                <CircularProgressbar styles={buildStyles({
                    pathColor: '#FF228C',
                    trailColor: '#fff',
                    textSize:14,
                    textColor:'#6A6A6A',
                    strokeLinecap:'butt'
                    })} counterClockwise={true} strokeWidth={22} value={2} text="2%" />
                </div>
            </div>
            <div className={styles.circularChart}>
                <h3>ATiN</h3>
                <div>
                <CircularProgressbar styles={buildStyles({
                    pathColor: '#FF228C',
                    trailColor: '#fff',
                    textSize:14,
                    textColor:'#6A6A6A',
                    strokeLinecap:'butt'
                    })} counterClockwise={true} strokeWidth={22} value={3} text="3%" />
                </div>
            </div>
        </div>

        <div className={styles.lineChartContainer}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <h2>Line Chart- Total Units by Month and Manufacturer</h2>
            <div style={{width:202}}><CustomDropdown options={['option one','option two']} /></div>
            </div>

        </div>


        <aside>
        <header>
          Watch Words
        </header>
        <main>
          <div>
            <span style={{backgroundColor:'#2EA162',borderColor:'#BCFCD9'}}></span>
            <h2>Company Cultures</h2>
            <h3 style={{color:'#2EA162'}}>26</h3>
          </div>
          <div>
            <span style={{backgroundColor:'#FC520F',borderColor:'#FFD1BF'}}></span>
            <h2>New Employee</h2>
            <h3 style={{color:'#FC520F'}}>20</h3>
          </div>
          <div>
            <span style={{backgroundColor:'#770FFC',borderColor:'#EEE0FF'}}></span>
            <h2>Parties</h2>
            <h3 style={{color:'#770FFC'}}>16</h3>
          </div>
          <div>
            <span style={{backgroundColor:'#FC0F81',borderColor:'#FEBADB'}}></span>
            <h2>Newrooms</h2>
            <h3 style={{color:'#FC0F81'}}>10</h3>
          </div>
        </main>
      </aside>


      <div className={styles.barCharts}>
        {/* ------ */}
            <div className={styles.barChart}>
            <h2>Working Environment</h2>
            <div className={styles.barProgress}>
                <p>A. Respect</p>
                <div style={{backgroundColor:'#FF228C',width:'10%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>B. Tranparency</p>
                <div style={{backgroundColor:'#6B248F',width:'50%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>C. Trust</p>
                <div style={{backgroundColor:'#094178',width:'70%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>D. Empathy</p>
                <div style={{backgroundColor:'#FC3263',width:'22%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>E. Humility</p>
                <div style={{backgroundColor:'#FC3263',width:'30%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>F. Appreciation</p>
                <div style={{backgroundColor:'#FC3263',width:'60%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>G. Integrety</p>
                <div style={{backgroundColor:'#FC3263',width:'50%'}} className={styles.barProgressInner}></div>
            </div>
        </div>
        {/* ------ */}

        {/* ------ */}
        <div className={styles.barChart}>
            <h2>Execution</h2>
            <div className={styles.barProgress}>
                <p>A. Execution</p>
                <div style={{backgroundColor:'#FF228C',width:'70%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>B. Excellence</p>
                <div style={{backgroundColor:'#6B248F',width:'20%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>C. Innovation</p>
                <div style={{backgroundColor:'#094178',width:'80%'}} className={styles.barProgressInner}></div>
            </div>
        </div>
        {/* ------ */}

        {/* ------ */}
        <div className={styles.barChart}>
            <h2>Toxic Nature</h2>
            <div className={styles.barProgress}>
                <p>A. Disrespect</p>
                <div style={{backgroundColor:'#FF228C',width:'6%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>B. Non Inclusiveness</p>
                <div style={{backgroundColor:'#6B248F',width:'8%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>C. Unethical</p>
                <div style={{backgroundColor:'#094178',width:'10%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>D. Abusive</p>
                <div style={{backgroundColor:'#FC3263',width:'40%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>E. Racial Discrimination</p>
                <div style={{backgroundColor:'#FC3263',width:'5%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>F. Gender Bias</p>
                <div style={{backgroundColor:'#FC3263',width:'10%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>G. Nepotism</p>
                <div style={{backgroundColor:'#FC3263',width:'0%'}} className={styles.barProgressInner}></div>
            </div>
        </div>
        {/* ------ */}

        {/* ------ */}
        <div className={styles.barChart}>
            <h2>Type of Work</h2>
            <div className={styles.barProgress}>
                <p>A. Agility</p>
                <div style={{backgroundColor:'#FF228C',width:'50%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>B. Collaboration</p>
                <div style={{backgroundColor:'#6B248F',width:'33%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>C. Customer Orientation</p>
                <div style={{backgroundColor:'#094178',width:'30%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>D. Diversity</p>
                <div style={{backgroundColor:'#FC3263',width:'50%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>E. Performance</p>
                <div style={{backgroundColor:'#FC3263',width:'12%'}} className={styles.barProgressInner}></div>
            </div>
            <div className={styles.barProgress}>
                <p>F. Purpose alignment</p>
                <div style={{backgroundColor:'#FC3263',width:'8%'}} className={styles.barProgressInner}></div>
            </div>
        </div>
        {/* ------ */}
      </div>
    </div>
  )
}
