import { Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../utils/constants';
import { BiSolidRightArrow } from 'react-icons/bi';

export default function PageFooter({ setTouchModal }) {
	return (
		<Stack gap={4}>
			<Stack justifyContent="center" direction="row" alignItems="center" gap={0.5}>
				<Link onClick={() => setTouchModal(true)} sx={{ cursor: 'pointer' }} fontSize={16} fontWeight={500} color="#fff">
					GET IN TOUCH
				</Link>
				<BiSolidRightArrow color="#fff" />
			</Stack>

			<Stack p={2} borderRadius={2} borderBottom="2px solid #94FFD3" sx={{ background: '#fff' }}>
				<Typography textAlign="center" fontSize={14} color={colors.tableGrey}>
					Save <span style={{ fontWeight: 500, color: '#000', fontSize: 15 }}>+1 (415) 843 2739</span> to avoid spam filtering.
					Select your model and provide your number for a callback.
				</Typography>
			</Stack>

			<Typography color="#fff" textAlign="center" fontSize={14} fontWeight={400}>
				Powered by <span style={{ fontWeight: 600 }}>CultureOS</span>
			</Typography>
		</Stack>
	);
}
