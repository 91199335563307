import React from "react";
import styles from "./TNC.module.css";
import CardContainer from "../../../Components/CardContainer";

export default function TNC() {
  return (
    <CardContainer className={styles.card}>
      <h2>Terms & Conditions</h2>
      <div>
        <ol>
          <li>Introduction</li>
          <ul>
            <li>
              These terms and conditions (the "Terms") govern the use of the web
              application (the "App") provided by [Company Name] (the
              "Company").
            </li>
          </ul>

          <li>Acceptance of Terms</li>
          <ul>
            <li>
              By accessing or using the App, you acknowledge that you have read,
              understood, and agree to be bound by these Terms and to comply
              with all applicable laws and regulations. If you do not agree to
              these Terms, you must not use the App.
            </li>
          </ul>

          <li>Use of the App</li>
          <ul>
            <li>
              The App is intended for use by corporate employees of the Company
              and its affiliates (the "Users"). The Users are responsible for
              ensuring that their use of the App complies with these Terms and
              all applicable laws and regulations.
            </li>
            <li>
              The Users may use the App for lawful purposes only. The Users may
              not use the App in any way that is illegal, fraudulent, or
              harmful, or in connection with any illegal, fraudulent, or harmful
              purpose or activity.
            </li>
            <li>
              The Users are responsible for keeping their login credentials
              (username and password) confidential and secure. The Company shall
              not be liable for any loss or damage arising from the unauthorized
              use of the Users' login credentials.
            </li>
          </ul>

          <li>Intellectual Property</li>
          <ul>
            <li>
              The App and its content, including but not limited to text,
              graphics, logos, icons, images, and software, are the property of
              the Company and are protected by copyright, trademark, and other
              intellectual property laws.
            </li>
            <li>
              The Users shall not copy, reproduce, distribute, display, sell, or
              otherwise exploit the App or its content without the prior written
              consent of the Company.
            </li>
          </ul>

          <li>Disclaimer of Warranties</li>
          <ul>
            <li>
              The App is provided "as is" and "as available" without warranty of
              any kind, either express or implied, including but not limited to
              the implied warranties of merchantability and fitness for a
              particular purpose.
            </li>
            <li>
              The Company does not warrant that the App will be uninterrupted,
              timely, secure, or error-free. The Company shall not be liable for
              any loss or damage arising from the use of the App.
            </li>
          </ul>

          <li>Limitation of Liability</li>
          <ul>
            <li>
              The Users shall indemnify and hold the Company and its officers,
              directors, employees, and agents harmless from and against any and
              all claims, damages, losses, liabilities, costs, and expenses,
              including reasonable attorneys' fees, arising out of or in
              connection with the Users' use of the App.
            </li>
            <li>
              In no event shall the Company be liable for any direct, indirect,
              incidental, special, or consequential damages arising out of or in
              connection with the use of the App.
            </li>
          </ul>

          <li>Changes to Terms</li>
          <ul>
            <li>
              The Company reserves the right, at its sole discretion, to modify
              or replace these Terms at any time. If the alterations constitute
              a material change to the Terms, the Company will notify you by
              posting an announcement on the App. What constitutes a "material
              change" will be determined at the Company's sole discretion, in
              good faith, and using common sense and reasonable judgement.
            </li>
          </ul>

          <li>Governing Law and Jurisdiction</li>
          <ul>
            <li>
              These Terms and the use of the App shall be governed by and
              construed in accordance with the laws of [Country/State], without
              giving effect to any principles of conflicts of law.
            </li>
            <li>
              Any dispute arising out of or in connection with these Terms or
              the use of the App shall be subject to the exclusive jurisdiction
              of the courts of [Country/State].
            </li>
          </ul>

          <li>Contact Information</li>
          <ul>
            <li>
              If you have any questions about these Terms, please contact the
              Company at [Company Email/Contact Number].
            </li>
          </ul>

          <li>Termination</li>
          <ul>
            <li>
              The Company may terminate or suspend your access to all or any
              part of the App at any time, with or without cause, with or
              without notice, effective immediately.
            </li>
          </ul>
        </ol>
      </div>
    </CardContainer>
  );
}
