import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { TimeInDays } from '../../Components/TimeInDays'
import RightCards from '../../Components/RightCards'
import { NavContext } from '../../Context/NavContext'
import styles from "./Styles/Notification.module.css"
import { useNavigate } from 'react-router-dom'
import { UserDetailsContext } from '../../Context/UserDetailsContext'

export default function Notification() {

  const { navExpanded } = useContext(NavContext)
  const { userDetails } = useContext(UserDetailsContext)

  const [notifications, setNotifications] = useState([])

  const navigate = useNavigate()

  const _getAllNotifications = async () => {
    const options = {
      headers: {
        'Content-type': 'application/json',
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
    };
    let res = await axios.get(process.env.REACT_APP_URL + 'getNotification', options)
    if (res.status == 200) {
      let sortedArr = res.data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setNotifications(sortedArr)
    }
  }

  useEffect(() => {
    _getAllNotifications()
  }, [])

  return (
    <div style={{ marginLeft: navExpanded ? 200 : 120 }} className={styles.container}>
      <main>
        <div className={styles.card}>
          {notifications.map(item => (
            item?.notificationBy?.id !== userDetails?.id && <div onClick={()=>{
              if(item.postId !== null){
                navigate('/dashboard/community/home/post',{state:{postId:item.postId,commentId:item.commentId}})
              }
            }} className={styles.notification}>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <h2 dangerouslySetInnerHTML={{ __html: item.description }}></h2>
                  <h4>{TimeInDays(item?.createdAt)}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
      <RightCards />
    </div>
  )
}
