import React, { useState } from 'react'
import styles from "./Styles/Criteria.module.css"
import {MdClose} from "react-icons/md"
import Input from '../../Components/Input'
import CustomDropdown from '../../Components/CustomDropdown'
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function Criteria() {
    const [modal, setModal] = useState(false)
    const [criteria, setCriteria] = useState([])
    const [data, setData] = useState({
        name: '',
        description: '',
        condition: ''
    })

  const saveHandler = (e) => {
    e.preventDefault()
    setCriteria([...criteria,data])
    setData({
        name: '',
        description: '',
        condition: ''
    })
    setModal(false)
  }

  return (
    <div className={styles.container}>
    
        <div className={styles.header}>
            <h1>Create Criteria</h1>
            <button onClick={()=>setModal(true)}>
                Add Criteria
            </button>
        </div>


        <table cellPadding={0} cellSpacing={0} className={styles.table}>
            <thead>
                <tr>
                    <th>S.No.</th>
                    <th>Criteria Name</th>
                    <th>Criteria Description</th>
                    <th>Criteria Condition</th>
                </tr>
            </thead>
            <tbody>
                {criteria.length !== 0 && criteria.map((i,index) => {
                    return <tr>
                    <td>{index+1}</td>
                    <td style={{fontWeight:600,color:'#2262C6'}}>{i.name}</td>
                    <td>{i.description}</td>
                    <td>{i.condition}</td>
                </tr>
                })}
                 
            </tbody>
        </table>
    </div>
  )
}
