export const ROUTES = {
  LOGIN: "/login",
  SIGN_UP: "/signup",
  POST_DESCRIPTION: "/dashboard/community/post/:id",
  KUDOS: "/dashboard/community/karma",
  DETAILS: "/details",
  DASHBOARD: "/dashboard",
  CREATE_PASSWORD: "/login/create_password",
  RESET_PASSWORD: "/login/reset_password",
  VERIFY_PASSWORD: "/login/verify_password",
  DASHBOARD_HOME: "/dashboard/community/home",
  DASHBOARD_STATISTICS: "/dashboard/statistics",
  FEEDBACK_THANKYOU: "/feedback/thankyou",
  FEEDBACK_DAYENDEMAIL: "/feedback/submit/day",
  FEEDBACK_MOODMARBLE: "/feedback/submit/mood",
  FEEDBACK_KARMA: "/feedback/submit/karma",
  REMO_FEEDBACK: "/remo",
};
