import React, { useContext } from "react";
import { MenuItem, Stack } from "@mui/material";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";

export default function EditDeleteMenu(props) {
  const {
    item,
    currentUser,
    setSelectedPostEdit,
    setEditVisible,
    setReportId,
    setReportDropdown,
    setDeleteVisible,
    setMenuVisible,
  } = props;
  const { userDetails } = useContext(UserDetailsContext);

  const isReported = item?.reports?.find((i) => i?.userId == userDetails?.id);

  return (
    <Stack>
      {currentUser && item?.post_title && (
        <MenuItem
          onClick={() => {
            setSelectedPostEdit(item);
            setEditVisible(true);
            setMenuVisible(false);
          }}
        >
          Edit
        </MenuItem>
      )}
      {currentUser && (
        <MenuItem
          onClick={() => {
            setDeleteVisible(true);
            setMenuVisible(false);
          }}
        >
          Delete
        </MenuItem>
      )}
      {!currentUser && item.post_title && (
        <MenuItem
          onClick={() => {
            if (!isReported) {
              setReportId(item?.id);
              setReportDropdown("");
              setMenuVisible(false);
            }
          }}
        >
          {isReported ? "Reported" : "Report"}
        </MenuItem>
      )}
    </Stack>
  );
}
