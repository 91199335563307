export const mentionStyles = {
  width: "100%",
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    width: "100%",
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      minHeight: 54,
    },
    highlighter: {
      padding: 8,
      border: "1px solid transparent",
    },
    input: {
      padding: 8,
      border: "1px solid silver",
      width: "100%",
      borderRadius: 4,
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
      width: "100%",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
      maxHeight: 400,
      overflowY: "scroll",
      borderRadius: 4,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#dee7f5",
      },
    },
  },
};
