import React from 'react'
import styles from "./Styles/ImageModal.module.css"

export default function ImageModal(props) {
  return (
    <div>
        <div onClick={props.onClose} className={styles.overlay}></div>
        <div className={styles.modal}>
            <img src={props.image} />
        </div>
    </div>
  )
}
