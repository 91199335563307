import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TabsComponent from "../../Components/TabsComponent";
import PageTitle from "../../Components/PageTitle";
import Leaderboard from "../../Components/Karma/Leaderboard";
import KarmaGivenReceivedCard from "../../Components/Karma/KarmaGivenReceivedCard";
import GiveKudos from "../../Components/Karma/GiveKudos";
import PageWrapper from "../../Layout/PageWrapper";
import { ROUTES } from "../../Routes/pathName";

export default function Kudos() {
  const location = useLocation();
  const { state } = location;
  const [activeTab, setActiveTab] = useState(
    location?.state?.karmaGiven ? "Karma Given" : "Karma Given"
  );
  const navigate = useNavigate();

  const [karmaGiven, setKarmaGiven] = useState([]);
  const [karmaReceived, setKarmaReceived] = useState([]);

  const _getUserKudos = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let res = await axios.get(
      process.env.REACT_APP_URL + "kudos/userkudos",
      options
    );
    if (res.status == 200) {
      let sortedArr1 = res.data.karmaGiven.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      let sortedArr2 = res.data.karmaReceived.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setKarmaGiven(sortedArr1);
      setKarmaReceived(sortedArr2);
    }
  };

  useEffect(() => {
    _getUserKudos();
  }, []);

  useEffect(() => {
    if (state?.leaderboard) {
      setActiveTab("Leaderboard");
    }
  }, [state]);

  return (
    <PageWrapper>
      <TabsComponent
        tabs={["Karma Given", "Karma Received", "Leaderboard"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <PageTitle
        title={activeTab == "Leaderboard" ? "Karma Leaderboard" : activeTab}
        subTitle={
          activeTab == "Leaderboard"
            ? "All employees ranked"
            : activeTab == "Karma Received"
            ? "Karma given to me"
            : activeTab == "Karma Given"
            ? "Karma given by me"
            : ""
        }
      />

      {activeTab == "Karma Given" && (
        <>
          <GiveKudos fetch={_getUserKudos} />

          {karmaGiven.length !== 0 &&
            karmaGiven.map((i) => <KarmaGivenReceivedCard given item={i} />)}
        </>
      )}

      {activeTab == "Leaderboard" && <Leaderboard />}

      {activeTab == "Karma Received" && (
        <Fragment>
          {karmaReceived.length !== 0 &&
            karmaReceived.map((i) => (
              <KarmaGivenReceivedCard received item={i} />
            ))}
        </Fragment>
      )}
    </PageWrapper>
  );
}
