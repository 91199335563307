import { Stack, Typography } from "@mui/material";
import React from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { colors } from "../../../utils/constants";

export default function Socials() {
  return (
    <Stack flexDirection="row" alignItems="center" gap={2}>
      <Typography fontWeight={500} fontSize={14} color={colors.tableBlack}>
        Follow us on
      </Typography>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <FacebookOutlinedIcon sx={{ fontSize: 24, color: colors.lightGrey }} />
        <InstagramIcon sx={{ fontSize: 24, color: colors.lightGrey }} />
        <YouTubeIcon sx={{ fontSize: 24, color: colors.lightGrey }} />
      </Stack>
    </Stack>
  );
}
