import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FeedbackContainer from "./Components/FeedbackContainer";
import LoginHeader from "../../Auth/Components/LoginHeader";
import { FEEDBACK_TYPE, colors } from "../../utils/constants";
import { Avatar, Button } from "antd";
import goofy from "../../Assets/emojis/goofy.svg";
import angry from "../../Assets/emojis/angry.svg";
import annoyed from "../../Assets/emojis/annoyed.svg";
import bored from "../../Assets/emojis/bored.svg";
import cool from "../../Assets/emojis/cool.svg";
import embarassed from "../../Assets/emojis/embarassed.svg";
import happy from "../../Assets/emojis/happy.svg";
import sad from "../../Assets/emojis/sad.svg";
import sick from "../../Assets/emojis/sick.svg";
import tired from "../../Assets/emojis/tired.svg";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../Routes/pathName";

export default function MoodMarble() {
  const moods = [
    {
      name: "Happy",
      image: happy,
      code: "happy",
    },
    {
      name: "Sad",
      image: sad,
      code: "sad",
    },
    {
      name: "Goofy",
      image: goofy,
      code: "goofy",
    },
    {
      name: "Angry",
      image: angry,
      code: "angry",
    },
    {
      name: "Annoyed",
      image: annoyed,
      code: "annoyed",
    },
    {
      name: "Bored",
      image: bored,
      code: "bored",
    },
    {
      name: "Cool",
      image: cool,
      code: "cool",
    },
    {
      name: "Embarassed",
      image: embarassed,
      code: "embarassed",
    },
    {
      name: "Sick",
      image: sick,
      code: "sick",
    },
    {
      name: "Tired",
      image: tired,
      code: "tired",
    },
  ];

  const [selectedMood, setSelectedMood] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const userId = query.get("userId");

  const handleSubmit = async (e) => {
    const data = {
      content: {
        message: selectedMood,
        type: FEEDBACK_TYPE.MOOD,
      },
    };
    e.preventDefault();
    try {
      setLoading(true);
      let res = await axios.post(
        process.env.REACT_APP_URL + `create/feedback?userId=${userId}`,
        data
      );
      if (res.status === 200) {
        setLoading(false);
        navigate(ROUTES.FEEDBACK_THANKYOU);
      }
    } catch (err) {
      if (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (!userId) {
      navigate(ROUTES.LOGIN);
    }
  }, [userId]);

  return (
    <FeedbackContainer>
      <LoginHeader
        title="How are you feeling today?"
        subTitle="Reflect Your Feelings: Mood Marble - Your Emotional Compass!"
      />

      <Stack gap={3}>
        <Typography fontSize={18} color={colors.tableBlack}>
          We understand that each day brings its own set of challenges and
          triumphs, and your emotional well-being is important to us.{" "}
        </Typography>
        <Typography fontSize={16} color={colors.tableBlack}>
          So, Mate! How’s Your mood today?
        </Typography>

        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          rowSpacing={2}
        >
          {moods.map((mood) => (
            <Grid item xs={2.4} sm={2.4} md={1}>
              <Stack
                sx={{
                  cursor: "pointer",
                  opacity: selectedMood === mood.code ? 1 : 0.5,
                }}
                onClick={() => setSelectedMood(mood.code)}
                alignItems="center"
                justifyContent="center"
                gap={0.5}
                key={mood.code}
              >
                <Avatar src={mood.image} size="large" />
                <Typography
                  color={
                    selectedMood === mood.code
                      ? colors.tableBlack
                      : colors.lightGrey
                  }
                  fontSize={14}
                  fontWeight={500}
                >
                  {mood.name}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Button
          disabled={!selectedMood}
          loading={loading}
          style={{ marginLeft: "auto" }}
          size="large"
          type="primary"
          onClick={handleSubmit}
        >
          Submit your Mood
        </Button>
      </Stack>
    </FeedbackContainer>
  );
}
