import React from "react";
import karmaIconGreen from "../../Assets/karmaIconGreen.png";
import { Avatar, Stack, Typography } from "@mui/material";
import { colors } from "../../utils/constants";
import { useSelector } from "react-redux";

export default function Leaderboard() {
  const karmaPoints = useSelector((state) => state.app.karmaPoints);
  return karmaPoints?.leaderBoard?.length !== 0 ? (
    <Stack>
      <Stack
        padding={3}
        borderRadius={2}
        bgcolor="#D8EDDD"
        mb={2}
        flexDirection="row"
        alignItems="center"
        px={4}
        gap={2}
      >
        <Typography fontSize={24} fontWeight={500} color="#137333">
          {karmaPoints?.myKarmaPoints?.rank || 0}
        </Typography>

        <Avatar
          sx={{ width: 56, height: 56 }}
          src={karmaPoints?.myKarmaPoints?.image}
          size="large"
        />
        <Typography fontSize={16} fontWeight={500} color="#137333">
          {karmaPoints?.myKarmaPoints?.name || "faian ali"}
        </Typography>
        <Stack flexDirection="row" alignItems="center" gap={1} ml="auto">
          <img width="30px" src={karmaIconGreen} alt="icon" />
          <Typography fontSize={24} fontWeight={600} color="#137333">
            {karmaPoints?.myKarmaPoints?.points || 123}
          </Typography>
        </Stack>
      </Stack>

      <Stack alignItems="center" flexDirection="row" padding="8px 30px" gap={7}>
        <Typography fontSize={14} fontWeight={500} color={colors.tableBlack}>
          Rank
        </Typography>
        <Typography fontSize={14} fontWeight={500} color={colors.tableBlack}>
          Name
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={500}
          color={colors.tableBlack}
          ml="auto"
        >
          Karma Points
        </Typography>
      </Stack>

      <Stack bgcolor={colors.WHITE} padding={2} borderRadius={2} gap={1}>
        {karmaPoints?.leaderBoard?.length !== 0 &&
          karmaPoints?.leaderBoard?.map((l) => {
            return (
              <Stack
                key={l.id}
                height="44px"
                bgcolor="#F4F4F4"
                gap={3}
                px="20px"
                flexDirection="row"
                alignItems="center"
              >
                <Typography fontSize={14} fontWeight={500}>
                  {l.rank}
                </Typography>
                <Avatar sx={{ height: 28, width: 28 }} src={l?.image} />
                <Typography fontSize={14} fontWeight={500}>
                  {l.name}
                </Typography>
                <Typography fontSize={14} fontWeight={500} ml="auto">
                  {l.points}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  ) : (
    <Stack bgcolor={colors.WHITE} padding={2} borderRadius={1}>
      <Typography fontSize={18}>
        This is just the start of your culture building journey with us, why
        don't you start giving karma points to your favourite peers
      </Typography>
    </Stack>
  );
}
