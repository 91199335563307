import { Avatar, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { colors } from "../../../utils/constants";
import { avatars } from "../../../Components/Avatars";
import { Alert, Button, Input } from "antd";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";
import axios from "axios";

export default function EditProfile({ setModalVisible, fetch }) {
  const { userDetails } = useContext(UserDetailsContext);
  const [errorVisible, setErrorVisible] = useState(false);
  const [creating, setCreating] = useState(false);

  const [user, setUser] = useState({
    imagePath: userDetails.imagePath,
    username: userDetails?.username,
    first_name: userDetails?.first_name,
    last_name: userDetails?.last_name,
    email_Id: userDetails?.email_Id,
  });

  const updateUser = async () => {
    setCreating(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      username: user.username,
      firstName: user.first_name,
      lastName: user.last_name,
      department: user.department,
      image: user?.imagePath,
    };

    let res = await axios.put(
      process.env.REACT_APP_URL + "updateUser",
      data,
      options
    );
    if (res.status == 200) {
      setCreating(false);
      setModalVisible(false);
      fetch();
    } else if (res.status == 203) {
      setCreating(false);
      setErrorVisible(res.data.msg);
    }
  };

  return (
    <Stack>
      <Stack
        borderBottom="1px solid"
        borderColor={colors.tableGrey}
        pb={2}
        mb={2}
      >
        <Typography fontSize={22} fontWeight={500} color={colors.tableBlack}>
          Edit Profile
        </Typography>
        <Typography fontSize={12} fontWeight={400} color={colors.tableGrey}>
          Change your intro
        </Typography>
      </Stack>

      <Typography fontSize={14} fontWeight={500} color={colors.tableBlack}>
        Select your avatar
      </Typography>

      <Stack
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
            scrollbarWidth: "none",
          },
        }}
        my={1}
        flexDirection="row"
        alignItems="center"
        gap={2}
        overflow="scroll"
      >
        {avatars.map((i) => {
          return (
            <Avatar
              onClick={() => setUser({ ...user, imagePath: i })}
              style={{
                width: 80,
                height: 80,
                flexShrink: 0,
                border: "4px solid",
                borderColor: user.imagePath == i ? "#2262C6" : "#fff",
              }}
              src={i}
            />
          );
        })}
      </Stack>
      <Stack
        gap={2}
        sx={{
          "& .MuiTypography-root": {
            fontSize: 14,
            fontWeight: 500,
            color: colors.tableBlack,
            mb: 0.5,
          },
        }}
      >
        <Stack>
          <Typography>
            Username<span style={{ color: "red" }}>*</span>
          </Typography>
          <Input
            size="large"
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
          />
        </Stack>

        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Stack flex={1}>
            <Typography>First Name</Typography>
            <Input
              size="large"
              value={user?.first_name}
              onChange={(e) => setUser({ ...user, first_name: e.target.value })}
            />
          </Stack>

          <Stack flex={1}>
            <Typography>Last Name</Typography>
            <Input
              size="large"
              value={user.last_name}
              onChange={(e) => setUser({ ...user, last_name: e.target.value })}
            />
          </Stack>
        </Stack>

        <Stack>
          <Typography>Your Email (Non-Editable)</Typography>
          <Input size="large" value={user?.email_Id} disabled />
        </Stack>

        {errorVisible && (
          <Alert showIcon message={errorVisible} type="error" closable />
        )}
      </Stack>

      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={1}
        mt={2}
      >
        <Button
          onClick={() => setModalVisible(false)}
          type="default"
          size="large"
        >
          Cancel
        </Button>
        <Button
          loading={creating}
          onClick={updateUser}
          style={{ backgroundColor: colors.primaryThemeColor }}
          type="primary"
          size="large"
        >
          Save Details
        </Button>
      </Stack>
    </Stack>
  );
}
