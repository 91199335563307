import React, { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { avatars } from "../../../Components/Avatars";
import SampleFile from "../../../Assets/bulk-add-sample.xlsx";
import { FiDownload } from "react-icons/fi";
import { ButtonBase, Chip, Stack, Typography } from "@mui/material";
import { colors } from "../../../utils/constants";
import { Button } from "antd";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  borderRadius: 3,
};

export default function AddBulkUsers({ setCreateBulkModal }) {
  const [successVisible, setSuccessVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef();
  const ovelayRef = useRef();

  const [data, setData] = useState([]);
  const [image, setImage] = useState({});
  const fileRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    try {
      const reader = new FileReader();
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet);
        let arr = [];
        excelData.map((i) => {
          let m = {
            email: i["Email ID"].toString().toLowerCase(),
            phone: i["Phone No"] ? i["Phone No"].toString() : "",
            department: i["Department/Function"],
            level: i["Hierarchy/Level"],
            location: i["Location"],
            gender: i["Gender"],
            imagePath: avatars[0],
            firstName: i["First Name"],
            lastName: i["Last Name"] || "",
          };
          arr.push(m);
        });
        setData(arr);
        setImage({ name: file?.name });
      };
      reader.readAsBinaryString(file);
    } catch (e) {
      console.log(e);
    }
  };

  const _createBulkAccount = async (e) => {
    setLoading(true);
    e.preventDefault();
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let body = {
      data: data.slice(0, 10),
    };

    try {
      let res = await axios.post(
        process.env.REACT_APP_URL + "createbulkaccounts",
        body,
        options
      );
      if (res.status == 200) {
        ref.current.style.transform = "translate(-50%,-50%)";
        ovelayRef.current.style.display = "block";
        setSuccessVisible(res.data.msg);
        setLoading(false);
        setData([]);
        setImage({});
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Stack minWidth="50vw" sx={modalStyle} p={3}>
      <Stack mb={2} flexDirection="row" justifyContent="space-between">
        <Typography fontSize={28} fontWeight={500} color={colors.tableBlack}>
          Add Bulk Users
        </Typography>

        <Button
          href={SampleFile}
          download="bulk-sample.xlsx"
          type="primary"
          style={{
            alignItems: "center",
            display: "flex",
            textDecoration: "none",
          }}
          icon={<FiDownload color="#fff" />}
          variant="contained"
        >
          Sample File
        </Button>
      </Stack>

      <ButtonBase onClick={() => fileRef.current.click()}>
        <Stack
          sx={{
            transition: "all .3s",
            ":hover": { bgcolor: "#e1edfe" },
          }}
          width="100%"
          border="1px dashed"
          borderColor={colors.primaryThemeColor}
          borderRadius={2}
          alignItems="center"
          justifyContent="center"
          height={90}
        >
          <Typography
            fontSize={16}
            fontWeight={500}
            color={colors.primaryThemeColor}
          >
            Click to attach excel file
          </Typography>
        </Stack>
      </ButtonBase>

      {image.name && (
        <Chip
          sx={{ width: "fit-content", mt: 1 }}
          label={image.name}
          variant="outlined"
          onDelete={() => {
            setImage({});
            setData([]);
          }}
        />
      )}

      <Stack mt={3} flexDirection="row" justifyContent="space-between">
        <Button size="large" onClick={() => setCreateBulkModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          loading={loading}
          onClick={_createBulkAccount}
        >
          Create
        </Button>
      </Stack>

      <input
        onChange={handleFileChange}
        name="file-upload-field"
        value=""
        accept=".xlsx"
        hidden
        type="file"
        ref={fileRef}
      />
    </Stack>
  );
}
