import React from "react";
import { FiSearch } from "react-icons/fi";
import { Input } from "antd";

export default function Search(props) {
  return (
    <Input
      style={{
        height: 44,
        gap: 4,
        backgroundColor: "#F3F5F8",
        borderRadius: 8,
        border: "none",
        fontSize:16
      }}
      prefix={<FiSearch size={20} color="#828282" />}
      {...props}
    />
  );
}
