import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../Components/Loader";
import { SearchContext } from "../../Context/SearchContext";
import CardContainer from "../../Components/CardContainer";
import Card from "./Components/Card";
import PageTitle from "../../Components/PageTitle";
import PageWrapper from "../../Layout/PageWrapper";

export default function Mentions() {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  const { searchInput, setSearchInput } = useContext(SearchContext);
  const [allUsers, setAllUsers] = useState([]);

  const _getMentionedPosts = async () => {
    setLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getMentionedPosts",
      options
    );
    if (res.status == 200) {
      let sortedArr = res.data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setPosts(sortedArr);
      setLoading(false);
    }
  };

  const _getAllUsers = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getAllUsers",
      options
    );
    if (res.status == 200) {
      setAllUsers(res.data);
    }
  };

  useEffect(() => {
    _getMentionedPosts();
    _getAllUsers();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <PageWrapper>
          <PageTitle
            title="Mentions"
            subTitle="Mention people who deserve a public acknowledgement."
          />

          {posts.length !== 0 &&
            posts.map(
              (item) =>
                (searchInput == "" ||
                  item.post_title.toLowerCase().includes(searchInput) ||
                  item.post_desc.toLowerCase().includes(searchInput)) && (
                  <CardContainer>
                    <Card
                      item={item}
                      editable={item.post_title}
                      setLoading={setLoading}
                      fetch={_getMentionedPosts}
                      // setReportId={setReportId}
                      allUsers={allUsers}
                    />
                  </CardContainer>
                )
            )}
        </PageWrapper>
    </>
  );
}
