import React, { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import { avatars } from "../../../Components/Avatars";
import { Grid, Stack, Typography } from "@mui/material";
import { colors } from "../../../utils/constants";
import LabelledInput from "../../../Components/LabelledInput";
import { Button, Input, Select } from "antd";

const departments = ["Technology", "Sales", "Finance", "HR"];

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  borderRadius: 3,
};

export default function CreateUser({ setCreateUserModal, fetch }) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [level, setLevel] = useState("");
  const [location, setLocation] = useState("");
  const [gender, setGender] = useState("");

  const [errorVisible, setErrorVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef();
  const ovelayRef = useRef();

  const _createUser = async (e) => {
    if (email == "") return alert("Please enter an email before continuing");
    setLoading(true);
    e.preventDefault();
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      accountName: email,
      email,
      firstName,
      lastName,
      department,
      level,
      location,
      phone,
      gender,
      imagePath: avatars[0],
    };
    try {
      let res = await axios.post(
        process.env.REACT_APP_URL + "createaccount",
        data,
        options
      );
      if (res.status == 200) {
        ref.current.style.transform = "translate(-50%,-50%)";
        ovelayRef.current.style.display = "block";
        setSuccessVisible(res.data.msg);
        setLoading(false);
        setEmail("");
        setDepartment("");
        setLevel("");
        setLocation("");
        setPhone("");
        setGender("");
        setFirstName("");
        setLastName("");
        fetch();
      } else if (res.status == 202) {
        setErrorVisible(res.data.msg);
        setTimeout(() => {
          setErrorVisible(false);
        }, 4000);
        setLoading(false);
      }
    } catch (err) {
      if (err) {
        setLoading(false);
        setErrorVisible(err.response.data);
        setTimeout(() => {
          setErrorVisible(false);
        }, 4000);
      }
    }
  };

  return (
    <Stack minWidth="50vw" sx={modalStyle} p={3}>
      <Typography
        mb={2}
        fontSize={28}
        fontWeight={500}
        color={colors.tableBlack}
      >
        Add User
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <LabelledInput label="Email" required>
            <Input
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
            />
          </LabelledInput>
        </Grid>

        <Grid item sm={6}>
          <LabelledInput label="First Name">
            <Input
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              type="text"
              size="large"
            />
          </LabelledInput>
        </Grid>

        <Grid item sm={6}>
          <LabelledInput label="Last Name">
            <Input
              size="large"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              type="text"
            />
          </LabelledInput>
        </Grid>

        <Grid item sm={6}>
          <LabelledInput label="Department">
            <Select
              getPopupContainer={(node) => node.parentNode}
              size="large"
              value={department}
              onChange={(e) => setDepartment(e)}
              options={departments.map((i) => {
                return {
                  value: i,
                  label: i,
                };
              })}
            />
          </LabelledInput>
        </Grid>

        <Grid item sm={6}>
          <LabelledInput label="Level">
            <Input
              size="large"
              onChange={(e) => setLevel(e.target.value)}
              value={level}
              type="text"
            />
          </LabelledInput>
        </Grid>

        <Grid item sm={12}>
          <LabelledInput label="Location">
            <Input
              size="large"
              onChange={(e) => setLocation(e.target.value)}
              value={location}
              type="text"
            />
          </LabelledInput>
        </Grid>

        <Grid item sm={6}>
          <LabelledInput label="Phone No.">
            <Input
              size="large"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              type="tel"
            />
          </LabelledInput>
        </Grid>

        <Grid item sm={6}>
          <LabelledInput label="Gender">
            <Select
              getPopupContainer={(node) => node.parentNode}
              size="large"
              value={gender}
              onChange={(e) => setGender(e)}
              options={[
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
              ]}
            />
          </LabelledInput>
        </Grid>
      </Grid>

      <Stack mt={3} flexDirection="row" justifyContent="space-between">
        <Button
          size="large"
          type="default"
          onClick={() => setCreateUserModal(false)}
        >
          Cancel
        </Button>
        <Button
          size="large"
          loading={loading}
          type="primary"
          onClick={_createUser}
        >
          Create
        </Button>
      </Stack>
    </Stack>
  );
}
