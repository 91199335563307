import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FeedbackContainer from "./Components/FeedbackContainer";
import LoginHeader from "../../Auth/Components/LoginHeader";
import { FEEDBACK_TYPE, colors } from "../../utils/constants";
import { Button, Input } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ROUTES } from "../../Routes/pathName";

export default function DayEndMail() {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const userId = query.get("userId");

  const handleSubmit = async (e) => {
    const data = {
      content: {
        message,
        type: FEEDBACK_TYPE.DAYEND,
      },
    };
    e.preventDefault();
    try {
      setLoading(true);
      let res = await axios.post(
        process.env.REACT_APP_URL + `create/feedback?userId=${userId}`,
        data
      );
      if (res.status === 200) {
        setLoading(false);
        navigate(ROUTES.FEEDBACK_THANKYOU);
      }
    } catch (err) {
      if (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (!userId) {
      navigate(ROUTES.LOGIN);
    }
  }, [userId]);

  return (
    <FeedbackContainer>
      <LoginHeader
        title="How was your day?"
        subTitle="Evening Reflection: Unwind with Our Mood Meter!"
      />

      <Stack gap={2}>
        <Typography fontSize={16} color={colors.tableBlack}>
          As the day winds down, I hope it's been filled with moments of
          accomplishment and growth for each of you. Recognizing the ebb and
          flow of daily challenges and victories, your well-being remains a
          priority for us.
        </Typography>
        <Typography fontSize={16} color={colors.tableBlack}>
          Fill your The Day Reflection below It's a chance to pause, reflect,
          and share your thoughts in confidence.
        </Typography>
        <Typography fontSize={14} color={colors.tableGrey}>
          Your candid participation is key to fostering an environment where
          every voice matters.
        </Typography>

        <Input.TextArea
          style={{ fontSize: 16 }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="3"
        />

        <Button
          loading={loading}
          disabled={message === ""}
          style={{ marginLeft: "auto" }}
          size="large"
          type="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
    </FeedbackContainer>
  );
}
