export const setAllUsers = (data) => ({
  type: "SET_USERS",
  payload: data,
});

export const setAnonymous = (data) => ({
  type: "SET_ANON",
  payload: data,
});

export const setKarmaPoints = (data) => ({
  type: "SET_KARMA",
  payload: data,
});

export const setGodsEye = (data) => ({
  type: "GODS_EYE",
  payload: data,
});

export const setScore = (data) => ({
  type: "SET_SCORE",
  payload: data,
});

export const setTrending = (data) => ({
  type: "SET_TRENDING",
  payload: data,
});
