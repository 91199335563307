import React, { useEffect, useState } from "react";
import karmaHeader from "../Assets/karma_header.png";
import statisticsHeader from "../Assets/statistics_header.png";
import trendingHeader from "../Assets/trending_header.png";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Modal, Stack, Typography } from "@mui/material";
import { colors } from "../utils/constants";
import RightCards from "./RightCards";

export default function PageHeader() {
  const [step, setStep] = useState(0);
  var counter = 0;
  useEffect(() => {
    setInterval(function () {
      if (counter == 0) {
        counter = 1;
        setStep(1);
      } else if (counter == 1) {
        counter = 2;
        setStep(2);
      } else if (counter == 2) {
        counter = 0;
        setStep(0);
      }
    }, 4000);
  }, []);

  const [modal, setModal] = useState(false);

  return (
    <>
      <Modal style={{ maxHeight: "100vh", overflow: "scroll" }} open={modal}>
        <Stack bgcolor={colors.pageBackground} p={1} gap={2}>
          <HiOutlineArrowLeft
            onClick={() => setModal(!modal)}
            color="#000"
            size={30}
          />

          <RightCards />
        </Stack>
      </Modal>

      <Stack
        zIndex={3}
        onClick={() => setModal(!modal)}
        height="35px"
        width="100%"
        top="65px"
        position="fixed"
        alignItems="center"
        justifyContent="center"
        gap={1}
        flexDirection="row"
        sx={{
          background:
            "linear-gradient(to right, #8e69b6, #a16bb1,#b471a8,#ba7ba1,#9d8da2,#6e97af,#5d96b5,#428bc1 )",
          "& img": {
            height: 16,
          },
        }}
      >
        <Typography fontSize={12} fontWeight={500} color={colors.WHITE}>
          CHECK
        </Typography>
        {step == 0 ? (
          <img src={karmaHeader} alt="icon" />
        ) : step == 1 ? (
          <img src={statisticsHeader} alt="icon" />
        ) : step == 2 ? (
          <img src={trendingHeader} alt="icon" />
        ) : null}
        <Typography fontSize={12} fontWeight={500} color={colors.WHITE}>
          {step == 0
            ? "Karma Leaderboard"
            : step == 1
            ? "Culture Index"
            : step == 2
            ? "Latest Trend"
            : ""}
        </Typography>
      </Stack>
    </>
  );
}
