import { Stack, Typography } from '@mui/material';
import { Input } from 'antd';
import React from 'react';

const TextInput = (props) => {
	const { label } = props;
	return (
		<Stack position="relative">
			<Typography
				fontSize={14}
				fontWeight={500}
				borderRadius={1}
				letterSpacing={0.4}
				color="#fff"
				sx={{ position: 'absolute', left: 14, top: -10, bgcolor: 'red', px: 1, backgroundColor: '#000', zIndex: 2 }}
			>
				{label}
			</Typography>
			<Input style={{ backgroundColor: 'transparent', outline: 'none', color: '#fff', height: 44 }} {...props} />
		</Stack>
	);
};

export default TextInput;
