import React from "react";
import FeedbackContainer from "./Components/FeedbackContainer";
import LoginHeader from "../../Auth/Components/LoginHeader";
import { Stack, Typography } from "@mui/material";
import { colors } from "../../utils/constants";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/pathName";

export default function KarmaLink() {
  const navigate = useNavigate();

  const handleSubmit = () => {
    let token = localStorage.getItem("token");
    if (token) {
      navigate(ROUTES.KUDOS);
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  return (
    <FeedbackContainer>
      <LoginHeader
        title="Send Karma Points"
        subTitle="Share the Love: Give Karma Points to Your Peers!"
      />

      <Stack gap={2}>
        <Typography fontSize={18} color={colors.tableBlack}>
          Feeling grateful for the awesome vibes your peers have been sending
          your way? It's time to pay it forward with some Karma Points!
        </Typography>
        <Typography fontSize={16} color={colors.tableBlack}>
          Just a quick reminder: Karma Points are a cool way to show
          appreciation and spread positivity among our squad. It's super simple
          – just hit the link below to give Karma Points to someone who's made
          your day brighter.
        </Typography>
        <Typography fontSize={16} color={colors.tableBlack}>
          Let's keep the good vibes flowing! Click here to give Karma Points
          now!
        </Typography>
        <Typography fontSize={16} color={colors.tableBlack}>
          Thanks for being awesome
        </Typography>

        <Button
          style={{ margin: "0 auto" }}
          size="large"
          type="primary"
          onClick={handleSubmit}
        >
          Give Karma Points Now
        </Button>
      </Stack>
    </FeedbackContainer>
  );
}
