import React from "react";
import styles from "./Styles/DatePicker.module.css";
import moment from "moment";
import { Typography } from "@mui/material";

export default function DatePicker({ date, setDate, setFilterTime }) {
  return (
    <div style={{ marginLeft: "auto" }} className={styles.dateInput}>
      <input
        name="start_date"
        value={date}
        onChange={(e) => {
          setFilterTime("");
          setDate(e.target.value);
        }}
        type="date"
      />
      <div>
        {date == "" || date == undefined || date == null ? (
          <Typography fontSize={12} fontWeight={500}>
            dd/mm/yy
          </Typography>
        ) : (
          <Typography fontSize={12} fontWeight={500}>
            {moment(date, "YYYY-MM-DD").format("DD/MM/YY")}
          </Typography>
        )}
      </div>
    </div>
  );
}
