import React from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { ChevronLeft, Search } from '@mui/icons-material';
import { colors } from '../../utils/constants';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../Layout/PageWrapper';

export default function CampaignUsers() {
	const [list, setList] = useState([]);
	const navigate = useNavigate();
	const [search, setSearch] = useState('');

	const _getUsers = async () => {
		const options = {
			headers: {
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		};
		let res = await axios.get(process.env.REACT_APP_URL + 'getallcallusers', options);
		if (res.status == 200) {
			setList(res?.data?.reverse());
		}
	};

	useEffect(() => {
		_getUsers();
	}, []);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (row) => <Typography>{row}</Typography>,
		},
		{
			title: 'Email',
			key: 'email',
			dataIndex: 'email',
			render: (row) => <Typography>{row}</Typography>,
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			render: (row) => <Typography>{row}</Typography>,
		},
		{
			title: 'Model',
			dataIndex: 'model',
			key: 'model',
			render: (row) => <Typography>{row}</Typography>,
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'date',
			render: (row) => <Typography>{moment(row).format('lll')}</Typography>,
		},
	];

	return (
		<PageWrapper hideRightCards>
			<Stack gap={2} flexDirection="row" alignItems="center" mb={2}>
				<IconButton onClick={() => navigate(-1)} sx={{ p: 0.2 }}>
					<ChevronLeft sx={{ color: colors.tableBlack, fontSize: 34 }} />
				</IconButton>
				<Typography fontSize={24} fontWeight={500} color={colors.tableBlack}>
					Campaign Users
				</Typography>

				<TextField
					InputProps={{ endAdornment: <Search sx={{ color: colors.tableGrey }} /> }}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					sx={{ bgcolor: '#fff', width: '50%', ml: 'auto', borderRadius: 2, fieldset: { borderRadius: 2 } }}
					size="small"
					placeholder="Search for a user"
				/>
			</Stack>

			<Stack
				gap={1}
				alignItems="flex-end"
				sx={{
					'.ant-table-thead .ant-table-cell': { bgcolor: '#dee7f5' },
					'& .MuiTypography-root': { fontSize: 14, fontWeight: 400, color: colors.tableBlack },
				}}
			>
				<Table
					scroll={{ y: 'calc(100vh - 275px)' }}
					columns={columns}
					pagination={{
						position: ['bottomCenter'],
					}}
					dataSource={list.filter((i) => {
						return (
							i?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
							i?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
							i?.phone?.toLowerCase()?.includes(search.toLowerCase())
						);
					})}
				/>
			</Stack>
		</PageWrapper>
	);
}
