import { Stack, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../../utils/constants";
import { BsCheck } from "react-icons/bs";
import moment from "moment";
import { Button } from "antd";
import DatePicker from "../../../Components/DatePicker";

export default function Filter(props) {
  const {
    filterStartDate,
    filterEndDate,
    filterTime,
    setPosts,
    filterStatus,
    allPosts,
    setFilterStartDate,
    setFilterEndDate,
    setFilterTime,
    setFilterStatus,
    setAnchorElFilter,
  } = props;

  const _applyfilter = () => {
    if (
      (filterStartDate !== "" && filterEndDate == "") ||
      (filterStartDate == "" && filterEndDate !== "")
    )
      return alert("Please enter both dates to apply.");

    if (filterStartDate !== "" && filterEndDate !== "" && filterTime == "") {
      let tempArr2 = allPosts.filter(
        (i) =>
          moment(i.createdAt).format() > moment(filterStartDate).format() &&
          moment(i.createdAt).format() < moment(filterEndDate).format()
      );
      if (filterStatus == "") {
        setPosts(tempArr2);
      } else if (filterStatus == "Most Viewed") {
        setPosts(tempArr2.filter((j) => j.viewCount == 1));
      }
    } else if (filterTime !== "") {
      if (filterTime == "Most Recent") {
        if (filterStatus == "") {
          setPosts(allPosts);
        } else if (filterStatus == "Most Viewed") {
          setPosts(allPosts.filter((j) => j.viewCount == 1));
        }
      } else if (filterTime == "Last 24 Hours") {
        const tempArr = allPosts.filter(
          (i) => moment().diff(moment(i.createdAt).format(), "hours") <= 24
        );

        if (filterStatus == "") {
          setPosts(tempArr);
        } else if (filterStatus == "Most Viewed") {
          setPosts(tempArr.filter((j) => j.viewCount == 1));
        }
      } else if (filterTime == "This Week") {
        let tempArr1 = allPosts.filter(
          (i) => moment().diff(moment(i.createdAt).format(), "weeks") < 1
        );

        if (filterStatus == "") {
          setPosts(tempArr1);
        } else if (filterStatus == "Most Viewed") {
          setPosts(tempArr1.filter((j) => j.viewCount == 1));
        }
      }
    } else {
      setPosts(allPosts);
    }
    setAnchorElFilter(null);
  };
  const FilterItem = ({ label }) => {
    return (
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Stack
          onClick={() => {
            setFilterStartDate("");
            setFilterEndDate("");
            setFilterTime(label);
          }}
          className="filterCheckbox"
          bgcolor={filterTime == label ? colors.tableBlack : "#fff"}
        >
          <BsCheck color="#fff" />
        </Stack>
        <Typography fontSize={14} fontWeight={500}>
          {label}
        </Typography>
      </Stack>
    );
  };

  const _clearfilter = () => {
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterTime("Most Recent");
    setFilterStatus("");
  };
  return (
    <Stack
      sx={{
        ".filterCheckbox": {
          height: 17,
          width: 17,
          borderRadius: 1,
          border: `1px solid ${colors.tableBlack}`,
        },
      }}
      p={1.5}
      gap={1}
    >
      <Stack>
        <Typography mb={0.5} fontSize={18} fontWeight={500}>
          Time
        </Typography>
        <Stack gap={1}>
          <FilterItem label="Most Recent" />
          <FilterItem label="Last 24 Hours" />
          <FilterItem label="This Week" />
        </Stack>
      </Stack>

      <Stack>
        <Typography mb={0.5} fontSize={18} fontWeight={500}>
          Status
        </Typography>
        <Stack gap={1}>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Stack
              onClick={() => {
                if (filterStatus !== "Most Viewed") {
                  setFilterStatus("Most Viewed");
                } else {
                  setFilterStatus("");
                }
              }}
              className="filterCheckbox"
              bgcolor={
                filterStatus == "Most Viewed" ? colors.tableBlack : "#fff"
              }
            >
              <BsCheck color="#fff" />
            </Stack>
            <Typography fontSize={14} fontWeight={500}>
              Most Viewed
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack>
        <Typography mb={0.5} fontSize={18} fontWeight={500}>
          Date
        </Typography>
        <Stack gap={1}>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography fontSize={14} fontWeight={500}>
              From
            </Typography>
            <DatePicker
              date={filterStartDate}
              setDate={setFilterStartDate}
              setFilterTime={setFilterTime}
            />
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography fontSize={14} fontWeight={500}>
              To
            </Typography>

            <DatePicker
              date={filterEndDate}
              setDate={setFilterEndDate}
              setFilterTime={setFilterTime}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <Button size="small" type="text" onClick={_clearfilter}>
          Clear All
        </Button>
        <Button size="small" type="primary" onClick={_applyfilter}>
          Apply
        </Button>
      </Stack>
    </Stack>
  );
}
