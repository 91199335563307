import React from "react";
import gaveKarmaIcon from "../../Assets/gaveKarmaIcon.png";
import karmaIcon from "../../Assets/karmaIcon_active.png";
import { Stack, Typography } from "@mui/material";
import { Avatar, Image } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { colors } from "../../utils/constants";
import Tag from "./Tag";

export default function KudosCard({ item }) {
  return (
    <Stack bgcolor="#DDEAFE" position="relative" mb={2} borderRadius={2} p={2}>
      <Stack
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" alignItems="center" gap={1.5}>
          <Avatar
            size="large"
            icon={<UserOutlined style={{ fontSize: 18 }} />}
          />
          <Typography fontSize={14} fontWeight={500}>
            {item?.kudos_by?.name}
          </Typography>
        </Stack>

        <Image
          preview={false}
          style={{ objectFit: "contain", height: 70, marginTop: -14 }}
          src={gaveKarmaIcon}
          alt="icon"
        />

        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Avatar
            size="large"
            icon={<UserOutlined style={{ fontSize: 18 }} />}
          />
          <Typography fontSize={14} fontWeight={500}>
            {item?.kudos_to?.name}
          </Typography>
        </Stack>
      </Stack>

      <Typography fontSize={14} fontWeight={400}>
        {item?.kudos_comment}
      </Typography>

      <Stack
        mt={2}
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" gap={1} flexWrap="wrap">
          {item.kudos_traits.map((trait, index) => {
            return <Tag key={index} trait={trait} />;
          })}
        </Stack>

        <Stack flexShrink={0} flexDirection="row" alignItems="center" gap={1}>
          <Typography fontSize={14} fontWeight={400}>
            Total Karma:{" "}
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap={0.5}>
            <Image style={{ objectFit: "cover", height: 20 }} src={karmaIcon} />
            <Typography
              fontSize={14}
              fontWeight={600}
              color={colors.primaryThemeColor}
            >
              {item.kudos_points}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
