import React from "react";
import { POST_TYPE } from "../../../utils/constants";
import { Chip, Stack, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { downloadFile } from "../../../utils/utils";
import { MdDownload } from "react-icons/md";
import { Image } from "antd";

export default function CardMedia({ item }) {
  function CardImage({ item }) {
    return (
      <Image
        style={{ height: "100%", objectFit: "cover",  }}
        wrapperStyle={{
          height: "300px",
          width: "100%",
          marginTop: 8,
        }}
        src={item?.imagePath.url}
        alt="mediaImage"
      />
    );
  }

  function CardVideo({ item }) {
    return (
      item?.imagePath?.url && (
        <ReactPlayer
          style={{ marginTop: 8 }}
          width="100%"
          height={300}
          controls
          url={item?.imagePath?.url}
        />
      )
    );
  }

  function CardFile({ item }) {
    return (
      item?.imagePath?.url && (
        <Chip
          onClick={() =>
            downloadFile(item?.imagePath?.url, item?.imagePath?.name)
          }
          sx={{ maxWidth: "fit-content", cursor: "pointer", mt: 1 }}
          label={
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography href={item?.imagePath?.url} target="_blank">
                {item?.imagePath?.name}
              </Typography>

              <MdDownload size={20} />
            </Stack>
          }
        />
      )
    );
  }
  return (
    <>
      {item?.imagePath?.mediaType == POST_TYPE.IMAGE && (
        <CardImage item={item} />
      )}

      {item?.imagePath?.mediaType == POST_TYPE.VIDEO && (
        <CardVideo item={item} />
      )}

      {item?.imagePath?.mediaType == POST_TYPE.FILE && <CardFile item={item} />}
    </>
  );
}
