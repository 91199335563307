import React, { useState } from "react";
import styles from "../Styles/Community.module.css";
import { BiTimeFive } from "react-icons/bi";
import moment from "moment";
import ReactPlayer from "react-player";
import { BsDownload } from "react-icons/bs";
import { CiFileOn } from "react-icons/ci";
import ImageModal from "../../../Components/ImageModal";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function BroadcastMessage({ item }) {
  const [fullScreenImage, setFullScreenImage] = useState('')
  const [show, setShow] = useState(false)
  return (
    <div className={styles.broadcast}>
      {fullScreenImage && <ImageModal onClose={() => setFullScreenImage('')} image={fullScreenImage} />}
      <div className={styles.broadCastHeader}>
        <h3 className={styles.broadcastTitle}>{item?.title}</h3>

        <div onClick={()=>setShow(!show)} className={styles.broadcastTag}>Broadcast {!show ? <FaChevronDown size={14} style={{marginTop:2}} color="#2262C6" /> : <FaChevronUp size={14} style={{marginTop:2}} color="#2262C6" />}</div>
      </div>

      <div
        className={styles.broadcastTime}
        style={{ display: "flex", gap: 4, alignItems: "center" }}
      >
        <BiTimeFive style={{ marginTop: 2 }} size={20} color="#d9d9d9" />
        <p>{moment(item?.createdAt).format("ll LT")}</p>
      </div>

      {show && <div className={styles.broadcastContent}>
        <p>{item?.message}</p>
        {item?.media && item?.media !== null && Object.keys(item?.media).length ? (
          <div style={{marginTop:12}}>
            {item?.media?.mediaType == "Image" && (
              <img
                onClick={() => setFullScreenImage(item?.media?.url)}
                className={styles.postImage}
                src={item?.media?.url}
                alt="icon"
              />
            )}
            {item?.media?.mediaType == "Video" && (
              <div className={styles.postImage}>
                <ReactPlayer
                  width="100%"
                  height={300}
                  controls
                  url={item?.media?.url}
                />
              </div>
            )}
            {item?.media?.mediaType == "File" && item?.media?.url ? (
              <div className={styles.postFile}>
                <div>
                  <CiFileOn size={30} color="#fff" />
                </div>
                <a href={item?.media?.url} target="_blank">
                  {item?.media?.name}
                </a>
              </div>
            ) : (
              item?.media?.mediaType &&
              item?.media?.mediaType == "pdf" && (
                <div className={styles.pdfViewerContainer}>
                  <iframe
                    className={styles.pdfViewer}
                    id="pdfviewer"
                    src={item?.media?.url}
                  ></iframe>
                  <a target="_blank" href={item?.media?.url} download>
                    <BsDownload /> Download
                  </a>
                </div>
              )
            )}
          </div>
        ) : null}
      </div>}
    </div>
  );
}
