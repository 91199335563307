import { Stack, Typography } from "@mui/material";
import { Avatar, Button } from "antd";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { MdOutlineAccessTime } from "react-icons/md";
import anonymousUser from "../../../Assets/anonymous-user.png";
import { colors } from "../../../utils/constants";
import { Mention, MentionsInput } from "react-mentions";
import axios from "axios";
import { getPostComments, getReplies } from "../../../Api/client";
import { mentionStyles } from "../../../Components/Styles/mentionStyles";
import { useSelector } from "react-redux";

export default function PostComments(props) {
  const { item, mentionedUsers, setMentionedUsers, selectedPost } = props;

  const [commentText, setCommentText] = useState("");
  const [replyText, setReplyText] = useState("");
  const [creating, setCreating] = useState(false);
  const [creatingReply, setCreatingReply] = useState(false);
  const [comments, setComments] = useState([]);
  const [replies, setReplies] = useState([]);
  const [commentId, setCommentId] = useState("");

  const allUsers = useSelector((state) => state.app.allUsers);
  const anonymous = useSelector((state) => state.app.anonymous);

  const _getPostComments = async () => {
    if (selectedPost !== "" && typeof selectedPost == "string") {
      let res = await getPostComments(selectedPost);
      setComments(res);
    }
  };

  const _getReplies = async () => {
    if (commentId !== "") {
      let res = await getReplies(commentId);
      setReplies(res);
    }
  };

  const _createComment = async (e) => {
    setCreating(true);
    e.preventDefault();
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      comment: commentText.replace(
        /\@\[(\w+)\]\([\w-]+\)/g,
        `<span style="color:#2262C6">$1</span>`
      ),
      anonymous,
      mentioned_user: mentionedUsers,
      postId: item.id,
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "createpostcomment",
      data,
      options
    );
    if (res.status == 200) {
      setCreating(false);
      setCommentText("");
      _getPostComments();
      setMentionedUsers([]);
    }
  };

  const _replyOnComment = async (commentId) => {
    setCreatingReply(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      comment: replyText.replace(
        /\@\[(\w+)\]\([\w-]+\)/g,
        `<span style="color:#2262C6">$1</span>`
      ),
      anonymous,
      mentioned_user: mentionedUsers,
      commentId,
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "createreply",
      data,
      options
    );
    if (res.status == 200) {
      setCreatingReply(false);
      setReplyText("");
      setMentionedUsers([]);
      _getReplies();
    }
  };

  useEffect(() => {
    _getPostComments();
  }, [selectedPost]);

  useEffect(() => {
    _getReplies();
  }, [commentId]);

  return (
    <Stack mt={2} gap={2}>
      <Typography fontSize={14} fontWeight={500} fontStyle="italic">
        Comments
      </Typography>
      {comments.length > 0
        ? comments.map((comment) => {
            return (
              <Stack key={comment.id}>
                <Stack gap={1}>
                  <Stack flexDirection="row" alignItems="center" gap={1.5}>
                    {comment.anonymous ? (
                      <Avatar size="large" src={anonymousUser} />
                    ) : (
                      <Avatar size="large" src={comment?.comment_by?.img} />
                    )}

                    <Typography fontSize={14} fontWeight={500}>
                      {" "}
                      {comment?.anonymous
                        ? comment?.comment_by?.name
                        : comment?.comment_by?.firstName +
                          " " +
                          comment?.comment_by?.lastName}
                    </Typography>

                    <Stack
                      ml="auto"
                      gap={1}
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Button
                        size="small"
                        type="text"
                        onClick={() => {
                          setReplies([]);
                          if (commentId !== comment.id) {
                            setCommentId(comment.id);
                          } else {
                            setCommentId("");
                          }
                        }}
                      >
                        View Replies
                      </Button>
                      <Stack gap={0.6} flexDirection="row" alignItems="center">
                        <MdOutlineAccessTime
                          color={colors.lightGrey}
                          size={16}
                        />
                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          color={colors.lightGrey}
                        >
                          {moment(item?.createdAt).fromNow()}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Typography
                    fontWeight={400}
                    fontSize={14}
                    dangerouslySetInnerHTML={{
                      __html: comment?.comment_desc || "N/A",
                    }}
                  />
                </Stack>

                {commentId == comment.id && (
                  <Stack ml={2} mt={2} gap={2}>
                    {replies.map((r) => {
                      return (
                        <Stack key={r.id} gap={1}>
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap={1.5}
                          >
                            {r.anonymous ? (
                              <Avatar size="large" src={anonymousUser} />
                            ) : (
                              <Avatar size="large" src={r.comment_by?.img} />
                            )}

                            <Typography fontSize={14} fontWeight={500}>
                              {" "}
                              {r?.anonymous
                                ? r?.comment_by?.name
                                : r?.comment_by?.firstName +
                                  " " +
                                  r?.comment_by?.lastName}
                            </Typography>

                            <Stack
                              gap={0.6}
                              flexDirection="row"
                              alignItems="center"
                              ml="auto"
                            >
                              <MdOutlineAccessTime
                                color={colors.lightGrey}
                                size={16}
                              />
                              <Typography
                                fontSize={12}
                                fontWeight={400}
                                color={colors.lightGrey}
                              >
                                {moment(r?.createdAt).fromNow()}
                              </Typography>
                            </Stack>
                          </Stack>

                          <Typography
                            fontWeight={400}
                            fontSize={14}
                            dangerouslySetInnerHTML={{
                              __html: r?.comment_desc || "N/A",
                            }}
                          />
                        </Stack>
                      );
                    })}

                    <Stack flexDirection="row" alignItems="flex-end" gap={2}>
                      <MentionsInput
                        style={mentionStyles}
                        placeholder="Add a Reply"
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        a11ySuggestionsListLabel={"Suggested mentions"}
                      >
                        <Mention
                          onAdd={(e) =>
                            setMentionedUsers([...mentionedUsers, e])
                          }
                          data={allUsers.map((u) => {
                            return {
                              id: u.id,
                              display: u.first_name || "" + u.last_name || "",
                            };
                          })}
                        />
                      </MentionsInput>
                      <Button
                        type="primary"
                        loading={creatingReply}
                        onClick={(e) => {
                          e.preventDefault();
                          _replyOnComment(comment.id);
                        }}
                      >
                        Reply
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            );
          })
        : null}

      <Stack flexDirection="row" alignItems="flex-end" gap={2}>
        <MentionsInput
          style={mentionStyles}
          placeholder="Add a Comment"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          a11ySuggestionsListLabel={"Suggested mentions"}
        >
          <Mention
            onAdd={(e) => setMentionedUsers([...mentionedUsers, e])}
            data={allUsers.map((u) => {
              return {
                id: u.id,
                display: u.first_name || "" + u.last_name || "",
              };
            })}
          />
        </MentionsInput>

        <Button loading={creating} onClick={_createComment} type="primary">
          Comment
        </Button>
      </Stack>
    </Stack>
  );
}
