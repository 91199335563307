import React from "react";
import Switch from "react-switch";

export default function CustomSwitch(props) {
  return (
    <Switch
      width={51}
      height={31}
      checkedIcon={false}
      uncheckedIcon={false}
      onColor="#2262C6"
      offColor="#e9e9eb"
      {...props}
    />
  );
}
