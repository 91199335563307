import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/logo.png";
import savedBackground from "../Assets/savedBackground.svg";
import savedBackgroundSm from "../Assets/savedBackgroundSm.svg";
import { avatars } from "../Components/Avatars";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { colors } from "../utils/constants";
import CardContainer from "../Components/CardContainer";
import LabelledInput from "../Components/LabelledInput";
import { Avatar, Button, Input } from "antd";

export default function BasicDetails() {

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [errorVisible, setErrorVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [department, setDepartment] = useState("");

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const [image, setImage] = useState(avatars[0]);

  const _getUserDetails = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL +
        "getUserById/" +
        JSON.parse(localStorage.getItem("user"))?.id,
      options
    );
    if (res.status == 200) {
      setEmail(res.data[0].email_Id);
      setUsername(res.data[0].username);
      setFirstName(res.data[0].first_name);
      setLastName(res.data[0].last_name);
    }
  };

  useEffect(() => {
    _getUserDetails();
  }, []);

  const updateUser = async () => {
    setLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      username,
      firstName,
      lastName,
      department,
      image,
    };
    let res = await axios.put(
      process.env.REACT_APP_URL + "updateUser",
      data,
      options
    );
    if (res.status == 200) {
      setLoading(false);
      setSaved(true);
      let userLocal = JSON.parse(localStorage.getItem("user"));
      userLocal.onboarded = true;
      localStorage.setItem("user", JSON.stringify(userLocal));
    } else if (res.status == 203) {
      setLoading(false);
      setErrorVisible(res.data.msg);
      setTimeout(() => {
        setErrorVisible(false);
      }, 3000);
    }
  };

  return (
    <Stack
      minHeight="100vh"
      bgcolor={colors.backgroundGrey}
      alignItems="center"
      justifyContent="center"
      gap={3}
    >
      {!saved && (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <img width="40px" src={logo} alt="logo" />
          <Typography
            fontSize={20}
            fontWeight={500}
            color={colors.primaryThemeColor}
          >
            Culture OS
          </Typography>
        </Stack>
      )}

      {!saved && (
        <CardContainer maxWidth={isSmallScreen ? "90vw" : "60%"}>
          <Stack
            borderBottom="1px solid"
            borderColor={colors.tableGrey}
            pb={2}
            mb={2}
          >
            <Typography
              fontSize={22}
              fontWeight={500}
              color={colors.tableBlack}
            >
              Basic Details
            </Typography>
            <Typography fontSize={12} fontWeight={400} color={colors.tableGrey}>
              Tell us about yourself
            </Typography>
          </Stack>

          <Typography fontSize={14} fontWeight={500} color={colors.tableBlack}>
            Select your avatar
          </Typography>

          <Stack
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
                scrollbarWidth: "none",
              },
            }}
            my={1}
            flexDirection="row"
            alignItems="center"
            gap={2}
            overflow="scroll"
            mb={3}
          >
            {avatars.map((i) => {
              return (
                <Avatar
                  onClick={() => setImage(i)}
                  style={{
                    width: 80,
                    height: 80,
                    flexShrink: 0,
                    border: "4px solid",
                    borderColor: image == i ? "#2262C6" : "#fff",
                  }}
                  src={i}
                />
              );
            })}
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LabelledInput label="Username" required>
                <Input size="large" value={username} disabled />
              </LabelledInput>
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelledInput label="First Name">
                <Input
                  size="large"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </LabelledInput>
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelledInput label="Last Name">
                <Input
                  size="large"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </LabelledInput>
            </Grid>
            <Grid item xs={12}>
              <LabelledInput label="Your Email (Non-Editable)">
                <Input size="large" value={email} disabled />
              </LabelledInput>
            </Grid>
          </Grid>

          <Typography fontSize={14} fontWeight={500} color={colors.themeRed}>
            {errorVisible}
          </Typography>

          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={2}
            mt={3}
          >
            <Button
              size="large"
              disabled={loading}
              onClick={() => {
                if (firstName == "" || lastName == "") return;
                if (firstName !== "" || lastName !== "") return updateUser;
                navigate("/dashboard/community/home");
              }}
            >
              I'll do it later
            </Button>
            <Button
              type="primary"
              size="large"
              loading={loading}
              disabled={loading}
              onClick={updateUser}
            >
              Save Details
            </Button>
          </Stack>
        </CardContainer>
      )}

      {saved && (
        <Stack my={4}>
          {!isSmallScreen && <img src={savedBackground} alt="largeimage" />}
          {isSmallScreen && <img src={savedBackgroundSm} alt="smallimage" />}

          <Stack justifyContent="center" alignItems="center" mt={2}>
            <Button
              type="primary"
              size="large"
              onClick={() => navigate("/dashboard/community/home")}
            >
              Got it!
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
