import { Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";
import { POST_TYPE, colors } from "../../../utils/constants";
import { Mention, MentionsInput } from "react-mentions";
import moment from "moment";
import TimeInput from "../../../Components/TimeInput";
import axios from "axios";
import textHomeActive from "../../../Assets/text_home_active.png";
import textHomeInActive from "../../../Assets/text_home_inactive.png";
import pollHomeActive from "../../../Assets/poll_home_active.png";
import pollHomeInActive from "../../../Assets/poll_home_inactive.png";
import imageHomeActive from "../../../Assets/image_home_active.png";
import imageHomeInActive from "../../../Assets/image_home_inactive.png";
import videoHomeActive from "../../../Assets/video_home_active.png";
import videoHomeInActive from "../../../Assets/video_home_inactive.png";
import fileHomeActive from "../../../Assets/file_home_active.png";
import fileHomeInActive from "../../../Assets/file_home_inactive.png";
import { Avatar, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import CardContainer from "../../../Components/CardContainer";
import Card from "./Card";
import { mentionStylesPost } from "../../../Components/Styles/mentionStylesPost";
import { useSelector } from "react-redux";

export default function CreatePost(props) {
  const {
    repostItem,
    fetch,
    setRepostItem,
    setRepostModalVisible,
    setBannedModal,
    editable,
    selectedPostEdit,
    setSelectedPostEdit,
  } = props;

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const isRepost = repostItem && Object.keys(repostItem)?.length > 0;
  const allUsers = useSelector((state) => state.app.allUsers);
  const anonymous = useSelector((state) => state.app.anonymous);
  const godsEye = useSelector((state) => state.app.godsEye);

  const buttons = [
    {
      name: "Text",
      active: textHomeActive,
      inActive: textHomeInActive,
      visible: true,
    },
    {
      name: "Poll",
      active: pollHomeActive,
      inActive: pollHomeInActive,
      visible: !isRepost,
    },
    {
      name: "Image",
      active: imageHomeActive,
      inActive: imageHomeInActive,
      visible: true,
    },
    {
      name: "Video",
      active: videoHomeActive,
      inActive: videoHomeInActive,
      visible: true,
    },
    {
      name: "File",
      active: fileHomeActive,
      inActive: fileHomeInActive,
      visible: true,
    },
  ];

  const { userDetails } = useContext(UserDetailsContext);
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [postType, setPostType] = useState("Text");
  const [uploadError, setUploadError] = useState("");

  const [postTitle, setPostTitle] = useState("");
  const [postDesc, setPostDesc] = useState("");

  const [pollDesc, setPollDesc] = useState("");
  const [pollTitle, setPollTitle] = useState("");
  const [optionNum, setOptionNum] = useState(2);
  const [options, setOptions] = useState([]);
  const [pollDuration, setPollDuration] = useState("1 Week");
  const [pollEndDate, setpollEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [pollEndTime, setpollEndTime] = useState("00:00");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({});
  const fileRef = useRef();
  const [creating, setCreating] = useState(false);

  const uploadToCloud = async (_image, type) => {
    let fileSize = (_image.size / 1048576).toFixed(2);
    fileSize = parseFloat(fileSize);

    if ((postType == "_Image" || postType == "Video") && fileSize > 5) {
      return setUploadError("Please choose media less than 5mb");
    }
    setLoading(true);
    const data = new FormData();
    data.append("file", _image);
    data.append("upload_preset", "vatg9qsy");
    data.append("api_key", "755813337218451");
    data.append("cloud_name", "culture");
    let res = await axios.post(
      `https://api.cloudinary.com/v1_1/vatg9qsy/${
        type !== "File" ? type.toLowerCase() : "raw"
      }/upload`,
      data
    );
    if (res.status == 200) {
      setImage({
        mediaType: _image.type == "application/pdf" ? "pdf" : type,
        name: Date.now(),
        url: res.data.secure_url,
        size: res.data.bytes,
        format: res.data.format,
        type: res.data.resource_type,
      });

      setLoading(false);
      setUploadError("");
    }
  };

  const _createPost = async () => {
    if (
      repostItem &&
      Object.keys(repostItem).length == 0 &&
      (postDesc == "" || postTitle == "")
    )
      return alert("Post title and description must be filled before posting!");
    setCreating(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      post_title: postTitle.replace(
        /\@\[(\w+)\]\([\w-]+\)/g,
        `<span style="color:#2262C6">$1</span>`
      ),
      post: postDesc.replace(
        /\@\[(\w+)\]\([\w-]+\)/g,
        `<span style="color:#2262C6">$1</span>`
      ),
      anonymous,
      mentioned_user: mentionedUsers,
      imagePath: image || [],
      repostItem,
      isPublic: godsEye,
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "createpost",
      data,
      options
    );
    if (res.status == 200) {
      setCreating(false);
      setPostTitle("");
      setPostDesc("");
      fetch();
      setMentionedUsers([]);
      setImage({});
      setRepostItem({});
      setRepostModalVisible(false);
    } else if (res.status == 202) {
      setCreating(false);
      return setBannedModal(true);
    }
  };

  const _createPoll = async () => {
    if (pollDesc == "" || !options) return;
    setCreating(true);
    const endDate = moment(
      new Date(`${pollEndDate}T${pollEndTime}+05:30`)
    ).format();
    const startDate = moment().format();
    const headerOptions = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let data = {
      poll_title: pollTitle,
      poll_desc: pollDesc,
      endDate,
      startDate,
      anonymous,
      options: {
        ...options,
      },
      isPublic: godsEye,
    };

    let res = await axios.post(
      process.env.REACT_APP_URL + "poll/create",
      data,
      headerOptions
    );

    if (res.status == 200) {
      setCreating(false);
      setPollDesc("");
      setPollTitle("");
      setOptions({});
      setPollDuration("1 Week");
      setOptionNum(2);
      setMentionedUsers([]);
      fetch();
    } else if (res.status == 202) {
      setCreating(false);
      return setBannedModal(true);
    }
  };

  return (
    <Stack flexDirection="row" gap={2} alignItems="flex-start">
      {!isSmallScreen && (
        <Stack>
          <Avatar
            size="large"
            style={{ width: 74, height: 74, objectFit: "contain" }}
            src={userDetails?.imagePath}
          />
        </Stack>
      )}

      <Stack gap={1} width="100%">
        {!isRepost && (
          <Stack flexDirection="row" alignItems="center" gap={1}>
            {isSmallScreen && (
              <Avatar size="small" src={userDetails?.imagePath} />
            )}
            <Typography fontSize={14}>
              Hey {userDetails?.first_name}, what's on your mind?
            </Typography>
          </Stack>
        )}
        <Stack
          borderRadius={1}
          gap={0.5}
          bgcolor="#F3F5F8"
          minHeight="95px"
          p={1.5}
          sx={{ "& textarea": { border: "none", outline: "none" } }}
        >
          <MentionsInput
            style={mentionStylesPost}
            placeholder="Give your post a title"
            value={
              selectedPostEdit?.post_title ||
              (postType !== "Poll"
                ? postTitle
                : postType == "Poll"
                ? pollTitle
                : "")
            }
            onChange={(e) => {
              if (editable) {
                setSelectedPostEdit({
                  ...selectedPostEdit,
                  post_title: e.target.value,
                });
              } else {
                if (postType !== "Poll") {
                  setPostTitle(e.target.value);
                } else if (postType == "Poll") {
                  setPollTitle(e.target.value);
                }
              }
            }}
            a11ySuggestionsListLabel={"Suggested mentions"}
          >
            <Mention
              onAdd={(e) => setMentionedUsers([...mentionedUsers, e])}
              data={allUsers.map((u) => {
                return {
                  id: u.id,
                  display: u.first_name || "" + u.last_name || "",
                };
              })}
            />
          </MentionsInput>

          {!isRepost && (
            <MentionsInput
              style={mentionStylesPost}
              placeholder={`Description to your ${postType.toLowerCase()}`}
              value={
                selectedPostEdit?.post_desc ||
                (postType !== "Poll"
                  ? postDesc
                  : postType == "Poll"
                  ? pollDesc
                  : "")
              }
              onChange={(e) => {
                if (editable) {
                  setSelectedPostEdit({
                    ...selectedPostEdit,
                    post_desc: e.target.value,
                  });
                } else {
                  if (postType !== "Poll") {
                    setPostDesc(e.target.value);
                  } else if (postType == "Poll") {
                    setPollDesc(e.target.value);
                  } else {
                    return null;
                  }
                }
              }}
              a11ySuggestionsListLabel={"Suggested mentions"}
            >
              <Mention
                onAdd={(e) => setMentionedUsers([...mentionedUsers, e])}
                style={{ backgroundColor: "#cee4e5" }}
                data={allUsers.map((u) => {
                  return {
                    id: u.id,
                    display: u.first_name || "" + u.last_name || "",
                  };
                })}
              />
            </MentionsInput>
          )}

          {isRepost && (
            <CardContainer mb={0}>
              <Card item={repostItem} hideFooter hideStats />
            </CardContainer>
          )}

          {!isRepost && postType == POST_TYPE.POLL && (
            <Stack
              mt={2}
              sx={{
                "& input": {
                  bgcolor: colors.WHITE,
                  height: "38px",
                  fontSize: 16,
                  borderRadius: 1,
                  border: "none",
                  outline: "none",
                  padding: "0px 12px",
                },
              }}
            >
              {Array(optionNum)
                .fill("")
                .map((item, index) => {
                  return (
                    <input
                      style={{ marginBottom: 8 }}
                      key={"pool" + index}
                      value={options[`option${index + 1}`] || ""}
                      onChange={(e) =>
                        setOptions({
                          ...options,
                          [`option${index + 1}`]: e.target.value,
                        })
                      }
                      placeholder={`Option ${index + 1}`}
                      type="text"
                    />
                  );
                })}

              <Button
                sx={{ textTransform: "none", bgcolor: "#fff" }}
                variant="outlined"
                style={{ marginBottom: 8 }}
                onClick={() => setOptionNum(optionNum + 1)}
              >
                Add Option
              </Button>

              <Typography fontSize={14}>Poll time</Typography>
              <Stack gap={1} flexDirection="row" alignItems="center">
                <Stack width="100%">
                  <input
                    onChange={(e) => {
                      setpollEndDate(e.target.value);
                    }}
                    value={pollEndDate}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                  />
                </Stack>
                <Stack width="100%">
                  <TimeInput
                    name="time"
                    placeholder="00:00"
                    className="s-input -time"
                    // mountFocus='true'
                    onTimeChange={(e) => {
                      setpollEndTime(e);
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}

          {(postType == POST_TYPE.IMAGE ||
            postType == POST_TYPE.VIDEO ||
            postType == POST_TYPE.FILE) && (
            <Stack gap={1} mt={1}>
              <Upload
                openFileDialogOnClick={false}
                onRemove={() => setImage({})}
                fileList={Object.keys(image).length ? [image] : []}
                multiple={false}
              >
                <Button
                  onClick={() => fileRef.current.click()}
                  icon={<UploadOutlined />}
                >
                  Upload {postType}
                </Button>
              </Upload>

              {uploadError !== "" && (
                <Typography fontSize={12} color={colors.textRed}>
                  {uploadError}
                </Typography>
              )}
            </Stack>
          )}
        </Stack>

        {!editable && (
          <Stack flexDirection="row" alignItems="center" gap={2.5}>
            {buttons.map((btn, index) => {
              return (
                btn.visible && (
                  <Stack
                    key={index}
                    sx={{
                      img: { width: 26, height: 26, objectFit: "contain" },
                      cursor: "pointer",
                    }}
                    onClick={() => setPostType(btn.name)}
                    flexDirection="row"
                    alignItems="center"
                    gap={0.8}
                  >
                    {postType == btn.name ? (
                      <img src={btn.active} />
                    ) : (
                      <img src={btn.inActive} />
                    )}
                    {!isSmallScreen && (
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color={
                          postType == btn.name
                            ? colors.textBlue
                            : colors.tableBlack
                        }
                      >
                        {btn.name}
                      </Typography>
                    )}
                  </Stack>
                )
              );
            })}

            <Button
              loading={creating}
              disableElevation
              type="primary"
              style={{
                marginLeft: "auto",
                minWidth: 80,
                maxWidth: "fit-content",
              }}
              onClick={() => {
                if (postType !== "Poll") {
                  _createPost();
                } else if (postType == "Poll") {
                  _createPoll();
                }
              }}
            >
              {isRepost ? "Repost" : "Post"}
            </Button>
          </Stack>
        )}

        <input
          ref={fileRef}
          onChange={(e) => uploadToCloud(e.target.files[0], postType)}
          name="file-upload-field"
          type="file"
          value=""
          hidden
          accept={
            postType == POST_TYPE.IMAGE
              ? "image/*"
              : postType == POST_TYPE.VIDEO
              ? "video/*"
              : ""
          }
        />
      </Stack>
    </Stack>
  );
}
