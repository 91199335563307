export let avatars = [
    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774068/xkikymv9zeqhcoey7knw.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774108/kkjxfh7paypc1la8yn8c.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774118/ak1qyltskq0vryu5rlw6.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774134/twqbyah1wb65lu5xyczw.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774146/ytr8in7e5thv0ve3tnm8.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774157/j1hvmtxehbiwinnszgv8.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774170/ocufm6wleh4gfbjfi70b.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774179/uminfrhaexlqehyrsekz.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774192/inz3qctpnesuhc9mehi5.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774201/fr4trrncebdcnvvbzppy.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774219/nvzua32weqgwuorl7wlx.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774228/valbfqey43uq2aqlipv6.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774236/aml3qfwq0q0p9nxqzoct.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774245/tx3fh6lmqidol9gfoubn.png",

    "https://res.cloudinary.com/daxqayxbb/image/upload/v1677774256/rwj7bi1ib9zf7qfijpdm.png"
]