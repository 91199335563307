import React from "react";
import { BiDislike, BiLike } from "react-icons/bi";
import commentgreen from "../Assets/commentgreen.png";
import { Stack, Typography, useMediaQuery } from "@mui/material";

export default function PostStats({ item }) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <Stack
      flexDirection="row"
      gap={2}
      mt={2}
      sx={{
        "& .MuiStack-root": {
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          borderRadius: 1,
          gap: 0.8,
        },
      }}
    >
      <Stack>
        <BiLike size={isSmallScreen ? 18 : 22} color="#C36302" />
        <Typography color="#C36302">
          {item?.likes?.length}
        </Typography>
      </Stack>
      <Stack>
        <BiDislike size={isSmallScreen ? 18 : 22} color="#B80773" />
        <Typography color="#B80773">
          {item?.dislikes?.length}
        </Typography>
      </Stack>
      <Stack>
        <img
          style={{ width: isSmallScreen ? 18 : 20, height: isSmallScreen ? 16 : 20, objectFit: "contain" }}
          src={commentgreen}
          alt='icon'
        />
        <Typography color="#137332">{item?.comments?.length}</Typography>
      </Stack>
    </Stack>
  );
}
