import { Stack, Typography } from "@mui/material";
import { Image } from "antd";
import React from "react";
import { colors } from "../../utils/constants";
import logo from "../../Assets/logo.png";

export default function LoginHeader({ title, subTitle }) {
  return (
    <Stack gap={2}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        <Image src={logo} height={40} width={40} preview={false} />
        <Typography
          fontSize={24}
          fontWeight={500}
          color={colors.primaryThemeColor}
        >
          Culture OS
        </Typography>
      </Stack>

      {title || subTitle ? (
        <Stack gap={0.2} alignItems="center">
          <Typography
            textAlign="center"
            fontSize={24}
            fontWeight={600}
            color={colors.tableBlack}
          >
            {title}
          </Typography>
          <Typography
            textAlign="center"
            fontSize={16}
            fontWeight={400}
            color={colors.lightGrey}
          >
            {subTitle}
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
}
