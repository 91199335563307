import React from "react";
import styles from "./Styles/Admin.module.css";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Header from "./Components/Header";
import Trends from "./Components/Trends";
import { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageWrapper from "../../Layout/PageWrapper";
import { Grid, Stack, Typography } from "@mui/material";
import PageTitle from "../../Components/PageTitle";
import CardContainer from "../../Components/CardContainer";
import { colors } from "../../utils/constants";

const months = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const years = ["2023", "2022", "2021", "2020", "2019"];

export default function Overview() {
  const navigate = useNavigate();
  const trending = useSelector((state) => state.app.trending);
  const [analysisType, setAnalysisType] = useState("");

  const LinkButton = ({ text, path }) => {
    return (
      <Stack
        onClick={() => navigate(path)}
        flexDirection="row"
        bgcolor="#E0EAF9"
        height={50}
        px={2}
        borderRadius={4}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          cursor: "pointer",
          transition: "all .3s",
          ":hover": { bgcolor: "#cedcf2" },
        }}
      >
        <Typography fontSize={14} fontWeight={500} color={colors.tableBlack}>
          {text}
        </Typography>
        <BsChevronRight size={22} color={colors.tableBlack} />
      </Stack>
    );
  };

  return (
    <PageWrapper hideRightCards>
      <Grid container justifyContent="space-between">
        <Grid xs={12} item md={8}>
          <Stack>
            <PageTitle
              titleStyle={{ fontSize: 26, color: colors.primaryThemeColor }}
              title="Cultural Elements"
              subTitle="Know the cultural elements of your organisation"
            />

            <Header />
          </Stack>

          <Stack gap={1}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <PageTitle
                titleStyle={{ fontSize: 26, color: colors.primaryThemeColor }}
                title="Cultural Trends"
                subTitle="Know the cultural trends of your organisation"
              />
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Dropdown
                  placeholder="Select Analysis type here"
                  className={styles.dropdownContainer}
                  placeholderClassName={styles.dropdownPlaceholder}
                  arrowClosed={
                    <span>
                      <HiOutlineChevronDown color="#000" size={16} />
                    </span>
                  }
                  arrowOpen={
                    <span>
                      <HiOutlineChevronUp color="#000" size={16} />
                    </span>
                  }
                  arrowClassName={styles.dropdownArrow}
                  controlClassName={styles.dropdown}
                  menuClassName={styles.dropdownMenu}
                  options={["Yearly", "Monthly"]}
                  onChange={(e) => setAnalysisType(e.value)}
                />

                <Dropdown
                  placeholder={analysisType || "Select"}
                  className={styles.dropdownContainer}
                  placeholderClassName={styles.dropdownPlaceholder}
                  arrowClosed={
                    <span>
                      <HiOutlineChevronDown color="#000" size={16} />
                    </span>
                  }
                  arrowOpen={
                    <span>
                      <HiOutlineChevronUp color="#000" size={16} />
                    </span>
                  }
                  arrowClassName={styles.dropdownArrow}
                  controlClassName={styles.dropdown}
                  menuClassName={styles.dropdownMenu}
                  options={
                    analysisType == "Yearly"
                      ? years
                      : analysisType == "Monthly"
                      ? months
                      : []
                  }
                  // onChange={(e)=>setSelectedCriterias([...selectedCriterias,e.value])}
                />
              </Stack>
            </Stack>
            <Trends />
          </Stack>
        </Grid>

        <Grid xs={12} md={3.5}>
          <CardContainer gap={2}>
            <PageTitle titleStyle={{fontSize:26, color: colors.primaryThemeColor, fontWeight:600}} title="Have something in your mind?" subTitle="Create surveys and broadcast message to connect" />

            <Stack gap={2}>
              <LinkButton
                text="Create Broadcast Message"
                path="/dashboard/admin/broadcast"
              />
              <LinkButton
                text="Create Surveys"
                path="/dashboard/admin/survey"
              />
            </Stack>
          </CardContainer>

          <CardContainer gap={2}>
            <Typography
              fontSize={18}
              fontWeight={500}
              color={colors.tableBlack}
            >
              Prominent cultural values discussed
            </Typography>
            <Stack maxHeight={560} overflow="auto" gap={1}>
              {trending.length !== 0 &&
                trending.map((i) => {
                  return (
                    <div className={styles.prominantTopic}>
                      <div className={styles.progressContainer}>
                        <div
                          style={{ width: `${i.count}%` }}
                          className={styles.progressWidth}
                        ></div>
                      </div>
                      <h5>{i.string}</h5>
                    </div>
                  );
                })}
            </Stack>
          </CardContainer>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
