import React, { useContext, useState } from "react";
import { MdClose } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/logo.png";
import home_inactive from "../Assets/home_inactive.png";
import home_active from "../Assets/home_active.png";
import poll_inactive from "../Assets/polls_inactive.png";
import poll_active from "../Assets/polls_active.png";
import mentions_active from "../Assets/mentions_active.png";
import mentions_inactive from "../Assets/mentions_inactive.png";
import karmaIcon_inactive from "../Assets/karmaIcon_inactive.png";
import karmaIcon_active from "../Assets/karmaIcon_active.png";
import settings_active from "../Assets/settings_active.png";
import settings_inactive from "../Assets/settings_inactive.png";
import logoutIcon from "../Assets/logoutIcon.png";
import support_inactive from "../Assets/support_inactive.png";
import support_active from "../Assets/support_active.png";
import admin_active from "../Assets/admin_active.svg";
import admin_inactive from "../Assets/admin_inactive.svg";
import { NavContext } from "../Context/NavContext";
import { SmallNavContext } from "../Context/SmallNavContext";
import { UserDetailsContext } from "../Context/UserDetailsContext";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { colors } from "../utils/constants";
import { Button, Drawer, Modal } from "antd";
import { useSelector } from "react-redux";

export default function SideNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = useContext(UserDetailsContext);
  const { navExpanded, setNavExpanded } = useContext(NavContext);
  const { smallNavExpanded, setSmallNavExpanded } = useContext(SmallNavContext);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const godsEye = useSelector((state) => state.app.godsEye);

  const tabs = [
    {
      name: "Home",
      code: "home",
      active: home_active,
      inActive: home_inactive,
      pathname: "/dashboard/community/home",
      visible: true,
    },
    {
      name: "Poll",
      code: "poll",
      active: poll_active,
      inActive: poll_inactive,
      pathname: "/dashboard/community/poll",
      visible: true,
    },
    {
      name: "Mentions",
      code: "mentions",
      active: mentions_active,
      inActive: mentions_inactive,
      pathname: "/dashboard/community/mentions",
      visible: !godsEye,
    },
    {
      name: "Karma",
      code: "karma",
      active: karmaIcon_active,
      inActive: karmaIcon_inactive,
      pathname: "/dashboard/community/karma",
      visible: !godsEye,
    },
    {
      name: "Settings",
      code: "settings",
      active: settings_active,
      inActive: settings_inactive,
      pathname: "/dashboard/settings",
      visible: true,
    },
    {
      name: "Support",
      code: "support",
      active: support_active,
      inActive: support_inactive,
      pathname: "/dashboard/support",
      visible: true,
    },
    {
      name: "Admin",
      code: "admin",
      active: admin_active,
      inActive: admin_inactive,
      pathname: "/dashboard/admin/overview",
      visible: userDetails?.roles == "admin",
    },
  ];

  const [logoutModal, setLogoutModal] = useState(false);

  return (
    <>
      <Modal
        centered
        zIndex={99999}
        open={logoutModal}
        closeIcon={null}
        onOk={() => {
          localStorage.clear();
          navigate("/login");
        }}
        onCancel={() => setLogoutModal(false)}
        okText="Yes, Logout"
        cancelText="No, Stay"
      >
        <Stack gap={1}>
          <Typography fontSize={26} fontWeight={600} color={colors.tableBlack}>
            Leaving?
          </Typography>
          <Typography fontSize={18} fontWeight={400} color={colors.tableBlack}>
            Are you sure you want to logout?
          </Typography>
        </Stack>
      </Modal>

      <Drawer
        bodyStyle={{ margin: 0, padding: 0 }}
        width="50%"
        placement="left"
        closable={false}
        onClose={() => setSmallNavExpanded(!smallNavExpanded)}
        open={smallNavExpanded}
      >
        <Stack height="100%">
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            p={2}
          >
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <img style={{ height: 30, width: 30 }} alt="icon" src={logo} />
              <Typography
                fontSize={16}
                fontWeight={600}
                color={colors.textBlue}
              >
                Culture OS
              </Typography>
            </Stack>
            <MdClose
              onClick={() => setSmallNavExpanded(!smallNavExpanded)}
              size={28}
              color="#2262C6"
            />
          </Stack>

          <Stack marginLeft={3} gap={4} mt={3}>
            {tabs.map((tab) => {
              return (
                tab.visible && (
                  <Stack
                    key={tab.code}
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    onClick={() => {
                      setSmallNavExpanded(!smallNavExpanded);
                      navigate(tab.pathname);
                    }}
                  >
                    {location.pathname.includes(tab.pathname) ? (
                      <img
                        style={{ width: 28, height: 28, objectFit: "contain" }}
                        src={tab.active}
                        alt="icon"
                      />
                    ) : (
                      <img
                        style={{ width: 28, height: 28, objectFit: "contain" }}
                        src={tab.inActive}
                        alt="icon"
                      />
                    )}
                    {<p>{tab.name}</p>}
                  </Stack>
                )
              );
            })}
          </Stack>

          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            marginLeft={3}
            mb={3}
            mt="auto"
            onClick={() => setLogoutModal(true)}
          >
            <img
              style={{ width: 28, height: 28, objectFit: "contain" }}
              src={logoutIcon}
              alt="icon"
            />
            {<p>Logout</p>}
          </Stack>
        </Stack>
      </Drawer>

      <Stack
        display={isSmallScreen ? "none" : "flex"}
        position="fixed"
        top={0}
        left={0}
        height="calc(100vh)"
        zIndex={3}
        width={navExpanded ? 200 : 100}
        alignItems="center"
        bgcolor={colors.WHITE}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          onClick={() => navigate("/dashboard/community/home")}
          minHeight="70px"
        >
          <img style={{ width: 36, height: 36 }} alt="logo" src={logo} />
          {navExpanded && (
            <Typography
              fontSize={22}
              color={colors.primaryThemeColor}
              fontWeight={500}
            >
              Culture OS
            </Typography>
          )}
        </Stack>

        <Button
          style={{
            backgroundColor: "#d3e0f4",
            borderRadius: 20,
            fontSize: 14,
            color: colors.primaryThemeColor,
            border: "none",
          }}
          onClick={() => {
            setNavExpanded(!navExpanded);
          }}
        >
          {!navExpanded ? "Expand" : "Collapse"}
        </Button>

        <Stack gap={3} mt={3}>
          {tabs.map((tab) => {
            return (
              tab.visible && (
                <Stack
                  key={tab.code}
                  marginLeft={navExpanded ? 6 : 0}
                  onClick={() => navigate(tab.pathname)}
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  sx={{
                    transform: navExpanded ? "translateX(-50px)" : "none",
                    cursor: "pointer",
                  }}
                >
                  {location.pathname.includes(tab.pathname) ? (
                    <img
                      style={{ width: 28, height: 28, objectFit: "contain" }}
                      src={tab.active}
                      alt="icon"
                    />
                  ) : (
                    <img
                      style={{ width: 28, height: 28, objectFit: "contain" }}
                      src={tab.inActive}
                      alt="icon"
                    />
                  )}
                  {navExpanded && (
                    <Typography
                      color={
                        location.pathname.includes(tab.pathname)
                          ? colors.primaryThemeColor
                          : colors.tableBlack
                      }
                      fontSize={14}
                      fontWeight={500}
                    >
                      {tab.name}
                    </Typography>
                  )}
                </Stack>
              )
            );
          })}
        </Stack>

        <Stack
          onClick={() => setLogoutModal(true)}
          mt="auto"
          mb={2}
          flexDirection="row"
          alignItems="center"
          gap={1}
          marginLeft={navExpanded ? 6 : 0}
          sx={{
            transform: navExpanded ? "translateX(-50px)" : "none",
            cursor: "pointer",
          }}
        >
          <img
            style={{ width: 28, height: 28, objectFit: "contain" }}
            src={logoutIcon}
            alt="logout"
          />
          {navExpanded && (
            <Typography fontSize={14} fontFamily={500}>
              Logout
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
}
