import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { FaPhone } from 'react-icons/fa';
import { MdOutlineMailOutline } from 'react-icons/md';

export default function GetTouchModal({ open, onClose }) {
	const small = useMediaQuery('(max-width:600px)');
	return (
		<Dialog open={open} onClose={onClose}>
			<Stack
				p={small ? 4 : 5}
				py={7}
				width={small ? '90%' : 'fit-content'}
				direction={small ? 'column' : 'row'}
				alignItems="center"
				gap={4}
			>
				<Typography fontSize={28} fontWeight={600}>
					Let's talk <br />
					on something <span style={{ color: '#2263C5' }}>great</span> <br />
					together
				</Typography>

				<Stack gap={2}>
					<Stack direction="row" alignItems="center" gap={1}>
						<FaPhone size={16} />
						<Typography fontSize={16} fontWeight={500}>
							+91-7289822525
						</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" gap={1}>
						<MdOutlineMailOutline size={16} />
						<Typography fontSize={16} fontWeight={500}>
							contact@cultureos.company
						</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Dialog>
	);
}
