import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { colors } from "../../../utils/constants";
import bg from "../../../Assets/bg.svg";
import Socials from "./Socials";

export default function FeedbackContainer({ children }) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <Grid
      container
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Grid xs={11} sm={8} md={8} lg={6}>
        <Stack
          bgcolor={colors.WHITE}
          borderRadius={4}
          sx={{ maxHeight: "96vh", overflow: "auto" }}
        >
          <Stack py={6} px={isSmallScreen ? 4 : 5} gap={3} flex={1}>
            {children}
          </Stack>

          <Stack
            width="100%"
            bgcolor={colors.backgroundGrey}
            py={2}
            px={3}
            borderRadius="0 0 16px 16px"
            gap={1}
          >
            <Socials />
            <Stack>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={colors.tableGrey}
              >
                Lets make our workspace more awesome together
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={colors.tableGrey}
              >
                Team Culture OS
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>

      <img
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: -1,
          overflowY: "hidden",
          objectFit: "cover",
        }}
        alt=""
        src={bg}
      />
    </Grid>
  );
}
