import { Stack, Typography } from '@mui/material'
import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { colors } from '../utils/constants'

export default function StatisticsCard({value, label, bg}) {
  return (
    <Stack flexDirection="row" alignItems="center" gap={2} sx={{background:bg}} padding="0px 20px" width="100%" height="90px" borderRadius={2}>
    <Stack height="64px" width="64px">
      <CircularProgressbar
        styles={buildStyles({
          pathColor: "#fff",
          trailColor: "rgba(0,0,0,0.1)",
          textSize: 26,
          textColor: "#fff",
        })}
        strokeWidth={12}
        value={value}
        text={`${parseInt(value)}%`}
      />
    </Stack>
    <Stack style={{ flex: 1 }}>
      <Typography fontSize={24} color={colors.WHITE} fontWeight={600}>{label}</Typography>
    </Stack>
  </Stack>
  )
}
