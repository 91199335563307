import { Stack, Typography } from "@mui/material";
import React from "react";
import { Button } from "antd";
import { colors } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/pathName";
import FeedbackContainer from "./Components/FeedbackContainer";
import LoginHeader from "../../Auth/Components/LoginHeader";

export default function FeedbackThankyou() {
  const navigate = useNavigate();
  return (
    <FeedbackContainer>
      <LoginHeader
        title="Thankyou for you time."
        subTitle="Thanks for providing your feedback. To explore more features of our
          ecosystem, join our community today"
      />

      <Button
        type="primary"
        onClick={() => {
          navigate(ROUTES.LOGIN, { replace: true });
          window.location.reload();
        }}
        style={{
          width: "fit-content",
          margin: "0 auto",
        }}
        size="large"
      >
        CHECK OUT NOW
      </Button>
    </FeedbackContainer>
  );
}
