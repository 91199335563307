import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import capgemeniIcon from "../Assets/camgemeniIcon.jpeg";
import { NavContext } from "../Context/NavContext";
import { UserDetailsContext } from "../Context/UserDetailsContext";
import { AiOutlineMenu } from "react-icons/ai";
import { SmallNavContext } from "../Context/SmallNavContext";
import { SearchContext } from "../Context/SearchContext";
import { IoIosNotificationsOutline } from "react-icons/io";
import {
  MdNotificationsNone,
  MdOutlineAdminPanelSettings,
} from "react-icons/md";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Image } from "antd";
import { colors } from "../utils/constants";
import CustomSwtch from "../Components/CustomSwitch";
import { useDispatch, useSelector } from "react-redux";
import { setGodsEye } from "../store/reducers/appActions";
import { ROUTES } from "../Routes/pathName";
import { FiBell } from "react-icons/fi";

export default function TopNav() {
  const navigate = useNavigate();
  const { navExpanded } = useContext(NavContext);
  const { smallNavExpanded, setSmallNavExpanded } = useContext(SmallNavContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { setSearchInput } = useContext(SearchContext);

  const [accountImage, setAccountImage] = useState(
    JSON.parse(localStorage.getItem("user"))?.accountimage
  );
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const godsEye = useSelector((state) => state.app.godsEye);
  const dispatch = useDispatch();

  return (
    <Stack
      borderBottom={`1px solid ${colors.borderGrey}`}
      zIndex={3}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      pr={isSmallScreen ? 2 : 4}
      paddingLeft={
        isSmallScreen ? 2 : `calc(${!navExpanded ? "100px" : "200px"} + 40px)`
      }
      height="65px"
      width="100%"
      gap={2}
      bgcolor={"#fff"}
    >
      <Stack flexDirection="row" gap={2} alignItems="center" flex={1}>
        {!isSmallScreen &&
          (accountImage == "" || accountImage == null || !accountImage ? (
            <Image style={{ height: 40 }} src={capgemeniIcon} preview={false} />
          ) : (
            <Image style={{ height: 40 }} src={accountImage} preview={false} />
          ))}

        <Stack
          display={isSmallScreen ? "flex" : "none"}
          onClick={() => setSmallNavExpanded(!smallNavExpanded)}
        >
          <AiOutlineMenu size={24} />
        </Stack>

        <Stack width={isSmallScreen ? "100%" : "70%"}>
          <Search
            onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
            placeholder="Search..."
          />
        </Stack>
      </Stack>

      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Stack borderRadius={2} flexDirection="row" gap={1} alignItems="center">
          <Typography fontSize={14} fontWeight={600} color={colors.tableBlack}>
            {godsEye ? "Public" : "Private"}
          </Typography>
          <CustomSwtch
            onChange={() => dispatch(setGodsEye(!godsEye))}
            checked={godsEye}
          />
        </Stack>

        {userDetails?.isSuperAdmin && (
          <MdOutlineAdminPanelSettings
            color={colors.tableBlack}
            cursor="pointer"
            size={isSmallScreen ? 22 : 30}
            onClick={() => navigate("/dashboard/admin/super/accounts")}
          />
        )}
        <MdNotificationsNone
          color={colors.tableBlack}
          cursor="pointer"
          size={isSmallScreen ? 22 : 30}
          onClick={() => navigate("/dashboard/notifications")}
        />
        <Image
          preview={false}
          style={{
            height: isSmallScreen ? 24 : 34,
            width: isSmallScreen ? 24 : 34,
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard/profile")}
          src={userDetails.imagePath}
          alt="img"
        />
      </Stack>
    </Stack>
  );
}
