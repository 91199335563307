import Input from "../Components/Input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { InputAdornment, Link, Stack, Typography } from "@mui/material";
import { colors } from "../utils/constants";
import { Button } from "antd";
import { ROUTES } from "../Routes/pathName";
import LoginHeader from "./Components/LoginHeader";
import LoginCard from "./Components/LoginCard";

function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [isLogin, setLogin] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const _LoginHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await axios.post(process.env.REACT_APP_URL + "login", data);
      if (res.status == 200) {
        setLoading(false);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        navigate("/details");
      } else if (res.status == 202) {
        setLoading(false);
        setErrorVisible(res.data.msg);
        setTimeout(() => {
          setErrorVisible(false);
        }, 3000);
      }
    } catch (err) {
      if (err) {
        setLoading(false);
        setErrorVisible(err.response.data);
        setTimeout(() => {
          setErrorVisible(false);
        }, 3000);
      }
    }
  };
  const _forgetPassword = async (e) => {
    e.preventDefault();
    if (!data.email) {
      return setErrorVisible("Please enter a valid email address.");
    }
    try {
      setLoading(true);
      let res = await axios.post(process.env.REACT_APP_URL + "forget", data);
      if (res.status == 200) {
        setLoading(false);
        localStorage.clear();
      } else if (res.status == 202) {
        setLoading(false);
        setErrorVisible(res.data.msg);
        setTimeout(() => {
          setErrorVisible(false);
        }, 3000);
      }
    } catch (err) {
      if (err) {
        setLoading(false);
        setErrorVisible(err.response.data);
        setTimeout(() => {
          setErrorVisible(false);
        }, 3000);
      }
    }
  };

  return (
    <LoginCard>
      <LoginHeader
        title={isLogin ? "Sign In" : "Forget Password"}
        subTitle="Welcome to Culture OS!"
      />

      <Input
        label="Email"
        onChange={(e) => setData({ ...data, email: e.target.value })}
        value={data.email}
        mb={isLogin ? 40 : 0}
        type="email"
        placeholder=""
      />
      {isLogin && (
        <Stack position="relative">
          <Input
            endAdornment={
              <InputAdornment position="end">
                <AiFillEyeInvisible
                  cursor="pointer"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  size={22}
                  color={colors.lightGrey}
                />
              </InputAdornment>
            }
            label="Password"
            onChange={(e) => setData({ ...data, password: e.target.value })}
            value={data.password}
            type={passwordVisible ? "text" : "password"}
            placeholder=""
          />
        </Stack>
      )}
      <Stack height={2} justifyContent="center">
        <Typography fontSize={14} fontWeight={500} color={colors.themeRed}>
          {errorVisible}
        </Typography>
      </Stack>

      <Stack alignItems="flex-end">
        <Typography
          fontSize={14}
          fontWeight={500}
          sx={{ cursor: "pointer" }}
          color={colors.primaryThemeColor}
          onClick={() => {
            navigate("/login/verify_password");
          }}
        >
          {isLogin ? "Forgot password?" : "Back to login"}
        </Typography>
      </Stack>

      <Button
        type="primary"
        size="large"
        loading={loading}
        onClick={(e) => {
          if (isLogin) _LoginHandler(e);
          else _forgetPassword(e);
        }}
      >
        {isLogin ? "Sign In" : "Send reset link"}
      </Button>

      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={0.5}
      >
        <Typography fontSize={14}>New to our system?</Typography>
        <Link
          onClick={() => navigate(ROUTES.SIGN_UP)}
          sx={{ cursor: "pointer", fontSize: 14 }}
        >
          Sign Up
        </Link>
      </Stack>
    </LoginCard>
  );
}

export default Login;
