import React from "react";
import { useState } from "react";
import axios from "axios";
import { Stack, Typography } from "@mui/material";
import { colors } from "../../../utils/constants";
import LabelledInput from "../../../Components/LabelledInput";
import { Button, Input } from "antd";

export default function CreateProfanity({ setCreateModal, fetch }) {
  const [creating, setCreating] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const _createProfanity = async () => {
    if (name == "") return;
    setCreating(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const body = {
      name,
      description,
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "createProfanity",
      body,
      options
    );
    if (res.status == 200) {
      setName("");
      setDescription("");
      setCreating(false);
      fetch();
      setCreateModal(false);
    } else if (res.status == 202) {
      setErrorVisible(res.data.msg);
      setTimeout(() => {
        setErrorVisible(false);
      }, 4000);
      setCreating(false);
    }
  };

  return (
    <Stack>
      <Typography
        mb={1}
        fontSize={28}
        fontWeight={500}
        color={colors.tableBlack}
      >
        Add Word
      </Typography>
      <Typography
        mb={2}
        fontSize={18}
        fontWeight={400}
        color={colors.tableGrey}
      >
        Add a new work to be banned from CultureOS
      </Typography>

      <Stack>
        <LabelledInput label="Name">
          <Input
            size="large"
            onChange={(e) => setName(e.target.value)}
            value={name}
            type="text"
          />
        </LabelledInput>
      </Stack>

      <Typography color={colors.textRed}>{errorVisible}</Typography>

      <Stack flexDirection="row" mt={4} justifyContent="space-between">
        <Button size="large" onClick={() => setCreateModal(false)}>
          Cancel
        </Button>
        <Button
          loading={creating}
          size="large"
          type="primary"
          onClick={_createProfanity}
        >
          Create
        </Button>
      </Stack>
    </Stack>
  );
}
