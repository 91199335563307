import React from "react";
import CardContainer from "../../../Components/CardContainer";
import { Typography } from "@mui/material";
import { colors } from "../../../utils/constants";

export default function AdminStatsCard({ heading, value }) {
  return (
    <CardContainer flex={1} gap={0.5}>
      <Typography fontSize={16} fontWeight={400} color={colors.tableGrey}>
        {heading}
      </Typography>
      <Typography fontSize={26} fontWeight={600} color={colors.tableBlack}>
        {value}
      </Typography>
    </CardContainer>
  );
}
