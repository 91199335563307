import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import styles from "./Styles/CreateSurvey.module.css";
import { HiPlusSm } from "react-icons/hi";
import TimeInput from "../../Components/TimeInput";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../Components/ButtonLoader";

export default function CreateSurvey() {

  const navigate = useNavigate()
  const [creating, setCreating] = useState(false)

  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyDesc, setSurveyDesc] = useState("");
  const [questionNum, setQuestionNum] = useState(2);

  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");

  // new logic
  const [optionNum, setOptionNum] = useState(2);
  const [options, setOptions] = useState([]);
  const [pollEndDate, setpollEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [pollEndTime, setpollEndTime] = useState("00:00");

  const submitHandler = async () => {
    if (surveyDesc == "" || questions.length == 0) return;

    let surveyData = {
      survey_desc: surveyDesc,
      status: "Open",
    };
    const headerOptions = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "createsurvey",
      surveyData,
      headerOptions
    );
    if (res.status == 200) {
      let data = {
        surveyId: res.data.id,
        question_desc: questions,
      };
      let resp = await axios.post(
        process.env.REACT_APP_URL + "createsurveyquestion",
        data,
        headerOptions
      );
      if (resp.status == 200) {
        setQuestions([]);
        setQuestion("");
        setSurveyDesc("");
      }
    }
  };

  const _createSurvey = async () => {
    setCreating(true)
    const startDate = moment(new Date().toUTCString()).format()
    const endDate = moment(new Date(`${pollEndDate}T${pollEndTime}+05:30`)).format()
    let data = {
      title: surveyTitle,
      description: surveyDesc,
      options: {
        ...options
      },
      startDate,
      endDate,
    };
    const headerOptions = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.post(process.env.REACT_APP_URL + "createsurvey",data,headerOptions);
    if(res.status == 200) {
        setCreating(false)
        setSurveyTitle("")
        setSurveyDesc("");
        setOptions({});
        setOptionNum(2);
    }
  }

  return (
    // old logic
    // <div className={styles.container}>
    //     <div className={styles.header}>
    //     <h1>Create Survey</h1>
    //   </div>

    //     <div class={styles.createSurvey}>
    //         <label>Survey Title</label>
    //         <input value={surveyDesc} onChange={(e) => setSurveyDesc(e.target.value)} placeholder="Survey is about..." type="text" />
    //         <label>End Date</label>
    //         <input value={moment(endDate).format('YYYY-MM-DD')} onChange={(e)=>setEndDate(moment(e.target.value).format())} type="date" />

    //         <label>Question</label>
    //         <textarea style={{marginBottom:8}} value={question} onChange={(e)=>setQuestion(e.target.value)} rows={3} placeholder="Type your question here..." ></textarea>

    //         {questions.length !== 0 && questions.map((i,index) => {
    //             return <p className={styles.question}><span>Question {index+1} -</span> {i}</p>
    //         })}

    //         <div style={{marginBottom:24}}></div>
    //         <label>Set Word Limit</label>
    //         <input placeholder="e.g. 250 char" type="text" />
    //           {/* <div style={{ height: "65%", overflowY: "scroll" }}>
    //             {Array(questionNum)
    //               .fill("")
    //               .map((item, index) => {
    //                 return (
    //                   <>
    //                     <input
    //                       value={questions[`question${index + 1}`] || ""}
    //                       onChange={(e) =>
    //                         setQuestions({
    //                           ...questions,
    //                           [`question${index + 1}`]: e.target.value,
    //                         })
    //                       }
    //                       placeholder={`Question ${index + 1}`}
    //                       type="text"
    //                     />
    //                   </>
    //                 );
    //               })}
    //           </div>
    //           <button
    //             onClick={() => setQuestionNum(questionNum + 1)}
    //             className={styles.addOption}
    //           >
    //             Add Option
    //           </button> */}
    //         </div>

    //         <button className={styles.addQtn} onClick={()=>{
    //             setQuestions([...questions,question])
    //             setQuestion('')
    //             }}>
    //             Add Question
    //         </button>

    //         <button className={styles.submitBtn} onClick={()=>submitHandler()}>
    //             Create Survey
    //         </button>
    // </div>

    // new logic
    <div className={styles.container}>
      <div className={styles.header}>
        <FiChevronLeft onClick={()=>navigate(-1)} style={{cursor:'pointer'}} size={33} color="#fff" />
        <h1>Create Survey</h1>
      </div>

      <div className={styles.createSurvey}>
      <label>Survey Title</label>
        <textarea
          value={surveyTitle}
          onChange={(e) => setSurveyTitle(e.target.value)}
          placeholder="Write a title..."
          rows="2"
          type="text"
        />
        <br />
        <label>Survey Description</label>
        <textarea
          value={surveyDesc}
          onChange={(e) => setSurveyDesc(e.target.value)}
          placeholder="Survey is about..."
          rows="5"
          type="text"
        />

        <div className={styles.createPoll}>
          {Array(optionNum)
            .fill("")
            .map((item, index) => {
              return (
                <input
                  key={"pool" + index}
                  value={options[`option${index + 1}`] || ""}
                  onChange={(e) =>
                    setOptions({
                      ...options,
                      [`option${index + 1}`]: e.target.value,
                    })
                  }
                  placeholder={`Option ${index + 1}`}
                  type="text"
                />
              );
            })}
          <button onClick={() => setOptionNum(optionNum + 1)}>
            <HiPlusSm size={20} /> Add Option
          </button>
          <div
            style={{
              display: "flex",
              gap: "40px",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div>
              <p style={{ color: "#828282", fontSize: 16, marginBottom: 2 }}>
                End Date
              </p>
              <input
                onChange={(e) => {
                  setpollEndDate(e.target.value);
                }}
                value={pollEndDate}
                type="date"
                min={new Date().toISOString().split("T")[0]}
                style={{ marginBottom: 0 }}
              />
            </div>
            <div>
              <p style={{ color: "#828282", fontSize: 16, marginBottom: 2 }}>
                Time
              </p>
              <TimeInput
                name="time"
                placeholder="00:00"
                className="s-input -time"
                onTimeChange={(e) => {
                  setpollEndTime(e);
                }}
              />
            </div>
          </div>
        </div>

        <button className={styles.submitBtn} onClick={_createSurvey}>
          {creating ? <ButtonLoader /> : 'Create Survey'}
        </button>
      </div>
    </div>
  );
}
