import React, { useState } from 'react'
import { BiLink } from 'react-icons/bi'
import { FaUserCircle } from 'react-icons/fa'
import { MdClose, MdMic, MdOutlineOpenInFull } from 'react-icons/md'
import styles from "./Styles/ChatBox.module.css"

export default function ChatBox() {

  const [message, setMessage] = useState('')

  const [chats, setChats] = useState([{
    type: 'bot',
    message: 'Hello User! Welcome to culture OS'
  },
  {
    type: 'bot',
    message: 'How can I be of Assistance?'
  }])

  const _sendMessage = () => {
    setChats([...chats, {
      type:'user',
      message
    },
    {
      type:'bot',
      message:"Please wait while I get back to you."
    }])
    setMessage('')
  }

  return (
    <div className={styles.container}>
        <div className={styles.chatBox}>
        <header>
          <h2>ChatBot</h2>
        </header>

       <div className={styles.chats}>

        {chats.map(c => {
          return <div className={c.type == 'bot' ? styles.botchat : styles.userchat}>
          <div>
          <FaUserCircle size={40} color="#1E71F2" />
          <h2>{c.type == 'bot' ? 'ChatBot' : 'User'}</h2>
          </div>
          <p>{c.message}</p>
        </div>
        })}

       </div>

       <footer>
         <div style={{position:'relative',width:'100%'}}>
         <input onChange={(e)=>setMessage(e.target.value)} placeholder="Enter message here" type="text" />
         <BiLink className={styles.attachmentIcon} size={26} color="#455A64" />
         <MdMic className={styles.micIcon} size={26} color="#455A64" />
         </div>
         <button onClick={()=>_sendMessage()} className={styles.sendBtn}>Send</button>
       </footer>
      <div>
      </div>
      </div>
    </div>
  )
}
