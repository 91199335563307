import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "moment-timezone";
import Loader from "../../Components/Loader";
import { UserDetailsContext } from "../../Context/UserDetailsContext";
import { RotatingLines } from "react-loader-spinner";
import "react-dropdown/style.css";
import Card from "./Components/Card";
import InfiniteScroll from "react-infinite-scroll-component";
import BroadcastMessage from "./Components/BroadcastMessage";
import SurveyMessage from "./Components/SurveyMessage";
import BannedModal from "./Components/BannedModal";
import ReportModal from "./Components/ReportModal";
import CreatePost from "./Components/CreatePost";
import { Alert, Popover, Stack } from "@mui/material";
import CardContainer from "../../Components/CardContainer";
import PollStats from "../../Components/Polls/PollStats";
import PageTitle from "../../Components/PageTitle";
import TabsComponent from "../../Components/TabsComponent";
import OngoingPoll from "../../Components/Polls/OngoingPoll";
import KudosCard from "../../Components/Karma/KudosCard";
import Filter from "./Components/Filter";
import { Modal } from "antd";
import PageWrapper from "../../Layout/PageWrapper";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/pathName";
import { getAllUsers, getUserDetails } from "../../Api/client";
import { useDispatch, useSelector } from "react-redux";
import { setAllUsers, setKarmaPoints } from "../../store/reducers/appActions";

export default function Community() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const { setUserDetails } = useContext(UserDetailsContext);

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("All Posts");

  const [reportId, setReportId] = useState("");

  const [bannedModal, setBannedModal] = useState(false);
  const [page, setPage] = useState(1);
  const [broadcastMessages, setBroadcastMessages] = useState([]);
  const [surveyMessages, setSurveyMessages] = useState([]);

  const godsEye = useSelector((state) => state.app.godsEye);

  const navigateToPost = (id) => {
    navigate(generatePath(ROUTES.POST_DESCRIPTION, { id }));
  };

  const _getUserDetails = async () => {
    const id = JSON.parse(localStorage.getItem("user")).id;
    let res = await getUserDetails(id);
    if (res.status == 200) {
      setUserDetails(res.data[0]);
    }
  };

  const _getLiveBroadcastMessages = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getLiveBroadcastMessages",
      options
    );
    if (res.status == 200) {
      let sortedArr = res.data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setBroadcastMessages(sortedArr);
    }
  };

  const _getLiveSurveys = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getLiveSurveys",
      options
    );
    if (res.status == 200) {
      let sortedArr = res.data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setSurveyMessages(sortedArr);
    }
  };

  const _getLeaderboard = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let res = await axios.get(
      process.env.REACT_APP_URL + "kudos/leaderboard",
      options
    );
    if (res.status == 200) {
      const currentuser = JSON.parse(localStorage.getItem("user")).id;
      dispatch(
        setKarmaPoints({
          leaderBoard: res.data,
          myKarmaPoints: res.data.find((i) => i.id === currentuser),
        })
      );
    }
  };

  useEffect(() => {
    _getUserDetails();
    _getLeaderboard();
    _getLiveBroadcastMessages();
    _getLiveSurveys();
  }, []);

  const _getAllPosts = async () => {
    setIsLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + `getAllPosts?page=${1}&public=${godsEye}`,
      options
    );
    if (res.status == 200) {
      let sortedArr = [
        ...res.data.karma,
        ...res.data.posts,
        ...res.data.polls,
      ].sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setPosts(sortedArr);
      setAllPosts(sortedArr);
      setLoading(false);
      setIsLoading(false);
    }
  };

  const fetchMoreData = async () => {
    setIsLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL +
        `${
          activeTab == "All Posts"
            ? "getAllPosts"
            : activeTab == "Media Posts"
            ? "getMediaPosts"
            : "getOngoingPosts"
        }?page=${page}&public=${godsEye}`,
      options
    );
    if (res.status == 200) {
      let sortedArr = [
        ...res.data.karma,
        ...res.data.posts,
        ...res.data.polls,
      ].sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setPosts((prevItems) => [...prevItems, ...sortedArr]);
      setAllPosts((prevItems) => [...prevItems, ...sortedArr]);
      setLoading(false);
      setIsLoading(false);
      setPage((prevPage) => prevPage + 1);
    }
  };

  const _getMediaPosts = async () => {
    setIsLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + `getMediaPosts?page=${1}&public=${godsEye}`,
      options
    );
    if (res.status == 200) {
      let sortedArr = [
        ...res.data.karma,
        ...res.data.posts,
        ...res.data.polls,
      ].sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setPosts(sortedArr);
      setAllPosts(sortedArr);
      setLoading(false);
      setIsLoading(false);
    }
  };

  const _getOngoingPosts = async () => {
    setIsLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + `getOngoingPosts?page=${1}&public=${godsEye}`,
      options
    );
    if (res.status == 200) {
      let sortedArr = [
        ...res.data.karma,
        ...res.data.posts,
        ...res.data.polls,
      ].sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setPosts(sortedArr);
      setAllPosts(sortedArr);
      setLoading(false);
      setIsLoading(false);
    }
  };

  const _getAllUsers = async () => {
    const res = await getAllUsers();
    if (res.status == 200) {
      dispatch(setAllUsers(res.data));
    }
  };

  const updateObjectKey = (objectId, keyToUpdate, newValue) => {
    setPosts((prevArray) => {
      return prevArray.map((obj) => {
        if (obj.id === objectId) {
          return {
            ...obj,
            [keyToUpdate]: newValue,
          };
        }
        return obj;
      });
    });
  };

  useEffect(() => {
    _getAllUsers();
  }, []);

  useEffect(() => {
    _getAllPosts();
  }, [godsEye]);

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterTime, setFilterTime] = useState("Most Recent");
  const [filterStatus, setFilterStatus] = useState("");
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const [reportedMessages, setReportedMessages] = useState([]);

  const getReportedPosts = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getReportedPosts",
      options
    );
    if (res.status == 200) {
      setReportedMessages(res.data);
    }
  };

  const viewReportedMessage = async (id) => {
    setReportedMessages(reportedMessages.filter((i) => i.id !== id));
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios.get(
      process.env.REACT_APP_URL + "report/" + id + "/acknowlegde",
      options
    );
  };

  useEffect(() => {
    getReportedPosts();
  }, []);

  useEffect(() => {
    setPage(1);
    if (activeTab == "All Posts") {
      _getAllPosts();
    } else if (activeTab == "Media Posts") {
      _getMediaPosts();
    } else if (activeTab == "Ongoing Poll") {
      _getOngoingPosts();
    }
  }, [activeTab]);

  // main return
  return (
    <>
      {loading && <Loader />}

      <Modal closeIcon={null} footer={null} open={bannedModal}>
        <BannedModal setBannedModal={setBannedModal} />
      </Modal>

      <Modal
        closeIcon={null}
        footer={null}
        open={reportId !== "" ? true : false}
      >
        <ReportModal
          setLoading={setLoading}
          reportId={reportId}
          fetch={_getAllPosts}
          setReportId={setReportId}
        />
      </Modal>

      <PageWrapper>
        <CardContainer>
          <CreatePost fetch={_getAllPosts} />
        </CardContainer>

        {reportedMessages.length > 0 && (
          <Stack mb={2} gap={1}>
            {reportedMessages.map((message) => {
              return (
                <Alert
                  severity="error"
                  onClose={() => viewReportedMessage(message.id)}
                >
                  Your post has been removed due to violation of our community
                  guidelines, which prohibits profanity, obscenity, and hate
                  speech. Your identity remains anonymous.
                </Alert>
              );
            })}
          </Stack>
        )}

        <PageTitle
          title="Recent Posts"
          subTitle="Your Latest Feed"
          setAnchorElFilter={setAnchorElFilter}
        />
        <Popover
          open={Boolean(anchorElFilter)}
          anchorEl={anchorElFilter}
          onClose={() => setAnchorElFilter(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Filter
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            filterTime={filterTime}
            setPosts={setPosts}
            filterStatus={filterStatus}
            allPosts={allPosts}
            setFilterStartDate={setFilterStartDate}
            setFilterEndDate={setFilterEndDate}
            setFilterTime={setFilterTime}
            setFilterStatus={setFilterStatus}
            setAnchorElFilter={setAnchorElFilter}
          />
        </Popover>

        <TabsComponent
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={["All Posts", "Media Posts", "Ongoing Poll"]}
        />

        {broadcastMessages.length !== 0 &&
          broadcastMessages.map((item, index) => {
            return <BroadcastMessage key={index} item={item} />;
          })}

        {surveyMessages.length !== 0 &&
          surveyMessages.map((item, index) => {
            return (
              <SurveyMessage fetch={_getLiveSurveys} key={index} item={item} />
            );
          })}

        <InfiniteScroll
          hasMore={true}
          dataLength={posts.length}
          next={fetchMoreData}
          loader={
            <Stack
              flexDirection="row"
              justifyContent="center"
              overflow="hidden"
            >
              <RotatingLines
                strokeColor="#2262C6"
                strokeWidth="5"
                animationDuration="0.75"
                width="36"
                visible={isLoading}
              />
            </Stack>
          }
        >
          {posts.length !== 0 &&
            posts.map((item) =>
              item.post_title ? (
                <CardContainer
                // onClick={() => navigateToPost(item.id)}
                >
                  <Card
                    item={item}
                    editable
                    setLoading={setLoading}
                    fetch={_getAllPosts}
                    setReportId={setReportId}
                    updateObjectKey={updateObjectKey}
                  />
                </CardContainer>
              ) : item.poll_title ? (
                <CardContainer>
                  <Card
                    setReportId={setReportId}
                    editable
                    setLoading={setLoading}
                    fetch={_getAllPosts}
                    item={item}
                  />
                  <OngoingPoll
                    polls={posts}
                    setPolls={setPosts}
                    item={item}
                    fetch={_getAllPosts}
                  />
                  <PollStats item={item} />
                </CardContainer>
              ) : item.kudos_by ? (
                <KudosCard item={item} />
              ) : null
            )}
        </InfiniteScroll>
      </PageWrapper>
    </>
  );
}
