import { Stack, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { NavContext } from "../Context/NavContext";
import RightCards from "../Components/RightCards";
import PageHeader from "../Components/PageHeader";
import { colors } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../Routes/pathName";

const topBarHeight = 65;

export default function PageWrapper({ children, hideRightCards }) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { navExpanded } = useContext(NavContext);
  const navigate = useNavigate();

  return (
    <>
      {isSmallScreen && <PageHeader />}
      <Stack
        ml={isSmallScreen ? 0 : navExpanded ? "200px" : "100px"}
        bgcolor={colors.pageBackground}
        padding="20px"
        style={{
          display: "grid",
          gridTemplateColumns:
            hideRightCards || isSmallScreen ? "100%" : "calc(70% - 24px) 30%",
          transition: "all .3s",
          marginTop: isSmallScreen ? 95 : topBarHeight,
          minHeight: `calc(100vh - ${topBarHeight}px)`,
        }}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Stack>{children}</Stack>

        {!hideRightCards && !isSmallScreen && <RightCards />}
      </Stack>
    </>
  );
}
