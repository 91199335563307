import React, { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import LabelledInput from "../../../Components/LabelledInput";
import { ButtonBase, Chip, Stack, Typography } from "@mui/material";
import { colors } from "../../../utils/constants";
import { Button, Input } from "antd";

export default function CreateAccount({ setCreateAccModal, fetch }) {
  const [creating, setCreating] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [image, setImage] = useState({});
  const [uploadError, setUploadError] = useState("");
  const fileRef = useRef();

  const _registerAccount = async () => {
    if (accountName == "") return;
    setCreating(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const body = {
      domain: accountName,
      image: image?.url,
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "registerAccount",
      body,
      options
    );
    if (res.status == 200) {
      setAccountName("");
      setCreating(false);
      fetch();
      setCreateAccModal(false);
    } else if (res.status == 202) {
      setErrorVisible(res.data.msg);
      setTimeout(() => {
        setErrorVisible(false);
      }, 4000);
      setCreating(false);
    }
  };

  const uploadToCloud = async (_image, type) => {
    let fileSize = (_image.size / 1048576).toFixed(2);
    fileSize = parseFloat(fileSize);

    const data = new FormData();
    data.append("file", _image);
    data.append("upload_preset", "vatg9qsy");
    data.append("api_key", "755813337218451");
    data.append("cloud_name", "culture");
    let res = await axios.post(
      `https://api.cloudinary.com/v1_1/vatg9qsy/${
        type !== "File" ? type.toLowerCase() : "raw"
      }/upload`,
      data
    );
    if (res.status == 200) {
      setImage({
        mediaType: _image.type == "application/pdf" ? "pdf" : type,
        name: Date.now(),
        url: res.data.secure_url,
        size: res.data.bytes,
        format: res.data.format,
        type: res.data.resource_type,
      });
      setUploadError("");
    }
  };

  return (
    <Stack p={1}>
      <Typography
        mb={1}
        fontSize={28}
        fontWeight={500}
        color={colors.tableBlack}
      >
        Create Account
      </Typography>
      <Typography
        mb={2}
        fontSize={18}
        fontWeight={400}
        color={colors.tableGrey}
      >
        Register a new account with Culture OS
      </Typography>

      <ButtonBase onClick={() => fileRef.current.click()}>
        <Stack
          sx={{
            transition: "all .3s",
            ":hover": { bgcolor: "#e1edfe" },
          }}
          width="100%"
          border="1px dashed"
          borderColor={colors.primaryThemeColor}
          borderRadius={2}
          alignItems="center"
          justifyContent="center"
          height={90}
        >
          <Typography
            fontSize={16}
            fontWeight={500}
            color={colors.primaryThemeColor}
          >
            Click to upload logo
          </Typography>
        </Stack>
      </ButtonBase>

      {image.name && (
        <Chip
          sx={{ width: "fit-content", mt: 1 }}
          label={image.name}
          variant="outlined"
          onDelete={() => {
            setImage({});
          }}
        />
      )}

      <br />

      <LabelledInput label="Domain Name">
        <Input
          size="large"
          placeholder="e.g. example.com"
          onChange={(e) => setAccountName(e.target.value)}
          value={accountName}
          type="text"
        />
      </LabelledInput>

      <Typography color={colors.textRed}>{errorVisible}</Typography>

      <Stack
        flexDirection="row"
        justifyContent="space-between"
        mt={4}
      >
        <Button size="large" onClick={() => setCreateAccModal(false)}>Cancel</Button>
        <Button size="large" type="primary" onClick={_registerAccount} loading={creating}>
          Create
        </Button>
      </Stack>

      <input
        hidden
        ref={fileRef}
        onChange={(e) => uploadToCloud(e.target.files[0], "Image")}
        name="file-upload-field"
        type="file"
        value=""
      />
    </Stack>
  );
}
