import React, { useContext, useEffect, useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import PageWrapper from "../../Layout/PageWrapper";
import karmaIcon_white from "../../Assets/karmaIcon_white.png";
import threadsIcon from "../../Assets/threadsIcon.png";
import keepTrackIcon from "../../Assets/keepTrackIcon.png";
import polls_inactive from "../../Assets/polls_inactive.png";
import axios from "axios";
import { UserDetailsContext } from "../../Context/UserDetailsContext";
import CardContainer from "../../Components/CardContainer";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Button, Image, Modal, Tag } from "antd";
import { colors } from "../../utils/constants";
import { getUserDetails } from "../../Api/client";
import { useSelector } from "react-redux";
import EditProfile from "./Components/EditProfile";

export default function Profile() {
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);
  const karmaPoints = useSelector((state) => state.app.karmaPoints);
  const [modalVisible, setModalVisible] = useState(false);
  const [stats, setStats] = useState({});

  const _getUserDetails = async () => {
    let res = await getUserDetails(userDetails?.id);
    if (res.status == 200) {
      setUserDetails(res.data[0]);
    }
  };

  const _getUserStats = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getUserStats",
      options
    );
    if (res.status == 200) {
      setStats(res.data);
    }
  };

  useEffect(() => {
    _getUserStats();
  }, []);

  return (
    <>
      <Modal
        centered
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        closeIcon={null}
      >
        <EditProfile
          setModalVisible={setModalVisible}
          fetch={_getUserDetails}
        />
      </Modal>

      <PageWrapper>
        <CardContainer padding={4}>
          <Stack flexDirection="row" alignItems="center" gap={4}>
            <Image
              width={158}
              height={158}
              src={userDetails.imagePath}
              preview={false}
            />

            <Stack flexDirection="column" alignItems="flex-start" gap={1}>
              <Tag color={colors.primaryThemeColor}>Active</Tag>
              <Typography
                fontSize={26}
                fontWeight={500}
                color={colors.tableBlack}
              >
                {userDetails?.username}
              </Typography>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <MdOutlineEmail size={20} color={colors.tableBlack} />
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={colors.tableBlack}
                >
                  {userDetails?.email_Id}
                </Typography>
              </Stack>

              <Button
                type="default"
                style={{
                  borderColor: colors.primaryThemeColor,
                  color: colors.primaryThemeColor,
                  fontWeight: 600,
                }}
                onClick={() => setModalVisible(true)}
              >
                Edit Profile
              </Button>
            </Stack>

            <Box
              sx={{
                ml: "auto",
                "& .MuiStack-root": {
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& .MuiTypography-root": {
                  fontSize: 16,
                  fontWeight: 500,
                  color: colors.WHITE,
                },
              }}
            >
              <Stack height={146} width={146} bgcolor="#91ECEF">
                <Stack height={128} width={128} bgcolor="#22D9DE">
                  <Stack height={105} width={105} bgcolor="#00B7BE" gap={0.5}>
                    <Stack flexDirection="row" alignItems="center" gap={0.5}>
                      <img width={20} src={karmaIcon_white} alt="icon" />
                      <Typography>
                        {karmaPoints?.myKarmaPoints?.points || 0}
                      </Typography>
                    </Stack>
                    <Typography>My Karma</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </CardContainer>

        <CardContainer gap={1.5}>
          <Stack>
            <Typography fontSize={22} fontWeight={500}>
              Contribution
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              (Non Editable - Can only be viewed by you)
            </Typography>
          </Stack>

          <Divider
            orientation="horizontal"
            sx={{ bgcolor: colors.borderRadius, height: 2 }}
          />

          <Stack
            sx={{
              "& .MuiStack-root": {
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              },
              "& .MuiTypography-root": {
                fontSize: 14,
                fontWeight: 400,
              },
              img: { width: "24px", height: "24px" },
            }}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              <img src={threadsIcon} alt="icon" />
              <Typography>
                Threads:{" "}
                <span style={{ fontWeight: 600 }}>{stats?.threads || 0}</span>
              </Typography>
            </Stack>

            <Stack>
              <img src={polls_inactive} alt="icon" />
              <Typography>
                Polls:{" "}
                <span style={{ fontWeight: 600 }}>{stats?.polls || 0}</span>
              </Typography>
            </Stack>

            <Stack>
              <img src={keepTrackIcon} alt="icon" />
              <Typography>
                Keeping Track:{" "}
                <span style={{ fontWeight: 600 }}>
                  {stats?.keepingTrack || 0}
                </span>
              </Typography>
            </Stack>

            <Stack>
              <img src={keepTrackIcon} alt="icon" />
              <Typography>
                Karms Given:{" "}
                <span style={{ fontWeight: 600 }}>
                  {stats?.karmaGiven || 0}
                </span>
              </Typography>
            </Stack>
          </Stack>
        </CardContainer>
      </PageWrapper>
    </>
  );
}
