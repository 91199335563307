import React, { useState } from "react";
import styles from "../Styles/Community.module.css";
import pstyles from "../Styles/Poll.module.css";
import { BiTimeFive } from "react-icons/bi";
import moment from "moment";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useContext } from "react";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";
import axios from "axios";

export default function SurveyMessage({ item, fetch }) {
  const [show, setShow] = useState(false)
  const { userDetails, setUserDetails } = useContext(UserDetailsContext)
  const [loading, setLoading] = useState(false)

  const _handleVote = async (item, opt) => {
    setLoading(true)
    const headerOptions = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      id: item.id,
      selectedOption: opt
    }
    let res = await axios.put(process.env.REACT_APP_URL + 'survey/giveVote', data, headerOptions)
    if (res.status == 200) {
      setLoading(false)
      fetch()
    }
  }

  return (
    <div className={styles.survey}>
      <div className={styles.broadCastHeader}>
        <h3 className={styles.broadcastTitle}>{item?.title}</h3>
        <div onClick={()=>setShow(!show)} className={styles.broadcastTag}>Survey {!show ? <FaChevronDown size={14} style={{marginTop:2}} color="#2262C6" /> : <FaChevronUp size={14} style={{marginTop:2}} color="#2262C6" />}</div>
      </div>

      <div
        className={styles.broadcastTime}
        style={{ display: "flex", gap: 4, alignItems: "center" }}
      >
        <BiTimeFive style={{ marginTop: 2 }} size={20} color="#d9d9d9" />
        <p>{moment(item?.createdAt).format("ll LT")}</p>
      </div>

      {show && <div className={styles.broadcastContent}>
        <p>{item?.description}</p>
        
        <br />
        <div style={{marginBottom:0}} className={pstyles.pollContent}>
            <div className={pstyles.options}>
              {(item?.options !== null && item?.options?.length !== 0) ? item?.options?.map((opt, index) => {
                let totalVotes = item.options?.length !== 0 && item.options.reduce((a, b) => {
                  return a + (b.votes.length);
                }, 0)
               return <div className={pstyles.ongoingPolls}>
                  <h3 onClick={() => {
                      _handleVote(item, opt)
                   }}>{opt.option}<span style={{ width: `${((opt.votes?.length / totalVotes) * 100)}%` }}></span>
                   </h3>
                 </div>
                }) : null}
              </div>

              <p style={{ color: '#fff',marginTop:10 }}>{item.options?.length !== 0 && item.options?.reduce((a, b) => {
                  return a + (b.votes.length);
            }, 0)} Votes</p>
            </div>
      </div>}
    </div>
  );
}
