import React, { useContext, useEffect, useState } from "react";
import CardContainer from "../CardContainer";
import { Chip, Stack, Typography } from "@mui/material";
import Search from "../Search";
import karmaIcon_inactive from "../../Assets/karmaIcon_inactive.png";
import axios from "axios";
import { UserDetailsContext } from "../../Context/UserDetailsContext";
import { colors } from "../../utils/constants";
import userIcon from "../../Assets/userIcon.png";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Button, Input } from "antd";
import UsersList from "./UsersList";

export default function GiveKudos({ fetch }) {
  const { userDetails } = useContext(UserDetailsContext);
  const [comment, setComment] = useState("");
  const [customTrait, setCustomTrait] = useState("");
  const [traits, setTraits] = useState([]);
  const [point, setPoint] = useState("");
  const [name, setName] = useState("");
  const [names, setNames] = useState([]);
  const [creating, setCreating] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const [points, setPoints] = useState(["100", "200", "300", "400", "500"]);
  const [ratings, setRatings] = useState([
    "Helpful",
    "Collaborative",
    "Social",
  ]);

  const _getUserByName = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getuserbyname/" + name,
      options
    );
    if (res.status == 200) {
      setNames(res.data.filter((i) => i.id !== userDetails?.id));
    }
  };


  const _giveKudos = async () => {
    if (Object.keys(selectedUser).length == 0)
      return alert("Please select a user before continuing");
    setCreating(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      kudos_comment: comment,
      kudos_points: point,
      kudos_traits: traits,
      receiverId: selectedUser?.id,
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "kudos/create",
      data,
      options
    );
    if (res.status == 200) {
      fetch();
      setSelectedUser({});
      setComment("");
      setName("");
      setTraits([]);
      setPoint("");
      setCreating(false);
    }
  };

  useEffect(() => {
    if (name !== "") {
      _getUserByName();
    }
  }, [name]);

  return (
    <CardContainer gap={1.5}>
      <Typography fontSize={16} fontWeight={500}>
        Give Karma
      </Typography>

      <Stack position="relative">
        <Search
          placeholder="Search Employee"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {name !== "" && names.length !== 0 ? (
          <UsersList
            names={names}
            setSelectedUser={setSelectedUser}
            setName={setName}
            setNames={setNames}
          />
        ) : null}
      </Stack>

      <Stack
        gap={0.5}
        sx={{
          "input, textarea": {
            border: "none",
            bgcolor: "#F3F5F8",
            resize: "none",
            fontSize: 16,
            outline: "none",
            padding: "2px",
          },
        }}
        width="100%"
        bgcolor="#F3F5F8"
        borderRadius={2}
        p={1}
      >
        <input
          disabled
          value={selectedUser?.name || ""}
          contentEditable={false}
          placeholder="Employee Name"
          type="text"
        />
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows="2"
          placeholder="Add your text"
        />
      </Stack>

      <Stack flexDirection="row">
        <Stack minWidth="180px" flexDirection="row" alignItems="center" gap={1}>
          <img width="20px" src={karmaIcon_inactive} alt="icon" />
          <Typography fontSize={14}>Select Karma Points</Typography>
        </Stack>

        <Stack gap={1} alignItems="center" flexDirection="row">
          <Input
            onChange={(e) => setPoint(e.target.value)}
            value={point}
            placeholder="Custom Points"
            type="number"
          />
          {points.map((p, index) => {
            return (
              <Chip
                sx={{
                  ":hover": { bgcolor: colors.primaryThemeColor },
                  bgcolor: point == p ? colors.primaryThemeColor : "#fff",
                  color: point == p ? "#fff" : colors.primaryThemeColor,
                  borderColor: colors.primaryThemeColor,
                }}
                variant="outlined"
                label={p}
                onClick={() => setPoint(p)}
              />
            );
          })}
        </Stack>
      </Stack>

      <Stack flexDirection="row">
        <Stack minWidth="180px" flexDirection="row" alignItems="center" gap={1}>
          <img width="20px" src={userIcon} alt="icon" />
          <Typography fontSize={14}>Select Karma Traits</Typography>
        </Stack>

        <Stack flexDirection="row" alignItems="center" flexWrap="wrap" gap={1}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setRatings([...ratings, customTrait]);
              setTraits([...traits, customTrait]);
              setCustomTrait("");
            }}
          >
            <Input
              onChange={(e) => setCustomTrait(e.target.value)}
              value={customTrait}
              placeholder="Custom Trait"
              type="text"
            />
          </form>
          {ratings.map((r, index) => {
            return (
              <Chip
                sx={{
                  ":hover": { bgcolor: colors.primaryThemeColor },
                  bgcolor: traits.includes(r) ? "#2262C6" : "#fff",
                  color: traits.includes(r) ? "#fff" : "#2262C6",
                  borderColor: colors.primaryThemeColor,
                }}
                variant="outlined"
                label={r}
                onClick={() => {
                  if (!traits.includes(r)) {
                    setTraits([...traits, r]);
                  } else {
                    setTraits(traits.filter((t) => t !== r));
                  }
                }}
              />
            );
          })}
        </Stack>
      </Stack>

      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack alignItems="center" flexDirection="row" gap={1}>
          <Stack
            bgcolor="#FBF3D6"
            alignItems="center"
            justifyContent="center"
            height={32}
            width={32}
            borderRadius={2}
            flexShrink={0}
          >
            <AiOutlineInfoCircle size={20} />
          </Stack>
          <Typography fontSize={13}>
            Karma Traits can be given to enhance and emphasize the quality of an
            employee.
          </Typography>
        </Stack>
        <Button
          style={{
            backgroundColor: colors.primaryThemeColor,
          }}
          size="middle"
          type="primary"
          onClick={_giveKudos}
          loading={creating}
        >
          Submit
        </Button>
      </Stack>
    </CardContainer>
  );
}
