export const currentUser = (currentUserId, dataUserId) => {
  if (currentUserId == dataUserId) {
    return true;
  } else {
    return false;
  }
};

export const downloadFile = (url, name) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert("An error occured while downloading the image."));
};
