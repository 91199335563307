import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import HeaderCard from "./HeaderCard";
import axios from "axios";
import moment from "moment";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

export default function Header() {
  const [selected, setSelected] = useState("");
  const [data, setData] = useState([])
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'))
  const score = useSelector(state => state.app.score)

  const _getStatsAdmin = async () => {
    const options = {
      headers: {
        'Content-type': 'application/json',
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
    };
    let res = await axios.get(process.env.REACT_APP_URL + 'getscoreAdmin/' + JSON.parse(localStorage.getItem('user'))?.accountId + '/' + selectedYear , options)
    if (res.status == 200) {
      setData(res?.data)
    }
  }

  useEffect(()=>{
    _getStatsAdmin()
  },[selectedYear])

  return (
    <Grid container className={styles.header}>
      {(selected == "cultex" || selected == "") && (
        <HeaderCard
          setSelectedYear={setSelectedYear}
          data={data}
          onClick={() => {
            if (selected !== "cultex") {
              setSelected("cultex");
            } else {
              setSelected("");
            }
          }}
          mainColor="#B822DD"
          secondaryColor="#DDA5EF"
          selectedTab="cultex"
          percentageValue={score?.cultex}
          title="CulteX"
          subTitle="Cultural Index"
          selected={selected}
          setSelected={setSelected}
        />
      )}

      {(selected == "toxin" || selected == "") && (
        <HeaderCard
          setSelectedYear={setSelectedYear}
          data={data}
          onClick={() => {
            if (selected !== "toxin") {
              setSelected("toxin");
            } else {
              setSelected("");
            }
          }}
          mainColor="#22D2DD"
          secondaryColor="#A6E5EB"
          selectedTab="toxin"
          percentageValue={score?.toxin}
          title="ToxiN"
          subTitle="Toxicity Index"
          selected={selected}
          setSelected={setSelected}
        />
      )}

      {/* {(selected == "attrition" || selected == "") && (
        <HeaderCard
          setSelectedYear={setSelectedYear}
          data={data}
          onClick={() => {
            if (selected !== "attrition") {
              setSelected("attrition");
            } else {
              setSelected("");
            }
          }}
          mainColor="#4722DD"
          secondaryColor="#AFB3EF"
          selectedTab="attrition"
          percentageValue={0}
          title="Attrition"
          selected={selected}
          setSelected={setSelected}
        />
      )} */}
    </Grid>
  );
}
