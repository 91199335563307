import React from "react";
import CardContainer from "./CardContainer";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { colors } from "../utils/constants";

export default function TabsComponent(props) {
  const { tabs, activeTab, setActiveTab } = props;
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <CardContainer hideShadow flexDirection="row" padding={0.5} {...props}>
      {tabs.map((tab) => {
        return (
          <Stack
            key={tab}
            borderRadius={2}
            alignItems="center"
            justifyContent="center"
            flex={1}
            height={isSmallScreen ? 30 : 38}
            sx={{ cursor: "pointer" }}
            bgcolor={activeTab == tab ? "#dee7f5" : "#fff"}
            onClick={() => {
              setActiveTab(tab);
            }}
          >
            <Typography
              fontSize={isSmallScreen ? 12 : 14}
              fontWeight={500}
              color={
                activeTab == tab ? colors.primaryThemeColor : colors.textGrey
              }
            >
              {tab}
            </Typography>
          </Stack>
        );
      })}
    </CardContainer>
  );
}
