import React from 'react'
import { Oval } from 'react-loader-spinner'

const ButtonLoader = () => {
    return <Oval
      height={24}
      width={24}
      color="#fff"
      visible={true}
      secondaryColor="transparent"
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  }

  export default ButtonLoader
