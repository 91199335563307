export const colors = {
  primaryThemeColor: "#2262C6",
  primaryThemeColorDark: "#153870",
  secondaryThemeColor: "#60D1D0",
  primaryButtonColor: "#2262C6",
  pageBackground: "#f4f7fa",
  secondaryButtonColor: "#60D1D0",
  successButtonColor: "SuccessButton",
  dangerButtonColor: "#FF725E",
  primaryBackground: "#FFFFFF",
  lightBlue: "#29b6f6",
  lightGrey: "#adadad",
  lightTurquoise: "rgba(96, 209, 208, 0.1)",
  darkTurquoise: "#6DB4C4",
  dullTurquoise: "#60D1D033",
  tableBlack: "#323c47",
  themeOrange: "#FFA500",
  themeGreen: "#41CD70",
  themeRed: "#FF5757",
  disabledGrey: "#e7e7eb",
  tableGrey: "#707683",
  loginButtonColor: "#111942",
  disabledBlue: "#F1F5FA",
  lightBlack: "#0000009C",
  moodboardBackground: "#EDF5FF",
  primaryDark: "#6DB4C4",
  darkBlue: "#334D6E",
  WHITE: "#fff",
  darkGrey: "#383535",
  titleBlue: "#109CF1",
  textGrey: "#90A0B7",
  borderGrey: '#e3e3e3',
  textBlue: '#1E71F2',
  textRed: 'red',
  successGreen: '#258b59',
  backgroundGrey: '#F4F7FA'
};

export const POST_TYPE = {
  TEXT: 'Text',
  POLL: 'Poll',
  IMAGE: 'Image',
  VIDEO: 'Video',
  FILE: 'File'
}

export const FEEDBACK_TYPE = {
  MOOD: 'MOOD',
  DAYEND: 'DAYEND',
}
