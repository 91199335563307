import React, { useContext, useEffect } from "react";
import karmaIcon from "../Assets/karmaIcon_inactive.png";
import companyStatictics from "../Assets/companyStatictics.png";
import trendingIcon from "../Assets/trendingIcon2.png";
import { TbTrendingUp } from "react-icons/tb";
import Switch from "react-switch";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserDetailsContext } from "../Context/UserDetailsContext";
import { Grid, Stack, Typography } from "@mui/material";
import CardContainer from "../Components/CardContainer";
import { Button } from "antd";
import StatisticsCard from "./StatisticsCard";
import { useSelector, useDispatch } from "react-redux";
import {
  setAnonymous,
  setScore,
  setTrending,
} from "../store/reducers/appActions";
import { ROUTES } from "../Routes/pathName";

export default function RightCards() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anonymous = useSelector((state) => state.app.anonymous);
  const karmaPoints = useSelector((state) => state.app.karmaPoints);
  const score = useSelector((state) => state.app.score);
  const trending = useSelector((state) => state.app.trending);
  const godsEye = useSelector((state) => state.app.godsEye);

  const { userDetails } = useContext(UserDetailsContext);

  const _getTrending = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "trendingaspect/" + userDetails?.accountId,
      options
    );
    if (res.status == 200) {
      dispatch(setTrending(res.data));
    }
  };

  const _getScore = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getscore/" + userDetails?.accountId,
      options
    );
    if (res.status == 200) {
      dispatch(setScore(res.data));
    }
  };

  useEffect(() => {
    if (!Object.keys(score).length && userDetails.accountId) {
      _getScore();
    }
  }, [score]);

  useEffect(() => {
    if (!trending.length && userDetails.accountId) {
      _getTrending();
    }
  }, [trending]);

  return (
    <Stack>
      <CardContainer>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography fontSize={16} fontWeight={500}>
            Anonymous
          </Typography>
          <Switch
            width={51}
            height={31}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#2262C6"
            offColor="#e9e9eb"
            checked={anonymous}
            onChange={() => dispatch(setAnonymous(!anonymous))}
          />
        </Stack>

        <Stack alignItems="flex-start" flexDirection="row" gap={1}>
          <Stack
            bgcolor="#FBF3D6"
            alignItems="center"
            justifyContent="center"
            height={32}
            width={32}
            borderRadius={2}
            flexShrink={0}
          >
            <AiOutlineInfoCircle size={20} />
          </Stack>
          <Typography fontSize={13}>
            Anonymous posting refers to the practice of making a post or comment
            without revealing the user's identity.
          </Typography>
        </Stack>
      </CardContainer>

      {!godsEye && <>
      <CardContainer>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <img width="24px" alt="icon" src={karmaIcon} />
            <Typography fontSize={16} fontWeight={500}>
              Karma Leaderboard
            </Typography>
          </Stack>

          <Button
            type="default"
            onClick={() =>
              navigate(ROUTES.KUDOS, { state: { leaderboard: true } })
            }
          >
            See All
          </Button>
        </Stack>

        <Stack>
          {karmaPoints?.myKarmaPoints && (
            <Stack
              flexDirection="row"
              height="38px"
              bgcolor="#D8EDDD"
              border="1px solid #13954E"
              borderRadius={2}
              alignItems="center"
              justifyContent="space-between"
              px={2}
              mb={2}
            >
              <Grid container>
                <Grid item xs={2}>
                  <Typography color="#383535" fontSize={14} fontWeight={500}>
                    {karmaPoints?.myKarmaPoints?.rank || 0}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography color="#383535" fontSize={14} fontWeight={500}>
                    {karmaPoints?.myKarmaPoints?.name || 0}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography color="#383535" fontSize={14} fontWeight={500}>
                    {karmaPoints?.myKarmaPoints?.points || 0}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          )}

          {karmaPoints?.leaderBoard?.length !== 0 &&
            karmaPoints?.leaderBoard?.slice(0, 3).map((k, index) => {
              return (
                <Grid
                  key={index}
                  container
                  justifyContent="space-between"
                  px={2}
                  borderBottom="1px solid #E1E1E1"
                  py={0.5}
                >
                  <Grid item xs={2}>
                    <Typography fontSize={14}>{k?.rank}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography fontSize={14}>{k?.name}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography fontSize={14}>{k?.points}</Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Stack>
      </CardContainer>

      <CardContainer>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <img width="24px" alt="icon" src={companyStatictics} />
            <Typography fontSize={16} fontWeight={500}>
              Company Statistics
            </Typography>
          </Stack>
        </Stack>

        <Stack gap={1}>
          <StatisticsCard
            bg="linear-gradient(to right, #E95ACA, #F8517E)"
            value={score?.cultex}
            label="CulteX"
          />
          {/* <StatisticsCard bg="linear-gradient(to right, #04CAB0, #359EDC)" value={score?.cultex} label="CulteX" /> */}
          {/* <StatisticsCard bg="linear-gradient(to right, #BC5EEF, #4C419B)" value={score?.cultex} label="CulteX" /> */}

          <Typography fontSize={14} color="grey">
            Cultural Index of your company
          </Typography>
        </Stack>
      </CardContainer>

      <CardContainer>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <img width="24px" alt="icon" src={trendingIcon} />
            <Typography fontSize={16} fontWeight={500}>
              Trending
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          {trending.length !== 0 &&
            trending.slice(0, 9).map((t, index) => {
              return (
                <Stack key={index} borderBottom="1px solid #828282" py={0.5}>
                  <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography fontSize={12} color="#828282">
                      {index + 1}
                    </Typography>
                    <Typography fontSize={12} color="#828282">
                      Discussion
                    </Typography>
                    <Typography fontSize={12} color="#828282">
                      Trending
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center" gap={1}>
                    <TbTrendingUp size={26} color="#2262C6" />
                    <Typography fontSize={14}>{t.string}</Typography>
                  </Stack>
                </Stack>
              );
            })}
        </Stack>
      </CardContainer>
      </>}
    </Stack>
  );
}
