import React from "react";
import styles from "./MediaInputs.module.css";
import { HiPlus } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { useState } from "react";
import axios from "axios";
import imageHomeActive from "../../../Assets/image_home_active.png";
import imageHomeInActive from "../../../Assets/image_home_inactive.png";
import videoHomeActive from "../../../Assets/video_home_active.png";
import videoHomeInActive from "../../../Assets/video_home_inactive.png";
import fileHomeActive from "../../../Assets/file_home_active.png";
import fileHomeInActive from "../../../Assets/file_home_inactive.png";

export default function MediaInputs({ postType, setPostType, image, setImage }) {
  
  const [uploadError, setUploadError] = useState("");
  const [loading, setLoading] = useState(false);

  const uploadToCloud = async (_image, type) => {
    let fileSize = (_image.size / 1048576).toFixed(2);
    fileSize = parseFloat(fileSize);

    if ((postType == "_Image" || postType == "Video") && fileSize > 5) {
      return setUploadError("Please choose media less than 5mb");
    }

    setLoading(true);
    const data = new FormData();
    data.append("file", _image);
    data.append("upload_preset", "vatg9qsy");
    data.append("api_key", "755813337218451");
    data.append("cloud_name", "culture");
    let res = await axios.post(
      `https://api.cloudinary.com/v1_1/vatg9qsy/${
        type !== "File" ? type.toLowerCase() : "raw"
      }/upload`,
      data
    );
    if (res.status == 200) {
      setImage({
        mediaType: _image.type == "application/pdf" ? "pdf" : type,
        name: Date.now(),
        url: res.data.secure_url,
        size: res.data.bytes,
        format: res.data.format,
        type: res.data.resource_type,
      });

      setLoading(false);
      setUploadError("");
    }
  };

  return (
    <div>
      <div className={styles.headerBtns}>
        <div
          onClick={() => setPostType("Image")}
          className={postType == "Image" && styles.activePostType}
        >
          {postType == "Image" ? (
            <img src={imageHomeActive} />
          ) : (
            <img src={imageHomeInActive} />
          )}
          <p>Image</p>
        </div>
        <div
          onClick={() => setPostType("Video")}
          className={postType == "Video" && styles.activePostType}
        >
          {postType == "Video" ? (
            <img src={videoHomeActive} />
          ) : (
            <img src={videoHomeInActive} />
          )}
          <p>Video</p>
        </div>
        <div
          onClick={() => setPostType("File")}
          className={postType == "File" && styles.activePostType}
        >
          {postType == "File" ? (
            <img src={fileHomeActive} />
          ) : (
            <img src={fileHomeInActive} />
          )}
          <p>File</p>
        </div>
      </div>

      {postType == "Image" && (
        <div className={styles.uploadImage}>
          <div
            className={styles.fileUploadWrapper}
            data-text="Select your file!"
          >
            <div>
              <HiPlus size={30} color="#2262C6" />
              <p>Click to attach image</p>
              <p>[Max: 5mb]</p>
            </div>
            <input
              onChange={(e) => uploadToCloud(e.target.files[0], postType)}
              name="file-upload-field"
              type="file"
              value=""
              accept="image/*"
            />
          </div>
          {uploadError !== "" && (
            <p className={styles.uploadError}>{uploadError}</p>
          )}
          {image.name && (
            <div onClick={() => setImage({})} className={styles.uploadedImage}>
              <p>{image.name}</p>
              <MdClose color="#000" size={14} />
            </div>
          )}
        </div>
      )}

      {postType == "Video" && (
        <div className={styles.uploadImage}>
          <div
            className={styles.fileUploadWrapper}
            data-text="Select your file!"
          >
            <div>
              <HiPlus size={30} color="#2262C6" />
              <p>Click to attach video</p>
              <p>[Max: 5mb]</p>
            </div>
            <input
              onChange={(e) => uploadToCloud(e.target.files[0], postType)}
              name="file-upload-field"
              type="file"
              value=""
              accept="video/*"
            />
          </div>
          {uploadError !== "" && (
            <p className={styles.uploadError}>{uploadError}</p>
          )}
          {image.name && (
            <div onClick={() => setImage({})} className={styles.uploadedImage}>
              <p>{image.name}</p>
              <MdClose color="#000" size={14} />
            </div>
          )}
        </div>
      )}

      {postType == "File" && (
        <div className={styles.uploadImage}>
          <div
            className={styles.fileUploadWrapper}
            data-text="Select your file!"
          >
            <div>
              <HiPlus size={30} color="#2262C6" />
              <p>Click to attach file</p>
              <p>[Max: 5mb]</p>
            </div>
            <input
              onChange={(e) => uploadToCloud(e.target.files[0], postType)}
              name="file-upload-field"
              type="file"
              value=""
            />
          </div>
          {image.name && (
            <div onClick={() => setImage({})} className={styles.uploadedImage}>
              <p>{image.name}</p>
              <MdClose color="#000" size={14} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
