import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs'
import { FaBars, FaComment, FaUserCircle } from 'react-icons/fa'
import { HiClipboardList } from 'react-icons/hi'
import { RiBarChartFill } from 'react-icons/ri'
import Loader from '../../Components/Loader'
import styles from "./Styles/Points.module.css"

export default function Points() {

  const [projectsCurve, setProjectsCurve] = useState([])
  const [leadsCurve, setLeadsCurve] = useState([])

  const [stats, setStats] = useState({})
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState(JSON.parse(localStorage.getItem('user')).username || '')

  const _getUserStats = async () => {
    setLoading(true)
    const options = {
        headers: {
            'Content-type': 'application/json',
            "Authorization": 'Bearer ' + localStorage.getItem('token')
        }
      };
      let res = await axios.get(process.env.REACT_APP_URL + 'getUserStats',options)
      if(res.status == 200) {
        setStats(res.data)
        setLoading(false)
      }
  }
  
  useEffect(()=>{
    _getUserStats()
  },[])

  return (
    <React.Fragment>
     {loading && <Loader />}
     <div className={styles.container}>
        <main>
            <h2 className={styles.heading}>Points</h2>
            
            <div className={styles.content}>
                <div className={styles.header}>
                <div className={styles.headerImage}>
                    <FaUserCircle size={100} color="#fff" />
                </div>
                <div>
                    <h2>{name}</h2>
                    <h3>Total Points: <span>{isNaN(stats?.likes + stats?.dislikes + stats?.pollsCreated + stats?.postsCreated + stats?.comments + stats?.kudosReceived + stats?.kudosGiven) ? 0 : stats?.likes + stats?.dislikes + stats?.pollsCreated + stats?.postsCreated + stats?.comments + stats?.kudosReceived + stats?.kudosGiven}</span></h3>
                </div>
                </div>

                <div className={styles.cards}>
                    <div className={styles.card}>
                        <h2>{stats?.likes || 0}</h2>
                        <div>
                            <BsFillArrowUpCircleFill color="#2FA163" size={20} />
                            <h3>Likes</h3>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <h2>{stats?.dislikes || 0}</h2>
                        <div>
                            <BsFillArrowDownCircleFill color="#F93333" size={20} />
                            <h3>Dislikes</h3>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <h2>{stats?.pollsCreated || 0}</h2>
                        <div>
                            <RiBarChartFill color="#40C0E7" size={20} />
                            <h3>Polls Created</h3>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <h2>0</h2>
                        <div>
                            <RiBarChartFill color="#518EF8" size={20} />
                            <h3>Polls Participated</h3>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <h2>{stats?.kudosGiven || 0}</h2>
                        <div>
                            <HiClipboardList color="#00DC64" size={20} />
                            <h3>Kudos Given</h3>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <h2>{stats?.kudosReceived || 0}</h2>
                        <div>
                            <HiClipboardList color="#00DC64" size={20} />
                            <h3>Kudos Received</h3>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <h2>{stats?.postsCreated || 0}</h2>
                        <div>
                            <FaBars color="#F43030" size={20} />
                            <h3>Posts Created</h3>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <h2>{stats?.comments || 0}</h2>
                        <div>
                            <FaComment color="#FFC700" size={20} />
                            <h3>Comments</h3>
                        </div>
                    </div>
                </div>

                <div className={styles.activity}>
                    <h3>Activity</h3>           
                </div>
            </div>
        </main>
    </div>
    </React.Fragment>
  )
}
