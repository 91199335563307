import React from "react";
import styles from "./Trends.module.css";
import Dropdown from "react-dropdown";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CardContainer from "../../../Components/CardContainer";

export default function Trends() {
  const positiveData = [
    {
        name: '',
        value: 0,
        color:'#2BA700'
    },
    {
        name: '',
        value: 0,
        color:'#3D8F20'
    },
    {
        name: '',
        value: 0,
        color:'#26C777'
    },
    {
        name: '',
        value: 0,
        color:'#00E031'
    },
    {
        name: '',
        value: 0,
        color:'#6EBE07'
    },
    {
        name: '',
        value: 0,
        color:'#78C947'
    },
    {
        name: '',
        value: 0,
        color:'#12B466'
    },
  ]

  const negativeData = [
    {
        name: '',
        value: 0,
        color:'#D95050'
    },
    {
        name: '',
        value: 0,
        color:'#FF9599'
    },
    {
        name: '',
        value: 0,
        color:'#F0525C'
    },
    {
        name: '',
        value: 0,
        color:'#FF3F61'
    },
    {
        name: '',
        value: 0,
        color:'#E07A7A'
    },
    {
        name: '',
        value: 0,
        color:'#E01111'
    },
  ]

  return (
    <CardContainer>
      <div style={{ width: 130, marginLeft: "auto" }}>
        <Dropdown
          placeholder="2023"
          className={styles.dropdownContainer}
          placeholderClassName={styles.dropdownPlaceholder}
          arrowClosed={
            <span>
              <HiOutlineChevronDown color="#2a65bf" size={16} />
            </span>
          }
          arrowOpen={
            <span>
              <HiOutlineChevronUp color="#2a65bf" size={16} />
            </span>
          }
          arrowClassName={styles.dropdownArrow}
          controlClassName={styles.dropdown}
          menuClassName={styles.dropdownMenu}
          options={[]}
          // onChange={(e)=>setSelectedCriterias([...selectedCriterias,e.value])}
        />
      </div>

      <div className={styles.barChart}>
        <div className={styles.yLabels}>
          <div className={styles.yLabel} style={{bottom:-10}}>
            <p>0</p>
            <div />
         </div>
         <div className={styles.yLabel} style={{top:'73%'}}>
            <p>25</p>
            <div />
         </div>
         <div className={styles.yLabel} style={{top:'50%'}}>
            <p>50</p>
            <div />
         </div>
         <div className={styles.yLabel} style={{top:'25%'}}>
            <p>75</p>
            <div />
         </div>
         <div className={styles.yLabel} style={{top:0}}>
            <p>100</p>
            <div />
         </div>
        </div>

        {positiveData.map(i => {
            return <div className={styles.bar} style={{height:`${i.value}%`,backgroundColor:i.color}}>
                <div className={styles.barTitle}>{i.name}</div>
            </div>
        })}
        {negativeData.map(i => {
            return <div className={styles.bar} style={{height:`${i.value}%`,backgroundColor:i.color}}>
                <div className={styles.barTitle}>{i.name}</div>
            </div>
        })}
      </div>

      <div className={styles.performance}>
        <div>
          <p>
            Your organisation is efficient in{" "}
            <span style={{ color: "#12B466" }}>Performance</span>
          </p>
          <div className={styles.progress}>
            <CircularProgressbar
              styles={buildStyles({
                pathColor: "#12B466",
                trailColor: "#94ddba",
                textSize: 26,
                textColor: "#12B466",
              })}
              strokeWidth={12}
              value={0}
              text={`${parseInt(0)}%`}
            />
          </div>
        </div>
        <div>
          <p>
            <span style={{ color: "#E07A7A" }}>Overworking</span> has high
            levels in your organisation
          </p>
          <div className={styles.progress}>
            <CircularProgressbar
              styles={buildStyles({
                pathColor: "#E07A7A",
                trailColor: "#f1c3c3",
                textSize: 26,
                textColor: "#E07A7A",
              })}
              strokeWidth={12}
              value={0}
              text={`${parseInt(0)}%`}
            />
          </div>
        </div>
      </div>
    </CardContainer>
  );
}
