import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import CardContainer from "../CardContainer";
import { Avatar } from "antd";

export default function UsersList({
  names,
  setSelectedUser,
  setName,
  setNames,
}) {
  return (
    <CardContainer
      padding={0}
      position="absolute"
      zIndex={2}
      top="44px"
      width="100%"
    >
      <List>
        {names.map((n) => {
          return (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedUser({
                    id: n.id,
                    name: n.first_name + " " + n.last_name,
                  });
                  setNames([]);
                  setName("");
                }}
              >
                <ListItemIcon sx={{ minWidth: "fit-content", mr: 1.2 }}>
                  <Avatar src={n.imagePath} />
                </ListItemIcon>
                <ListItemText primary={n.first_name + " " + n.last_name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </CardContainer>
  );
}
