import axios from "axios";
import React, { useContext, useState } from "react";
import { MdOutlineAccessTime } from "react-icons/md";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";
import avatar2 from "../../../Assets/avatar2.png";
import anonymousUser from "../../../Assets/anonymous-user.png";
import { Menu, Stack, Typography, useMediaQuery } from "@mui/material";
import { currentUser } from "../../../utils/utils";
import moment from "moment";
import { Avatar, Modal } from "antd";
import { colors } from "../../../utils/constants";
import PostStats from "../../../Components/PostStats";
import { BiDislike, BiLike } from "react-icons/bi";
import repostIcon from "../../../Assets/repostIcon.png";
import keepTrackIcon from "../../../Assets/keepTrackIcon.png";
import repostBlue from "../../../Assets/repostBlue.png";
import commentIcon from "../../../Assets/commentIcon.png";
import keepTrackIconActive from "../../../Assets/keepTrackIcon_active.png";
import CreatePost from "./CreatePost";
import EditDeleteMenu from "./EditDeleteMenu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useParams } from "react-router-dom";
import PostComments from "./PostComments";
import CardMedia from "./CardMedia";

const Card = (props) => {
  const {
    item,
    editable,
    setLoading,
    fetch,
    setReportId,
    hideStats,
    hideFooter,
    updateObjectKey,
  } = props;

  const { id } = useParams();
  const [reportDropdown, setReportDropdown] = useState("");
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const { userDetails } = useContext(UserDetailsContext);
  const [selectedPostEdit, setSelectedPostEdit] = useState("");
  const [creating, setCreating] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [selectedPost, setSelectedPost] = useState(id || "");
  const [menuVisible, setMenuVisible] = useState(null);
  const [repostModalVisible, setRepostModalVisible] = useState(false);
  const [repostItem, setRepostItem] = useState({});
  
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const isLiked = item?.likes?.find((i) => i.userId === userDetails.id);
  const isDisliked = item?.dislikes?.find((i) => i.userId === userDetails.id);
  const isTracked = item?.tracks?.find((i) => i.userId === userDetails.id);

  const _deletePost = async () => {
    setLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.delete(
      process.env.REACT_APP_URL + "deletePost/" + selectedPost?.id,
      options
    );
    if (res.status == 200) {
      setSelectedPost({});
      setDeleteVisible(false);
      fetch();
    }
  };

  const _deletePoll = async () => {
    setLoading(true);
    const headerOptions = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.delete(
      process.env.REACT_APP_URL + "deletePoll/" + item?.id,
      headerOptions
    );
    if (res.status == 200) {
      setSelectedPost({});
      setDeleteVisible(false);
      fetch();
    }
  };

  const _editPost = async () => {
    setCreating(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const data = {
      postId: selectedPostEdit?.id,
      postTitle: selectedPostEdit?.post_title.replace(
        /\@\[(\w+)\]\([\w-]+\)/g,
        `<span style="color:#2262C6">$1</span>`
      ),
      postDesc: selectedPostEdit?.post_desc.replace(
        /\@\[(\w+)\]\([\w-]+\)/g,
        `<span style="color:#2262C6">$1</span>`
      ),
      image: selectedPostEdit?.imagePath,
    };
    let res = await axios.put(
      process.env.REACT_APP_URL + "editPost",
      data,
      options
    );
    if (res.status == 200) {
      setSelectedPostEdit("");
      fetch();
      setEditVisible(false);
      setCreating(false);
    }
  };

  const uploadToCloudEdit = async (imagee) => {
    let fileSize = (imagee.size / 1048576).toFixed(2);
    fileSize = parseFloat(fileSize);

    if (fileSize > 5) {
      return;
    }
    let type = selectedPostEdit?.imagePath?.type;
    const data = new FormData();
    data.append("file", imagee);
    data.append("upload_preset", "vatg9qsy");
    data.append("api_key", "755813337218451");
    data.append("cloud_name", "culture");
    let res = await axios.post(
      `https://api.cloudinary.com/v1_1/vatg9qsy/${type}/upload`,
      data
    );
    if (res.status == 200) {
      setSelectedPostEdit({
        ...selectedPostEdit,
        imagePath: {
          mediaType:
            type == "image" ? "Image" : type == "video" ? "Video" : "File",
          name: res.data.original_filename,
          url: res.data.secure_url,
          size: res.data.bytes,
          format: res.data.format,
          type: res.data.resource_type,
        },
      });
    }
  };

  const _likePost = async (e) => {
    e.stopPropagation();
    if (!isLiked) {
      updateObjectKey(item.id, "likes", [
        ...item.likes,
        { userId: userDetails?.id },
      ]);
    } else {
      updateObjectKey(
        item.id,
        "likes",
        item.likes.filter((i) => i.userId !== userDetails.id)
      );
    }
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      postId: item?.id,
      notificationFor: {
        name: item?.createdby?.name,
        id: item?.createdby?.id,
      },
      item,
      notificationBy: {
        name: userDetails?.username,
        id: userDetails?.id,
      },
    };
    await axios.put(process.env.REACT_APP_URL + "likePost", data, options);
  };

  const _dislikePost = async (e) => {
    e.stopPropagation();
    if (!isDisliked) {
      updateObjectKey(item.id, "dislikes", [
        ...item.dislikes,
        { userId: userDetails?.id },
      ]);
    } else {
      updateObjectKey(
        item.id,
        "dislikes",
        item.dislikes.filter((i) => i.userId !== userDetails.id)
      );
    }
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      postId: item?.id,
      notificationFor: {
        name: item?.createdby?.name,
        id: item?.createdby?.id,
      },
      item,
      notificationBy: {
        name: userDetails?.username,
        id: userDetails?.id,
      },
    };
    await axios.put(process.env.REACT_APP_URL + "dislikePost", data, options);
  };

  const _trackPost = async (e) => {
    e.stopPropagation();
    if (!isTracked) {
      updateObjectKey(item.id, "tracks", [
        ...item.tracks,
        { userId: userDetails?.id },
      ]);
    } else {
      updateObjectKey(
        item.id,
        "tracks",
        item.tracks.filter((i) => i.userId !== userDetails.id)
      );
    }
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let res = await axios.get(
      process.env.REACT_APP_URL + "post/track/" + item.id,
      options
    );
    if (res.status === 200) {
      fetch();
    }
  };

  return (
    <>
      <Modal
        centered
        width="50%"
        closeIcon={null}
        onOk={_editPost}
        okText="Edit"
        open={editVisible}
        okButtonProps={{ loading: creating }}
        onCancel={() => setEditVisible(false)}
      >
        <Stack gap={2}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontSize={16} fontWeight={500}>
              Edit Post
            </Typography>
          </Stack>

          <CreatePost
            setMentionedUsers={setMentionedUsers}
            selectedPostEdit={selectedPostEdit}
            setSelectedPostEdit={setSelectedPostEdit}
            editable
          />
        </Stack>
      </Modal>

      <Modal
        onOk={
          item?.post_title ? _deletePost : item.poll_title ? _deletePoll : null
        }
        open={deleteVisible}
        onCancel={() => {
          setDeleteVisible(false);
        }}
        okType="danger"
      >
        <Stack gap={1}>
          <Typography fontSize={26} fontWeight={600} color={colors.tableBlack}>
            Delete {item?.post_title ? "Post" : item?.poll_title ? "Poll" : ""}?
          </Typography>
          <Typography fontSize={18} fontWeight={400} color={colors.tableBlack}>
            Are you sure you want to delete this{" "}
            {item?.post_title ? "Post" : item?.poll_title ? "Poll" : ""}?
          </Typography>
        </Stack>
      </Modal>

      <Modal
        style={{ maxWidth: "70vw" }}
        centered
        width="fit-content"
        closeIcon={null}
        footer={null}
        open={repostModalVisible}
        onCancel={() => {
          setRepostModalVisible(false);
          setRepostItem({});
        }}
      >
        <CreatePost
          setMentionedUsers={setMentionedUsers}
          fetch={fetch}
          repostItem={repostItem}
          setRepostItem={setRepostItem}
          setRepostModalVisible={setRepostModalVisible}
        />
      </Modal>

      <Stack mb={2} flexDirection="row" alignItems="center" gap={1.5}>
        {item?.anonymous ? (
          <Avatar size="large" src={anonymousUser} />
        ) : item?.createdby?.img ? (
          <Avatar size="large" src={item?.createdby?.img} />
        ) : item?.created_by?.img ? (
          <Avatar size="large" src={item?.created_by?.img} />
        ) : (
          <Avatar size="large" src={avatar2} />
        )}

        <Typography fontSize={14} fontWeight={500}>
          {item?.anonymous
            ? item?.createdby?.name ||
              item?.comment_by?.name ||
              item?.created_by?.name
            : !item?.anonymous
            ? item?.createdby
              ? `${item?.createdby?.firstName} ${item?.createdby?.lastName}`
              : item?.comment_by
              ? `${item?.comment_by?.firstName} ${item?.comment_by?.lastName}`
              : item?.created_by
              ? `${item?.created_by?.firstName} ${item?.created_by?.lastName}`
              : ""
            : ""}
        </Typography>

        <Stack flexDirection="row" alignItems="center" gap={1} ml="auto">
          <Stack gap={0.6} flexDirection="row" alignItems="center">
            <MdOutlineAccessTime color={colors.lightGrey} size={16} />
            <Typography fontSize={12} fontWeight={400} color={colors.lightGrey}>
              {moment(item?.createdAt).fromNow()}
            </Typography>
          </Stack>

          {editable && (
            <BsThreeDotsVertical
              color={colors.tableBlack}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPost(item);
                setMenuVisible(e.currentTarget);
              }}
              style={{ cursor: "pointer" }}
              size={18}
            />
          )}

          <Menu
            anchorEl={menuVisible}
            open={Boolean(menuVisible)}
            onClose={() => setMenuVisible(null)}
          >
            <EditDeleteMenu
              item={item}
              currentUser={currentUser(
                userDetails?.id,
                item?.createdby?.id || item?.created_by?.id
              )}
              setSelectedPostEdit={setSelectedPostEdit}
              setEditVisible={setEditVisible}
              setReportId={setReportId}
              setReportDropdown={setReportDropdown}
              setDeleteVisible={setDeleteVisible}
              setMenuVisible={setMenuVisible}
            />
          </Menu>
        </Stack>
      </Stack>

      <Stack>
        {item?.post_title || item?.poll_title ? (
          <Typography
            mb={0.5}
            fontWeight={500}
            dangerouslySetInnerHTML={{
              __html: item?.post_title || item?.poll_title || "N/A",
            }}
          />
        ) : null}

        {item?.repost?.post_title && (
          <Stack
            border="1px solid"
            borderColor={colors.borderGrey}
            borderRadius={2}
            padding={2}
            mt={1}
          >
            <Card hideFooter hideStats item={item?.repost} />
          </Stack>
        )}

        {!item?.repost?.post_title && (
          <Stack>
            <Typography
              fontSize={14}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html:
                  item?.post_desc || item?.poll_desc || item?.comment_desc,
              }}
            />

            <CardMedia item={item} />
          </Stack>
        )}

        {item?.post_title && !hideStats && <PostStats item={item} />}

        {item?.post_title && !hideFooter && (
          <Stack
            pt={1}
            sx={{
              "& .MuiTypography-root": {
                fontSize: 13,
                fontWeight: 500,
                display: isSmallScreen ? "none" : "block",
              },
              "& .MuiStack-root": {
                flexDirection: "row",
                alignItems: "center",
                gap: 0.8,
                cursor: "pointer",
              },
            }}
            flexDirection="row"
            alignItems="center"
            gap={2}
            mt={1.5}
            borderTop="1px solid"
            borderColor={colors.borderGrey}
          >
            <Stack onClick={_likePost}>
              <BiLike
                size={18}
                color={isLiked ? colors.primaryThemeColor : colors.tableBlack}
              />
              <Typography
                color={isLiked ? colors.primaryThemeColor : colors.tableBlack}
              >
                {isLiked ? "Liked" : "Like"}
              </Typography>
            </Stack>

            <Stack onClick={_dislikePost}>
              <BiDislike
                size={18}
                color={
                  isDisliked ? colors.primaryThemeColor : colors.tableBlack
                }
              />
              <Typography
                color={
                  isDisliked ? colors.primaryThemeColor : colors.tableBlack
                }
              >
                {isDisliked ? "Disiked" : "Dislike"}
              </Typography>
            </Stack>

            <Stack
              onClick={(e) => {
                e.stopPropagation();
                if (selectedPost !== item?.id) {
                  setSelectedPost(item?.id);
                } else {
                  setSelectedPost("");
                }
              }}
            >
              <img
                style={{ height: 14, width: 14, objectFit: "contain" }}
                src={commentIcon}
                alt="icon"
              />
              <Typography>Comment</Typography>
            </Stack>

            <Stack onClick={_trackPost}>
              <img
                style={{ height: 18, width: 18, objectFit: "contain" }}
                src={isTracked ? keepTrackIconActive : keepTrackIcon}
                alt="icon"
              />

              <Typography
                color={isTracked ? colors.primaryThemeColor : colors.tableBlack}
              >
                {isTracked ? "Keeping Track" : "Keep Track"}
              </Typography>
            </Stack>

            <Stack
              onClick={(e) => {
                e.stopPropagation();
                setRepostItem(item);
                setRepostModalVisible(true);
              }}
            >
              {item?.reposted == null || !item?.reposted ? (
                <img
                  style={{ height: 18, width: 18, objectFit: "contain" }}
                  src={repostIcon}
                  alt="icon"
                />
              ) : (
                <img
                  style={{ height: 14, width: 14, objectFit: "contain" }}
                  src={repostBlue}
                  alt="icon"
                />
              )}
              <Typography color={item?.reposted && "#2262C6"}>
                {item?.reposted == null || !item?.reposted
                  ? "Repost"
                  : "Reposted"}
              </Typography>
            </Stack>

            {item?.edited && (
              <Typography
                fontWeight={400}
                color={colors.lightGrey}
                fontStyle="italic"
                ml="auto"
              >
                edited
              </Typography>
            )}
          </Stack>
        )}
      </Stack>

      {selectedPost == item?.id && (
        <PostComments
          item={item}
          mentionedUsers={mentionedUsers}
          setMentionedUsers={setMentionedUsers}
          selectedPost={selectedPost}
        />
      )}
    </>
  );
};

export default Card;
