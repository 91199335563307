import { Grid, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { colors } from "../../utils/constants";
import bg from "../../Assets/bg.svg";

export default function LoginCard({ children }) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <Grid
      container
      sx={{ display: "flex", alignItems: "center" }}
      minHeight="100vh"
    >
      <Grid xs={12} sm={8} md={6} lg={5}>
        <Stack
          width={isSmallScreen ? "90%" : "unset"}
          marginLeft={!isSmallScreen ? "10vh" : "auto"}
          marginRight={isSmallScreen ? "auto" : 0}
          py={6}
          px={isSmallScreen ? 5 : 10}
          borderRadius={4}
          gap={3}
          bgcolor={colors.WHITE}
          flex={1}
        >
          {children}
        </Stack>
      </Grid>

      <img
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: -1,
          overflowY: "hidden",
          objectFit: "cover",
        }}
        alt=""
        src={bg}
      />
    </Grid>
  );
}
