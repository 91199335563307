import React, { Fragment, useEffect, useState } from "react";
import styles from "./Styles/Survey.module.css";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import axios from "axios";
import Countdown from "react-countdown";
import moment from "moment";
import Loader from "../../Components/Loader";

export default function Survey() {

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Completed");
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  const _getAllSurveys = async () => {
    setLoading(true)
    const options = {
      headers: {
          'Content-type': 'application/json',
          "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
    };
    let res = await axios.get(process.env.REACT_APP_URL + 'getAllSurveys' ,options)
    if(res.status == 200){
        setItems(res.data)
        setLoading(false)
    }
  }

  useEffect(()=>{
    _getAllSurveys()
  },[])

  const renderer = (props,index) => {
    if (props.completed) {
      return null
    } else {
      return <h3>
          Ending in: <span>{props.days}D {props.hours}h</span>
      </h3>
    }
  };

  return (
    <Fragment>
      {loading && <Loader />}
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Survey</h1>
        <button onClick={() => navigate('create')}>
          <FiPlus color="#2262C6" size={22} />
          Create Survey
        </button>
      </div>

      <div className={styles.childContainer}>
      <div>
      {/* <div className={styles.tabs}>
        <div
          className={activeTab == "Completed" && styles.activeTab}
          onClick={() => setActiveTab("Completed")}
        >
          Past
        </div>
        <div
          className={activeTab == "Ongoing" && styles.activeTab}
          onClick={() => setActiveTab("Ongoing")}
        >
          Ongoing
        </div>
        <div
          className={activeTab == "Upcoming" && styles.activeTab}
          onClick={() => setActiveTab("Upcoming")}
        >
          Upcoming
        </div>
      </div> */}

      <ul>
        {items.length !== 0 && items.map((item, index) => {
          return (
            <li 
              // onClick={() => navigate("details", { state: { item } })}
            >
              <h2>
                {index + 1}. {item.title}
              </h2>
              <div style={{ display: "flex", alignItems: "center", gap: 18 }}>
                <p>Ending on <span style={{fontWeight:600}}>{moment(item?.endDate).format('ll LT')}</span></p>
                {/* <Countdown renderer={renderer} date={Date.now() + (moment(item?.endDate).diff(moment().format(),'seconds'))} /> */}
                {/* <button>{item.status == null ? 'N/A' : item.status}</button> */}
              </div>
            </li>
          );
        })}
      </ul>

      <div className={styles.stats}>
        <h4>Overall Surveys stats</h4>

        <div className={styles.statsCards}>
          <div className={styles.statsCard}>
            <h2 style={{ color: "#2262C6" }}>0</h2>
            <div></div>
            <h3>Viewed</h3>
          </div>
          <div className={styles.statsCard}>
            <h2 style={{ color: "#6A6A6A" }}>0</h2>
            <div></div>
            <h3>Total responses</h3>
          </div>
          <div className={styles.statsCard}>
            <h2 style={{ color: "#009962" }}>0</h2>
            <div></div>
            <h3>Completed</h3>
          </div>
          <div className={styles.statsCard}>
            <h2 style={{ color: "#009962" }}>0</h2>
            <div></div>
            <h3>Completion rate</h3>
          </div>
          <div className={styles.statsCard}>
            <h2 style={{ color: "#B71818" }}>0</h2>
            <div></div>
            <h3>Dropouts</h3>
          </div>
          <div className={styles.statsCard}>
            <h2 style={{ color: "#6A6A6A" }}>0 mins</h2>
            <div></div>
            <h3>Average view time</h3>
          </div>
        </div>
      </div>
      </div>

      <aside>
      <h2>Overall Survey <br /> Response</h2>
      <div className={styles.pieChart}>
          <CircularProgressbar
            styles={buildStyles({
              pathColor: "#2262C6",
              trailColor: "#90b0e3",
            })}
            counterClockwise={true}
            strokeWidth={22}
            value={0}
          />
      </div>
      </aside>
      </div>

      
    </div>
    </Fragment>
  );
}
