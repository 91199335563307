import { Popover, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { CountryCodesList } from './CountryCodesList';
import Search from '../../../Components/Search';

export default function CountryCode({ countryCode, setCountryCode }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [search, setSearch] = useState('');

	const handleClose = () => {
		setAnchorEl(null);
		setSearch('');
	};

	return (
		<Stack>
			<Stack
				sx={{ cursor: 'pointer' }}
				onClick={(e) => setAnchorEl(e.currentTarget)}
				direction="row"
				alignItems="center"
				gap={0.5}
			>
				<FaCaretDown size={14} color="#fff" />
				<Typography color="#fff">{countryCode}</Typography>
			</Stack>

			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Stack p={1} gap={0.5}>
					<Search value={search} onChange={(e) => setSearch(e.target.value.toLowerCase())} placeholder="Search Country" />
					<Stack width={380} height={400} overflow="auto">
						{CountryCodesList.map(
							(i) =>
								(search === '' || i.name.toLowerCase().includes(search) || i.dial_code.includes(search)) && (
									<Stack
										onClick={() => {
											setCountryCode(i.dial_code);
											handleClose();
										}}
										borderRadius={1}
										sx={{ cursor: 'pointer', ':hover': { bgcolor: '#f5f5f5' } }}
										direction="row"
										alignItems="center"
										height={32}
										flexShrink={0}
										px={1.5}
										gap={1}
									>
										<Typography fontSize={14} fontWeight={400}>
											{i.name}
										</Typography>
										<Typography fontSize={14} fontWeight={500}>
											{i.dial_code}
										</Typography>
									</Stack>
								),
						)}
					</Stack>
				</Stack>
			</Popover>
		</Stack>
	);
}
