import { Stack } from "@mui/material";
import React from "react";
import { colors } from "../utils/constants";

export default function CardContainer(props) {
  const { children, padding = 2, hideShadow } = props;

  return (
    <Stack
      boxShadow={!hideShadow ? "rgba(0, 0, 0, 0.04) 0px 3px 5px" : "none"}
      sx={{ padding: padding }}
      bgcolor={colors.WHITE}
      borderRadius={2}
      mb={2}
      {...props}
    >
      {children}
    </Stack>
  );
}
