import { createTheme } from "@mui/material";
import { colors } from "../utils/constants";

export const antdTheme = {
  token: {
    // Seed Token
    colorPrimary: colors.primaryThemeColor,

    // Alias Token
    colorBgContainer: colors.WHITE,
  },
};

export const appTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.primaryThemeColor,
      light: colors.primaryThemeColor,
      dark: colors.primaryThemeColorDark,
    },
    secondary: {
      main: "#f50057",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});
