import React from "react";
import karmaIcon from "../../Assets/karmaIcon_active.png";
import CardContainer from "../CardContainer";
import { Avatar, Stack, Typography } from "@mui/material";
import { MdOutlineAccessTime } from "react-icons/md";
import moment from "moment";
import { colors } from "../../utils/constants";
import { Image } from "antd";
import Tag from "./Tag";

export default function KarmaGivenReceivedCard({ item, given, received }) {
  return (
    <CardContainer>
      <Stack mb={2} flexDirection="row" alignItems="center" gap={1.5}>
        <Avatar
          src={item?.sender?.imagePath || item?.receiver?.imagePath}
          size="large"
        />
        <Typography fontSize={14} fontWeight={500}>
          {given
            ? `${item?.sender?.first_name} ${item?.sender?.last_name}`
            : received
            ? `${item?.receiver?.first_name} ${item?.receiver?.last_name}`
            : "N/A"}
        </Typography>

        <Stack ml="auto" gap={0.6} flexDirection="row" alignItems="center">
          <MdOutlineAccessTime color={colors.lightGrey} size={16} />
          <Typography fontSize={12} fontWeight={400} color={colors.lightGrey}>
            {moment(item?.createdAt).fromNow()}
          </Typography>
        </Stack>
      </Stack>

      <Typography fontSize={14} fontWeight={400}>
        {item?.kudos_comment}
      </Typography>

      <Stack
        mt={2}
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" gap={1} flexWrap="wrap">
          {item.kudos_traits.map((trait) => {
            return <Tag trait={trait} />;
          })}
        </Stack>

        <Stack flexShrink={0} flexDirection="row" alignItems="center" gap={1}>
          <Typography fontSize={14} fontWeight={400}>
            Total Karma:{" "}
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap={0.5}>
            <Image style={{ objectFit: "cover", height: 20 }} src={karmaIcon} />
            <Typography
              fontSize={14}
              fontWeight={600}
              color={colors.primaryThemeColor}
            >
              {item.kudos_points}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </CardContainer>
  );
}
