import axios from "axios";

const options = {
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
};

export const getUserDetails = async (id) => {
  const options = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  let res = await axios.get(
    process.env.REACT_APP_URL + "getUserById/" + id,
    options
  );
  return res;
};

export const getPostComments = async (id) => {
  let res = await axios.get(
    process.env.REACT_APP_URL + "getpostcomment/" + id,
    options
  );
  if (res.status == 200) {
    return res.data;
  }
};

export const getReplies = async (id) => {
  let res = await axios.get(
    process.env.REACT_APP_URL + "getcommentreply/" + id,
    options
  );
  if (res.status == 200) {
    return res.data;
  }
};

export const getAllUsers = async () => {
  const options = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  let res = await axios.get(
    process.env.REACT_APP_URL + "getAllUsers",
    options
  );
  return res
};
