import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "./Styles/SurveyDetails.module.css"

export default function SurveyDetails() {

  const location = useLocation()
  const navigate = useNavigate()
  const [options, setOptions] = useState(['A. Yes, it was great  working with us.','C. No,it was not good.','B. Can’t say what I felt.','D. Skip this question.'])
  const [questions, setQuestions] = useState([])
  const [modal, setModal] = useState(false)
  const [activeTab, setActiveTab] = useState('Questions')

  const [response, setResponse] = useState([])

  const [startDate, setStartDate] = useState(location?.state?.item?.startDate)
  
  const _getSurveyQuestions = async () => {
    const options = {
      headers: {
          'Content-type': 'application/json',
          "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
    };
    let res = await axios.get(process.env.REACT_APP_URL + 'getAllSurveyQuestion/' + location.state.id ,options)
    if(res.status == 200){
        setQuestions(res.data[0].question_desc);
        // let arr = []
        // res.data[0].question_desc.map(q => {
        //     let m = {
        //         question: q,
        //         answer: ''
        //     }
        // arr.push(m)
        // setResponse(arr)
        // })
    }
  }

  const _submitResponse = () => {
    const options = {
        headers: {
            'Content-type': 'application/json',
            "Authorization": 'Bearer ' + localStorage.getItem('token')
        }
    };

    let data = {
        surveyId: location.state.id,
        response: {

        }
    }
    //   let res = await axios.get(process.env.REACT_APP_URL + 'getAllSurveyQuestion/' + location.state.id ,options)
    //   if(res.status == 200){
    //       setQuestions(res.data[0].question_desc);
    //   }
  }

  useEffect(()=>{
    // _getSurveyQuestions()
  },[])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
  return (
    // <div className={styles.container}>
    //     {modal && <Modal width={500} padding="60px 0px">
    //         <div className={styles.modal}>
    //             <div className={styles.tick}>
    //                 <BsCheckLg size={130} color="#2262C6" />
    //             </div>
    //             <p>Successfully submitted</p>
    //             <button onClick={()=>{
    //                 setModal(false)
    //                 navigate('/dashboard')
    //             }}>Back to Home</button>
    //         </div>    
    //     </Modal>}

    //     <main>
    //     <div onClick={()=>navigate(-1)} className={styles.header}>
    //         <FiArrowLeft  color="#2262C6" size={30} />
    //         <h2>{location.state.title}</h2>
    //     </div>

    //     <div className={styles.tabs}>
    //         <div className={activeTab == 'Statistics' && styles.activeTab} onClick={()=>setActiveTab('Statistics')}>Statistics</div>
    //         <div className={activeTab == 'Questions' && styles.activeTab} onClick={()=>setActiveTab('Questions')}>Questions</div>
    //     </div>


    //     {activeTab == 'Statistics' && <div className={styles.statistics}>
    //         <div>
    //         <div style={{display:'flex',alignItems:'center',marginBottom:20}}>
    //             <h2>Start Date</h2>
    //             <h3>{moment(startDate).format('DD MMM YYYY')}</h3>
    //         </div>
    //         <div style={{display:'flex',alignItems:'center',marginBottom:20}}>
    //             <h2>Time</h2>
    //             <h3>{moment(startDate).format('hh:mma')}</h3>
    //         </div>
    //         </div>

    //     <div className={styles.stats}>
    //      <h4>Overall Surveys stats</h4>

    //      <div className={styles.statsCards}>
    //         <div className={styles.statsCard}>
    //             <h2 style={{color:'#2262C6'}}>0</h2>
    //             <div></div>
    //             <h3>Viewed</h3>
    //         </div>
    //         <div className={styles.statsCard}>
    //             <h2 style={{color:'#6A6A6A'}}>0</h2>
    //             <div></div>
    //             <h3>Total responses</h3>
    //         </div>
    //         <div className={styles.statsCard}>
    //             <h2 style={{color:'#009962'}}>0</h2>
    //             <div></div>
    //             <h3>Completed</h3>
    //         </div>
    //         <div className={styles.statsCard}>
    //             <h2 style={{color:'#009962'}}>0</h2>
    //             <div></div>
    //             <h3>Completion rate</h3>
    //         </div>
    //         <div className={styles.statsCard}>
    //             <h2 style={{color:'#B71818'}}>0</h2>
    //             <div></div>
    //             <h3>Dropouts</h3>
    //         </div>
    //         <div className={styles.statsCard}>
    //             <h2 style={{color:'#6A6A6A'}}>0 mins</h2>
    //             <div></div>
    //             <h3>Average view time</h3>
    //         </div>

    //      </div>
    //     </div>

    //     <div className={styles.pieChart}>
    //         <div>
    //          <CircularProgressbar styles={buildStyles({
    //                 pathColor: '#2262C6',
    //                 trailColor: '#90b0e3',
    //          })} counterClockwise={true} strokeWidth={22} value={0} />
    //          <h4>Survey Response</h4>
    //         </div>
    //     </div>
    //     </div>}

    //     {activeTab == 'Questions' && <div className={styles.questions}>
    //         {questions.map((ques,index) => {
    //         return <div className={styles.question}>
    //         <h3>{index + 1}. {ques}</h3>

    //         <textarea onChange={(e)=>setResponse([...response,{
    //             question: ques,
    //             answer: e.target.value
    //         }])} placeholder="Please enter your answer..." rows={3}></textarea>
            
    //         {/* <div className={styles.options}>
    //            {options.map(item => {
    //                return <div>
    //                <input name={ques} type="radio" />
    //                <p>{item}</p>
    //                </div>
    //            })}
    //        </div> */}
    //        </div>
    //     })}

    //     <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
    //         <div></div>
    //         <button onClick={()=>setModal(true)} className={styles.submitBtn}>Submit</button>
    //     </div>
    //     </div>}

    //     </main>
    // </div>

    <div className={styles.container}>
        <div  className={styles.header}>
            <FiChevronLeft onClick={()=>navigate(-1)} style={{cursor:'pointer',marginTop:4}} size={33} color="#2262C6" />
             <h2>{location.state.item.title}</h2>
         </div>
    </div>
  )
}
