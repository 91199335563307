import { InputLabel, Stack } from "@mui/material";
import React from "react";
import { colors } from "../utils/constants";

export default function LabelledInput({ children, label, required }) {
  return (
    <Stack
      sx={{
        "& .MuiInputLabel-root": {
          fontSize: 14,
          fontWeight: 400,
          color: colors.tableBlack,
          mb: label ? 0.5 : 0,
        },
        "& .MuiTypography-root": {
          fontSize: 14,
          fontWeight: 400,
          color: "#0E1123",
        },
        "& .MuiInputBase-root": {
          backgroundColor: colors.WHITE,
        },
      }}
      flex={1}
    >
      <InputLabel>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </InputLabel>
      {children}
    </Stack>
  );
}
