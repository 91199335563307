import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { colors } from "../../utils/constants";
import axios from "axios";
import { UserDetailsContext } from "../../Context/UserDetailsContext";

export default function OngoingPoll({ item, fetch, polls, setPolls }) {
  const { userDetails } = useContext(UserDetailsContext);

  const updateObjectKey = (objectId, keyToUpdate, newValue) => {
    setPolls((prevArray) => {
      return prevArray.map((obj) => {
        if (obj.id === objectId) {
          return {
            ...obj,
            [keyToUpdate]: newValue,
          };
        }
        return obj;
      });
    });
  };

  const _handleVote = async (item, opt) => {    
    const headerOptions = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      pollId: item.id,
      selectedOption: opt,
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "poll/givevote",
      data,
      headerOptions
    );
    if (res.status == 200) {
      fetch();
    }
  };
  return (
    <Stack my={2} gap={1.5}>
      {item?.options !== null && item?.options?.length !== 0
        ? item?.options?.map((opt, index) => {
            let totalVotes =
              item.options?.length !== 0 &&
              item.options.reduce((a, b) => {
                return a + b.votes.length;
              }, 0);
            return (
              <Stack
                bgcolor="#f9f9f9"
                key={index}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  _handleVote(item, opt);
                }}
                position="relative"
                height="40px"
                borderRadius={2}
                alignItems="center"
                flexDirection="row"
              >
                <Typography
                  color={colors.tableBlack}
                  fontSize={14}
                  fontWeight={500}
                  zIndex={2}
                  ml={2}
                >
                  {opt.option}
                </Typography>
                <Stack
                  position="absolute"
                  width={`${(opt.votes?.length / totalVotes) * 100}%`}
                  borderRadius={2}
                  height="100%"
                  backgroundColor="#e1effa"
                  zIndex={0}
                  sx={{ transition: "all .2s" }}
                />
              </Stack>
            );
          })
        : null}
    </Stack>
  );
}
