import React, { Fragment} from "react";
import styles from "./Header.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import Dropdown from "react-dropdown";
import {
  HiOutlineChevronDown,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineChevronUp,
} from "react-icons/hi";
import CardContainer from "../../../Components/CardContainer";

export default function HeaderCard(props) {
  let {
    selected,
    title,
    subTitle,
    percentageValue,
    onClick,
    selectedTab,
    mainColor,
    secondaryColor,
    data,
    setSelectedYear,
  } = props;

  return (
    <CardContainer
      style={{ width: selected == selectedTab && "100%" }}
      className={styles.card}
    >
      <div className={styles.cardHeader}>
        <h3>{title}</h3>
        <h4>{subTitle}</h4>
      </div>
      {selected !== selectedTab && <div className={styles.progress}>
        <CircularProgressbar
          styles={buildStyles({
            pathColor: mainColor,
            trailColor: secondaryColor,
            textSize: 26,
            textColor: mainColor,
          })}
          strokeWidth={12}
          value={percentageValue}
          text={`${parseInt(percentageValue)}%`}
        />
      </div>}

      {selected == selectedTab && (
        <Fragment>
          <AreaChart
            width={600}
            height={200}
            data={data.map((i) => {
              return {
                name: i.month,
                value: selectedTab == 'toxin' ? i.toxin : selectedTab == 'cultex' ? i.cultex : 0,
              };
            })}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {/* <XAxis dataKey="name" /> */}
            {/* <YAxis /> */}
            <Area
              type="monotone"
              dataKey="value"
              stroke={mainColor}
              fill={secondaryColor}
            />
          </AreaChart>

          <div className={styles.cardFilterContainer}>
            <Dropdown
              placeholder="2023"
              className={styles.dropdownContainer}
              placeholderClassName={styles.dropdownPlaceholder}
              arrowClosed={
                <span>
                  <HiOutlineChevronDown color="#6494DF" size={14} />
                </span>
              }
              arrowOpen={
                <span>
                  <HiOutlineChevronUp color="#6494DF" size={14} />
                </span>
              }
              arrowClassName={styles.dropdownArrow}
              controlClassName={styles.dropdown}
              menuClassName={styles.dropdownMenu}
              options={['2023','2022','2021','2020']}
              onChange={(e)=>setSelectedYear(e.value)}
            />
          </div>
        </Fragment>
      )}

      {selected !== selectedTab ? (
        <HiOutlineChevronRight
          onClick={onClick}
          className={styles.cardOpenIcon}
          color="#6C6C6C"
          size={30}
        />
      ) : (
        <HiOutlineChevronLeft
          onClick={onClick}
          className={styles.cardOpenIcon}
          color={mainColor}
          size={30}
        />
      )}
    </CardContainer>
  );
}
