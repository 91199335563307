import axios from "axios";
import React, { useState } from "react";
import supportImage from "../../Assets/supportImage.svg";
import PageWrapper from "../../Layout/PageWrapper";
import CardContainer from "../../Components/CardContainer";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Button, Input, Modal } from "antd";
import { colors } from "../../utils/constants";
import TNC from "./Components/TNC";

export default function Support() {
  const tabs = ["Terms & Conditions", "Privacy Policy", "Licenses"];

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [creating, setCreating] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const _submitMessage = async () => {
    if (subject == "" || message == "")
      return alert("Please enter a topic and a message before continuing.");
    setCreating(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const data = {
      subject,
      message,
    };
    let res = await axios.post(
      process.env.REACT_APP_URL + "submitMessage",
      data,
      options
    );
    if (res.status == 200) {
      setMessage("");
      setSubject("");
      setCreating(false);
      setSuccessModal(true);
    }
  };

  return (
    <PageWrapper hideRightCards>
      <Modal footer={null} open={successModal}>
        <Stack gap={1}>
          <Typography fontSize={18} fontWeight={500}>
            Message sent
          </Typography>
          <Typography fontSize={14} fontWeight={400}>
            We appreciate your effort in making us right and suggest ways to
            improve. Our team is on it and will get back to you.
          </Typography>
          <Button
            onClick={() => setSuccessModal(false)}
            style={{ marginTop: 20 }}
            type="primary"
          >
            OK
          </Button>
        </Stack>
      </Modal>

      <CardContainer>
        <Grid container>
          {!isSmallScreen && (
            <Grid
              sx={{
                img: { width: "50%", mt: 2, mx: "auto", display: "block" },
              }}
              item
              xs={12}
              md={6}
            >
              <Typography fontSize={40} fontWeight={600}>
                Need Support?
              </Typography>
              <Typography fontSize={22} fontWeight={400}>
                You are in the right space
              </Typography>

              <img src={supportImage} alt="support" />
            </Grid>
          )}

          <Grid md={6} item xs={12}>
            <Stack gap={3}>
              <Typography fontSize={20} fontWeight={500}>
                Send us a Message
              </Typography>

              <Box>
                <Typography mb={0.5} fontSize={14} fontWeight={500}>
                  Topic*
                </Typography>
                <Input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Enter Your Topic"
                  type="text"
                />
              </Box>

              <Box>
                <Typography mb={0.5} fontSize={14} fontWeight={500}>
                  Message*
                </Typography>
                <Input.TextArea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message"
                  rows="4"
                />
              </Box>

              <Button
                loading={creating}
                disableElevation
                type="primary"
                style={{
                  backgroundColor: colors.primaryThemeColor,
                  marginLeft: "auto",
                }}
                onClick={_submitMessage}
              >
                Send
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </CardContainer>

      <Stack flexDirection="row" alignItems="center" gap={2} mb={2}>
        {tabs.map((tab) => (
          <Typography
            sx={{ cursor: "pointer" }}
            fontSize={14}
            fontWeight={500}
            color={
              activeTab == tab ? colors.primaryThemeColor : colors.lightGrey
            }
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </Typography>
        ))}
      </Stack>

      {activeTab == "Terms & Conditions" && <TNC />}
    </PageWrapper>
  );
}
