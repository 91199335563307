import { ButtonBase, Grid, Modal, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../../utils/constants';
import verysad from '../../../Assets/emojis/new/verysad.png';
import sad from '../../../Assets/emojis/new/sad.png';
import neutral from '../../../Assets/emojis/new/neutral.png';
import happy from '../../../Assets/emojis/new/happy.png';
import veryhappy from '../../../Assets/emojis/new/veryhappy.png';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: colors.WHITE,
	borderRadius: '8px',
};

const CustomButton = ({ buttonText, bordered, onClick }) => {
	return (
		<ButtonBase
			onClick={onClick}
			sx={{
				width: 200,
				bgcolor: bordered ? '#fff' : '#18024A',
				color: bordered ? '#18024A' : '#fff',
				height: 40,
				borderRadius: 0.5,
				fontSize: 14,
				fontWeight: 500,
				borderColor: '#18024A',
			}}
		>
			{buttonText}
		</ButtonBase>
	);
};

export default function SuccessModal({ open, onClose }) {
	const small = useMediaQuery('(max-width:600px)');

	const moods = [
		{
			label: 'Very Sad',
			name: verysad,
			code: 'verysad',
		},
		{
			label: 'Sad',
			name: sad,
			code: 'sad',
		},

		{
			label: 'Neutral',
			name: neutral,
			code: 'neutral',
		},
		{
			label: 'Happy',
			name: happy,
			code: 'happy',
		},
		{
			label: 'Very Happy',
			name: veryhappy,
			code: 'veryhappy',
		},
	];

	const [selectedMood, setSelectedMood] = useState(false);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack width={small ? '90%' : 'fit-content'} sx={style}>
				<Stack p={small ? 4 : 6} gap={4}>
					<Typography textAlign="center" fontWeight={600} fontSize={16} color="#18024A">
						How would you rate your experience with REMO
					</Typography>

					<Stack borderRadius={50} bgcolor="#18024A" height={72} px={small ? 2 : 4} alignItems="center" justifyContent="center">
						<Grid container alignItems="center" justifyContent="space-between">
							{moods.map((mood) => (
								<Grid item xs={2}>
									<Stack
										alignItems="center"
										justifyContent="center"
										onClick={() => setSelectedMood(mood.code)}
										sx={{ cursor: 'pointer', opacity: selectedMood === mood.code ? 1 : 0.4 }}
									>
										<img width={40} src={mood.name} alt="mood" />
									</Stack>
								</Grid>
							))}
						</Grid>
					</Stack>
				</Stack>

				<Stack bgcolor="#f5f2fc" borderRadius="50px 50px 0 0" p={small ? 4 : 6} gap={3}>
					<Typography textAlign="center" fontSize={18} fontWeight={600} color="#18024A">
						Would you be interested in trying Remo with the employees in your organization?
					</Typography>
					<Stack direction="row" alignItems="center" justifyContent="center" gap={4}>
						<CustomButton onClick={onClose} buttonText="Sure" />
						<CustomButton onClick={onClose} bordered buttonText="Never Mind" />
					</Stack>
				</Stack>
			</Stack>
		</Modal>
	);
}
