import React from 'react'
import { InfinitySpin, Oval, ProgressBar, Puff, Vortex, Watch } from 'react-loader-spinner'
import styles from "./Styles/Loader.module.css"

export default function Loader() {
  return (
    <div>
        <div className={styles.overlay}></div>
        <div className={styles.loader}>
        {/* <Watch
            height="40"
            width="40"
            radius="48"
            color="#fff"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /> */}
          <ProgressBar
            height="80"
            width="80"
            borderColor = '#fff'
            barColor = '#2262C6'
          />
        </div>
    </div>
  )
}
