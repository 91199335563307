export const mentionStylesPost = {
  width: "100%",
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
      maxHeight: 400,
      overflowY: "scroll",
      borderRadius: 4,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#dee7f5",
      },
    },
  },
};
