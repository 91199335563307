import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { HiOutlineFilter } from "react-icons/hi";

export default function PageTitle({
  setAnchorElFilter,
  title,
  subTitle,
  titleStyle,
  subTitleStyle,
}) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Stack>
        <Typography
          fontWeight={500}
          fontSize={isSmallScreen ? 16 : 20}
          color="#383535"
          sx={titleStyle}
        >
          {title}
        </Typography>
        <Typography
          fontSize={isSmallScreen ? 12 : 13}
          color="#828282"
          sx={subTitleStyle}
        >
          {subTitle}
        </Typography>
      </Stack>

      {typeof setAnchorElFilter == "function" && (
        <HiOutlineFilter
          cursor="pointer"
          onClick={(e) => {
            if (typeof setAnchorElFilter == "function") {
              setAnchorElFilter(e.currentTarget);
            }
          }}
          size={22}
          color="#383535"
        />
      )}
    </Stack>
  );
}
