import React, { useEffect } from "react";
import Card from "./Components/Card";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import PageWrapper from "../../Layout/PageWrapper";
import CardContainer from "../../Components/CardContainer";

export default function PostDescription() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const [item, setItem] = useState({});
  const [commentText, setCommentText] = useState("");

  const [navigatedComment, setNavigatedComment] = useState("");

  const getPostById = async () => {
    setLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let res = await axios.get(
      process.env.REACT_APP_URL + "post/" + id,
      options
    );
    if (res.status == 200) {
      setItem(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPostById();
  }, []);

  useEffect(() => {
    if (location?.state?.commentId !== null) {
      setNavigatedComment(location?.state?.commentId);
      setTimeout(() => {
        setNavigatedComment("");
      }, 3000);
    }
  },[]);

  return (
    <PageWrapper>
      <CardContainer>
        <Card
          item={item}
          editable
          setLoading={setLoading}
          fetch={getPostById}
          // setReportId={setReportId}
          // setRepostItem={setRepostItem}
          // setRepostModalVisible={setRepostModalVisible}
          setCommentText={setCommentText}
          commentText={commentText}
        />
      </CardContainer>
    </PageWrapper>
  );
}
