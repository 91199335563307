import Input from "../Components/Input";
import { useState } from "react";
import axios from "axios";
import { Link, Stack, Typography } from "@mui/material";
import LoginCard from "./Components/LoginCard";
import LoginHeader from "./Components/LoginHeader";
import { Button } from "antd";
import { colors } from "../utils/constants";
import { ROUTES } from "../Routes/pathName";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [errorVisible, setErrorVisible] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const _forgetPassword = async (e) => {
    e.preventDefault();
    if (!data.email) {
      return setErrorVisible("Please enter a valid email address.");
    }
    try {
      setLoading(true);
      let res = await axios.post(process.env.REACT_APP_URL + "forget", data);
      if (res.status == 200) {
        setLoading(false);
        setSuccessVisible("Verification mail has been sent to the email");
        setTimeout(() => {
          setSuccessVisible(false);
        }, 3000);
        setData({ email: "", password: "" });
        localStorage.clear();
      } else if (res.status == 202) {
        setLoading(false);
        setErrorVisible(res.data.msg);
        setTimeout(() => {
          setErrorVisible(false);
        }, 3000);
      }
    } catch (err) {
      if (err) {
        setLoading(false);
        setErrorVisible(err.response.data);
        setTimeout(() => {
          setErrorVisible(false);
        }, 3000);
      }
    }
  };

  return (
    <LoginCard>
      <LoginHeader
        title="Reset Password"
        subTitle="Enter your email to receive a reset link"
      />

      <Input
        label="Email"
        onChange={(e) => setData({ ...data, email: e.target.value })}
        value={data.email}
        mb={isLogin ? 48 : 0}
        type="email"
        placeholder=""
      />

      <Stack height={2} justifyContent="center">
        <Typography fontSize={14} fontWeight={500} color={colors.themeRed}>
          {successVisible}
        </Typography>
      </Stack>

      <Button
        size="large"
        type="primary"
        loading={loading}
        onClick={_forgetPassword}
      >
        Send Verification Link
      </Button>

      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={0.5}
      >
        <Typography fontSize={14}>Remember your password?</Typography>
        <Link
          onClick={() => navigate(ROUTES.LOGIN)}
          sx={{ cursor: "pointer", fontSize: 14 }}
        >
          Login
        </Link>
      </Stack>
    </LoginCard>
  );
}

export default ForgotPassword;
