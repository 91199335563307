import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./Styles/Broadcast.module.css";
import axios from "axios";
import moment from "moment";
import ButtonLoader from "../../Components/ButtonLoader";
import MediaInputs from "./Components/MediaInputs";
import { IoIosRemoveCircle } from "react-icons/io";
import PageWrapper from "../../Layout/PageWrapper";
import PageTitle from "../../Components/PageTitle";
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Button, Input } from "antd";
import CardContainer from "../../Components/CardContainer";
import { colors } from "../../utils/constants";

export default function Broadcast() {
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);

  const [allMessages, setAllMessages] = useState([]);

  const [postType, setPostType] = useState("");
  const [image, setImage] = useState({});

  const ref = useRef();
  const ovelayRef = useRef();
  const [successVisible, setSuccessVisible] = useState(false);

  const _PostBroadcast = async () => {
    if (title == "" || message == "" || startDate == "" || endDate == "")
      return alert("Please fill all the details before posting");
    setCreating(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      title,
      message,
      startDate,
      endDate,
      media: image,
    };
    try {
      let res = await axios.post(
        process.env.REACT_APP_URL + "createBroadcastMessage",
        data,
        options
      );
      if (res.status == 200) {
        ref.current.style.transform = "translate(-50%,-50%)";
        ovelayRef.current.style.display = "block";
        setSuccessVisible("Your broadcast has been created successfully!");
        setMessage("");
        setTitle("");
        setStartDate("");
        setEndDate("");
        setCreating(false);
        _getBroadcastMessages();
        setImage({});
        setPostType("");
      }
    } catch (err) {
      console.log(err);
      setCreating(false);
    }
  };

  const _updateBroadcast = async (id) => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let data = {
      id,
      status: "ended",
    };
    try {
      let res = await axios.put(
        process.env.REACT_APP_URL + "updateBroadcastMessage",
        data,
        options
      );
      if (res.status == 200) {
        _getBroadcastMessages();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const _getBroadcastMessages = async () => {
    setLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getBroadcastMessages",
      options
    );
    if (res.status == 200) {
      let sortedArr = res.data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setAllMessages(sortedArr);
      setLoading(false);
    }
  };

  const renderStatus = (start, end, status) => {
    if (moment(end).format() < moment().format() || status == "ended") {
      return "Ended";
    } else if (moment(start).format() > moment().format()) {
      return "Scheduled";
    } else if (
      moment(start).format() < moment().format() &&
      moment(end).format() > moment().format()
    ) {
      return "Live";
    }
  };

  useEffect(() => {
    _getBroadcastMessages();
  }, []);

  return (
    <PageWrapper hideRightCards>
      {/* <Fragment>
        <div ref={ovelayRef} className={styles.mmodalOverlay}></div>
        <div ref={ref} className={styles.mmodal}>
          <h2>Success</h2>
          <h3 className={styles.success}>{successVisible}</h3>

          <div className={styles.mfooterBtns}>
            <button
              onClick={() => {
                ref.current.style.transform = "translate(58vw,-50%)";
                ovelayRef.current.style.display = "none";
                setSuccessVisible(false);
              }}
              className={styles.saveBtn}
            >
              OK
            </button>
          </div>
        </div>
      </Fragment> */}

      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <PageTitle
            titleStyle={{ fontSize: 26, color: colors.primaryThemeColor }}
            title="Broadcast Message"
            subTitle="Know the cultural trends of your organisation"
          />
          <CardContainer gap={2} bgcolor="#E0EAF9">
            <Stack flex={1}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={colors.tableBlack}
              >
                Title
              </Typography>
              <textarea
                className={styles.textarea}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                rows="2"
                placeholder="Add title of your message"
              />
            </Stack>

            <Stack flex={1}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={colors.tableBlack}
              >
                Description
              </Typography>
              <textarea
                className={styles.textarea}
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Add title of your message"
              />
            </Stack>

            <MediaInputs
              postType={postType}
              setPostType={setPostType}
              image={image}
              setImage={setImage}
            />

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <div className={styles.dateInput}>
                  <input
                    name="start_date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    type="datetime-local"
                  />
                  <div className={styles.dateContent}>
                    {startDate == "" ||
                    startDate == undefined ||
                    startDate == null ? (
                      <h3>Start Date</h3>
                    ) : (
                      <h3>{moment(startDate).format("L LT")}</h3>
                    )}
                  </div>
                </div>

                <div className={styles.dateInput}>
                  <input
                    name="start_date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    type="datetime-local"
                  />
                  <div className={styles.dateContent}>
                    {endDate == "" ||
                    endDate == undefined ||
                    endDate == null ? (
                      <h3>End Date</h3>
                    ) : (
                      <h3>{moment(endDate).format("L LT")}</h3>
                    )}
                  </div>
                </div>
              </Stack>

              <Button
                type="primary"
                size="large"
                loading={creating}
                onClick={!creating ? _PostBroadcast : null}
              >
                Post
              </Button>
            </Stack>
          </CardContainer>
        </Grid>

        <Grid item xs={5.5}>
          <TableContainer
            sx={{ maxHeight: "calc(100vh - 110px)" }}
            component={Paper}
          >
            <Table stickyHeader>
              <TableHead
                sx={{ "& .MuiTableCell-root": { bgcolor: "#E0EAF9" } }}
              >
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allMessages.map((i) => (
                  <TableRow
                    key={i.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      {i.title && i.title.length > 20
                        ? `${i.title.substring(0, 20)}...`
                        : i.title || "N/A"}
                    </TableCell>
                    <TableCell>{moment(i.endDate).format("lll")}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          color:
                            renderStatus(i.startDate, i.endDate, i.status) ==
                            "Scheduled"
                              ? "#00AA44"
                              : renderStatus(
                                  i.startDate,
                                  i.endDate,
                                  i.status
                                ) == "Ended"
                              ? "#FF0000"
                              : "#000",
                        }}
                        className={`${styles.broadcastStatus} ${
                          renderStatus(i.startDate, i.endDate, i.status) ==
                            "Live" && styles.liveStatus
                        }`}
                      >
                        {renderStatus(i.startDate, i.endDate, i.status) ==
                          "Live" && <div className={styles.liveDot} />}
                        {renderStatus(i.startDate, i.endDate, i.status)}
                        {renderStatus(i.startDate, i.endDate, i.status) ==
                          "Live" && (
                          <IoIosRemoveCircle
                            onClick={(id) => _updateBroadcast(i.id)}
                            className={styles.endBtn}
                            size={24}
                            style={{ cursor: "pointer" }}
                            color="red"
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
