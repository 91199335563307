import Input from "../Components/Input";
import { useState } from "react";
import axios from "axios";
import { avatars } from "../Components/Avatars";
import { Link, Stack, Typography } from "@mui/material";
import { colors } from "../utils/constants";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../Routes/pathName";
import LoginHeader from "./Components/LoginHeader";
import LoginCard from "./Components/LoginCard";

function Signup() {
  const [errorVisible, setErrorVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);

  const _Signup = async (e) => {
    e.preventDefault();

    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    if (email == "") {
      setErrorVisible("Field cannot be empty!");
      setTimeout(() => {
        setErrorVisible(false);
      }, 4000);
      return;
    }

    let regex = new RegExp(
      /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
      "gm"
    );
    if (!regex.test(email)) {
      setErrorVisible("Please enter a valid email");
      setTimeout(() => {
        setErrorVisible(false);
      }, 4000);
      return;
    }

    if (
      email.split("@")[1].includes("gmail") ||
      email.split("@")[1].includes("yahoo") ||
      email.split("@")[1].includes("outlook") ||
      email.split("@")[1].includes("hotmail")
    ) {
      setErrorVisible("Continue with your company email");
      setTimeout(() => {
        setErrorVisible(false);
      }, 4000);
      return;
    }

    let data = {
      accountName: email,
      email,
      imagePath: avatars[0],
    };

    try {
      setLoading(true);
      let res = await axios.post(
        process.env.REACT_APP_URL + "createaccount",
        data,
        options
      );
      if (res.status == 200) {
        setSuccessVisible(res.data.msg);
        setLoading(false);
        setEmail("");
      } else if (res.status == 202) {
        setErrorVisible(res.data.msg);
        setTimeout(() => {
          setErrorVisible(false);
        }, 4000);
        setLoading(false);
      }
    } catch (err) {
      if (err) {
        setLoading(false);
        setErrorVisible(err.response.data);
        setTimeout(() => {
          setErrorVisible(false);
        }, 4000);
      }
    }
  };

  return (
    <LoginCard>
      <Modal
        onOk={() => setSuccessVisible(false)}
        closeIcon={false}
        open={successVisible == "" ? false : true}
        onCancel={() => setSuccessVisible(false)}
      >
        <Stack gap={2}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={colors.successGreen}
          >
            {successVisible}
          </Typography>
        </Stack>
      </Modal>

      <LoginHeader title="Sign Up" subTitle="Register yourself Now!" />

      <Stack>
        <Input
          label="Company Email"
          autocomplete="off"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          mb={30}
          type="email"
          placeholder=""
        />
        <Stack height="48px" justifyContent="center">
          <Typography fontSize={14} fontWeight={500} color={colors.themeRed}>
            {errorVisible}
          </Typography>
        </Stack>
      </Stack>

      <Button loading={loading} type="primary" size="large" onClick={_Signup}>
        Send Link
      </Button>

      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={0.5}
      >
        <Typography fontSize={14}>Already have an account?</Typography>
        <Link
          onClick={() => navigate(ROUTES.LOGIN)}
          sx={{ cursor: "pointer", fontSize: 14 }}
        >
          Login
        </Link>
      </Stack>
    </LoginCard>
  );
}

export default Signup;
