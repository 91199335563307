import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./Styles/Poll.module.css";
import axios from "axios";
import Loader from "../../Components/Loader";
import { SearchContext } from "../../Context/SearchContext";
import TabsComponent from "../../Components/TabsComponent";
import PageTitle from "../../Components/PageTitle";
import PageWrapper from "../../Layout/PageWrapper";
import CardContainer from "../../Components/CardContainer";
import Card from "./Components/Card";
import OngoingPoll from "../../Components/Polls/OngoingPoll";
import PollStats from "../../Components/Polls/PollStats";
import { useSelector } from "react-redux";

export default function Poll() {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Recent Polls");

  const { searchInput, setSearchInput } = useContext(SearchContext);

  const [recentPolls, setRecentPolls] = useState([]);
  const [ongoingPolls, setOngoingPolls] = useState([]);
  const [myPolls, setMyPolls] = useState([]);

  const [deleteModal, setDeleteModal] = useState("");

  const ref = useRef();
  const ovelayRef = useRef();

  const godsEye = useSelector((state) => state.app.godsEye);

  const _getAllPolls = async () => {
    setLoading(true);
    const headerOptions = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + `poll/getpollquestions?public=${godsEye}`,
      headerOptions
    );
    if (res.status == 200) {
      setRecentPolls(res.data.recent);
      setOngoingPolls(res.data.ongoing);
      setMyPolls(res.data.myPolls);
      setLoading(false);
    }
  };

  useEffect(() => {
    _getAllPolls();
  }, [godsEye]);

  const _deletePoll = async () => {
    const headerOptions = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.delete(
      process.env.REACT_APP_URL + "deletePoll/" + deleteModal,
      headerOptions
    );
    if (res.status == 200) {
      ref.current.style.transform = "translate(55vw,-50%)";
      ovelayRef.current.style.display = "none";
      setDeleteModal("");
      _getAllPolls();
    }
  };

  return (
    <>
      {loading && <Loader />}

      {/* delete modal */}
      <Fragment>
        <div ref={ovelayRef} className={styles.modalOverlay}></div>
        <div ref={ref} className={styles.modal}>
          <h2>Delete Poll?</h2>
          <h3 style={{ textAlign: "center" }}>
            Are you sure you want to delete this poll? This action cannot be
            reversed.
          </h3>

          <div className={styles.footerBtns}>
            <button
              onClick={() => {
                ref.current.style.transform = "translate(55vw,-50%)";
                ovelayRef.current.style.display = "none";
              }}
              className={styles.cancelBtn}
            >
              Don't Delete
            </button>
            <button onClick={_deletePoll} className={styles.saveBtn}>
              Yes, Delete
            </button>
          </div>
        </div>
      </Fragment>
      {/* --------- */}

      <PageWrapper>
        <TabsComponent
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={["Recent Polls", "Ongoing Polls", "My Polls"]}
        />

        <PageTitle
          title={activeTab}
          subTitle={
            activeTab == "Recent Polls"
              ? "Your Latest Feed"
              : activeTab == "Ongoing Polls"
              ? "Polls that are active"
              : activeTab == "My Polls"
              ? "Polls created by me"
              : ""
          }
        />

        {activeTab == "Recent Polls" &&
          recentPolls?.length !== 0 &&
          recentPolls?.map((item) => {
            return (
              (searchInput == "" ||
                item.poll_title.toLowerCase().includes(searchInput) ||
                item.question_desc.toLowerCase().includes(searchInput)) && (
                <CardContainer>
                  <Card
                    editable
                    setLoading={setLoading}
                    fetch={_getAllPolls}
                    item={item}
                  />
                  <OngoingPoll item={item} fetch={_getAllPolls} />
                  <PollStats item={item} />
                </CardContainer>
              )
            );
          })}

        {activeTab === "Ongoing Polls" &&
          ongoingPolls?.length !== 0 &&
          ongoingPolls?.map((item) => {
            return (
              (searchInput == "" ||
                item.poll_title.toLowerCase().includes(searchInput) ||
                item.question_desc.toLowerCase().includes(searchInput)) && (
                <CardContainer>
                  <Card
                    editable
                    setLoading={setLoading}
                    fetch={_getAllPolls}
                    item={item}
                  />
                  <OngoingPoll item={item} fetch={_getAllPolls} />
                  <PollStats item={item} />
                </CardContainer>
              )
            );
          })}

        {activeTab == "My Polls" &&
          myPolls?.length !== 0 &&
          myPolls?.map((item) => {
            return (
              (searchInput == "" ||
                item.poll_title.toLowerCase().includes(searchInput) ||
                item.question_desc.toLowerCase().includes(searchInput)) && (
                <CardContainer>
                  <Card
                    editable
                    setLoading={setLoading}
                    fetch={_getAllPolls}
                    item={item}
                  />
                  <OngoingPoll item={item} fetch={_getAllPolls} />
                  <PollStats item={item} />
                </CardContainer>
              )
            );
          })}

        <div className={styles.endOfPolls}>
          <p>No more Polls</p>
          <p>End of feed</p>
        </div>
      </PageWrapper>
    </>
  );
}
