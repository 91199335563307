import { Stack, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../utils/constants";

export default function Tag({ trait }) {
  return (
    <Stack
      borderRadius={10}
      bgcolor="#C8D8F7"
      height="30px"
      width="fit-content"
      px={2}
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: "#C8D8F7" }}
    >
      <Typography
        fontSize={13}
        fontWeight={500}
        color={colors.primaryThemeColor}
      >
        {trait}
      </Typography>
    </Stack>
  );
}
