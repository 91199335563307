import React from 'react'
import styles from "./Styles/CustomDropdown.module.css"
import {HiOutlineChevronDown, HiOutlineChevronUp} from "react-icons/hi"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function CustomDropdown(props) {
  return (
    <Dropdown
      className={styles.dropdownContainer}
      placeholderClassName={styles.dropdownPlaceholder}
      arrowClosed={<span><HiOutlineChevronDown color="#000" size={20} /></span>}
      arrowOpen={<span><HiOutlineChevronUp color="#000" size={20} /></span>}
      arrowClassName={styles.dropdownArrow}
      controlClassName={props.white ? styles.whiteDropdown : styles.dropdown}
      menuClassName={styles.dropdownMenu}
      {...props}
            />
  )
}
