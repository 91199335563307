import React from "react";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchContext } from "../../Context/SearchContext";
import { useContext } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import Loader from "../../Components/Loader";
import CustomSwitch from "../../Components/CustomSwitch";
import CreateUser from "./Components/CreateUser";
import AddBulkUsers from "./Components/AddBulkUsers";
import PageWrapper from "../../Layout/PageWrapper";
import {
  Divider,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { colors } from "../../utils/constants";
import { Avatar, Button, Popconfirm } from "antd";
import AdminStatsCard from "./Components/AdminStatsCard";
import { ChevronLeft } from "@mui/icons-material";

export default function AccountUsers() {
  const [users, setUsers] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { searchInput, setSearchInput } = useContext(SearchContext);
  const [selectedUser, setSelectedUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    inactive: 0,
    lastmonth: 0,
    admins: 0,
    banned: 0,
    emailverified: 0,
  });
  const [createUserModal, setCreateUserModal] = useState(false);
  const [createBulkModal, setCreateBulkModal] = useState(false);

  const _getAccountUsers = async () => {
    setLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_URL + "getaccountusers/" + location?.state?.id,
      options
    );
    if (res.status == 200) {
      let sortedArr = res?.data?.users?.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setLoading(false);
      setUsers(sortedArr);
      setStats({
        total: res.data.total,
        active: res.data.active,
        banned: res.data.banned,
        admins: res.data.admins,
        emailverified: res.data.emailverified,
        lastmonth: res.data.lastmonth,
      });
    }
  };

  const _updateUser = async () => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const body = {
      id: selectedUser?.id,
      role: !selectedUser?.roles ? "enduser" : selectedUser?.roles,
    };
    let res = await axios.put(
      process.env.REACT_APP_URL + "updateUser",
      body,
      options
    );
    if (res.status == 200) {
      setSelectedUser(false);
      _getAccountUsers();
    }
  };

  const _changeStatus = async (id, status) => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const body = {
      id,
      status,
    };
    let res = await axios.put(
      process.env.REACT_APP_URL + "updateUser",
      body,
      options
    );
    if (res.status == 200) {
      _getAccountUsers();
    }
  };

  useEffect(() => {
    _getAccountUsers();
  }, []);

  return (
    <PageWrapper hideRightCards>
      <Modal onClose={() => setCreateUserModal(false)} open={createUserModal}>
        <CreateUser
          setCreateUserModal={setCreateUserModal}
          fetch={_getAccountUsers}
        />
      </Modal>

      <Modal onClose={() => setCreateBulkModal(false)} open={createBulkModal}>
        <AddBulkUsers setCreateBulkModal={setCreateBulkModal} />
      </Modal>

      {loading && <Loader />}

      <Stack flexDirection="row" alignItems="center" gap={3}>
        <AdminStatsCard heading="Total User" value={stats?.total} />
        <AdminStatsCard heading="Active" value={stats?.active} />
        <AdminStatsCard heading="Admins" value={stats?.admins} />
        <AdminStatsCard heading="Email Verified" value={stats?.emailverified} />
        <AdminStatsCard heading="Banned" value={stats?.banned} />
        <AdminStatsCard heading="Last Month" value={stats?.lastmonth} />
      </Stack>

      <Stack
        gap={2}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <IconButton onClick={() => navigate(-1)} sx={{ p: 0.2 }}>
          <ChevronLeft sx={{ color: colors.tableBlack, fontSize: 34 }} />
        </IconButton>
        <Typography fontSize={24} fontWeight={500} color={colors.tableBlack}>
          All Users
        </Typography>
        <Divider sx={{ flex: 1 }} />
        <Button
          size="large"
          type="primary"
          onClick={() => setCreateBulkModal(true)}
        >
          Add Bulk Users
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => setCreateUserModal(true)}
        >
          Add User
        </Button>
      </Stack>

      <TableContainer sx={{ bgcolor: colors.WHITE }}>
        <Table>
          <TableHead sx={{ bgcolor: "#deecfa" }}>
            <TableCell>S.No</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Joining Date</TableCell>
            <TableCell>Email Verified</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Active</TableCell>
          </TableHead>

          <TableBody>
            {users.map((user, index) => {
              return (
                (searchInput == "" ||
                  user.email_Id.toLowerCase().includes(searchInput)) && (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Stack flexDirection="row" alignItems="center" gap={1}>
                        <Avatar
                          size="large"
                          src={
                            user.imagePath ||
                            "https://www.shareicon.net/data/512x512/2015/09/18/103159_user_512x512.png"
                          }
                        />
                        <Stack>
                          <Typography
                            fontSize={16}
                            fontWeight={500}
                            color={colors.tableBlack}
                          >
                            {user.email_Id}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color={colors.tableGrey}
                          >{`${user.first_name || ""} ${
                            user.last_name || ""
                          }`}</Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {moment(user.createdAt).format("lll")}
                    </TableCell>
                    <TableCell>
                      {user.email_verified ? (
                        <BsCheckCircleFill size={26} color="#2262C6" />
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell>
                      <Popconfirm
                        open={selectedUser.id === user.id}
                        title={
                          selectedUser?.roles === "admin"
                            ? "Remove Admin"
                            : "Make Admin"
                        }
                        description={
                          selectedUser?.roles === "admin"
                            ? "Are you sure you want to remove this user as admin?"
                            : "Are you sure you want to make this user admin?"
                        }
                        onConfirm={_updateUser}
                        onCancel={() => setSelectedUser(false)}
                        cancelText="Cancel"
                        okText={
                          selectedUser?.roles === "admin"
                            ? "Remove Admin"
                            : "Make Admin"
                        }
                      >
                        <CustomSwitch
                          checked={user.roles === "admin" ? true : false}
                          onChange={() => {
                            setSelectedUser(user);
                          }}
                        />
                      </Popconfirm>
                    </TableCell>
                    <TableCell>
                      <CustomSwitch
                        onChange={() => {
                          _changeStatus(user?.id, user?.status);
                        }}
                        checked={user.status === "active" ? true : false}
                      />
                    </TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </PageWrapper>
  );
}
