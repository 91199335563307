const initialState = {
  godsEye: false,
  allUsers: [],
  anonymous: false,
  karmaPoints: {},
  score: {},
  trending: [],
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USERS":
      return {
        ...state,
        allUsers: action.payload,
      };
    case "SET_ANON":
      return {
        ...state,
        anonymous: action.payload,
      };
    case "SET_KARMA":
      return {
        ...state,
        karmaPoints: action.payload,
      };
    case "GODS_EYE":
      return {
        ...state,
        godsEye: action.payload,
      };

    case "SET_SCORE":
      return {
        ...state,
        score: action.payload,
      };

    case "SET_TRENDING":
      return {
        ...state,
        trending: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
