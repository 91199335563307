import { Stack, Typography} from "@mui/material";
import { Button } from "antd";
import React from "react";
import { colors } from "../../../utils/constants";

export default function BannedModal({ setBannedModal }) {
  return (
    <Stack gap={4}>
      <Typography
        variant="h2"
        fontWeight={500}
        textAlign="center"
        color={colors.textRed}
        style={{ textAlign: "center", color: "red" }}
      >
        Warning!
      </Typography>
      <Typography variant="h6" style={{ textAlign: "center" }}>
        Your post contains words which are against our community guidelines.
        Please check your post and try again.
      </Typography>
      <Button
        style={{
          backgroundColor: colors.primaryThemeColor,
          color: colors.WHITE,
        }}
        size="large"
        onClick={() => {
          setBannedModal(false);
        }}
      >
        Okay, Understood!
      </Button>
    </Stack>
  );
}
