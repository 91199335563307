import { Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { colors } from "../../../utils/constants";
import { Button } from "antd";

export default function ReportModal({
  setLoading,
  reportId,
  fetch,
  setReportId,
}) {
  const [step, setStep] = useState("one");
  const [reasons, setReasons] = useState([
    "False information",
    "Hate speech or symbols",
    "Bullying or harassment",
    "Nudity or sexual activity",
    "Illegal activity",
  ]);
  const [selectedReasons, setSelectedReasons] = useState([]);

  const _reportPost = async () => {
    setLoading(true);
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const data = { reasons: selectedReasons };
    let res = await axios.put(
      process.env.REACT_APP_URL + "post/report/" + reportId,
      data,
      options
    );
    if (res.status === 200) {
      fetch();
      setReportId("");
    }
  };

  const onCancel = () => {
    setReportId("");
    setStep("one");
    setSelectedReasons([]);
  };

  return (
    <Stack gap={2}>
      <Typography fontSize={36} fontWeight={500} textAlign="center">
        {step == "one" ? "Report Content" : "Why are you reporting"}
      </Typography>
      <Typography textAlign="center" fontSize={16} color={colors.tableBlack}>
        {step == "one"
          ? "Thankyou for keeping the platform safe. Please confirm to have this post reviewed by a Moderator."
          : "Your report is anonymous."}
      </Typography>

      {step == "two" && (
        <Stack gap={1} my={2}>
          {reasons.map((r) => {
            return (
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Stack
                  border="1px solid"
                  borderColor={colors.tableBlack}
                  width="20px"
                  height="20px"
                  bgcolor={
                    selectedReasons.includes(r)
                      ? colors.tableBlack
                      : colors.WHITE
                  }
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={1}
                  onClick={() => {
                    if (!selectedReasons.includes(r)) {
                      setSelectedReasons([...selectedReasons, r]);
                    } else {
                      setSelectedReasons(
                        selectedReasons.filter((i) => i !== r)
                      );
                    }
                  }}
                >
                  <BsCheck color="#fff" size={22} />
                </Stack>
                <Typography fontSize={16} color={colors.tableBlack}>
                  {r}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      )}

      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        {step == "one" ? (
          <>
            <Button size="large" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: colors.primaryThemeColor,
                color: colors.WHITE,
              }}
              size="large"
              onClick={() => {
                setStep("two");
              }}
            >
              Continue
            </Button>
          </>
        ) : (
          <>
            <Button size="large" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: colors.primaryThemeColor,
                color: colors.WHITE,
              }}
              size="large"
              onClick={_reportPost}
            >
              Report
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
}
