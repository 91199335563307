import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { SearchContext } from '../../Context/SearchContext';
import Loader from '../../Components/Loader';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import CreateAccount from './Components/CreateAccount';
import CustomSwitch from '../../Components/CustomSwitch';
import PageWrapper from '../../Layout/PageWrapper';
import {
	Alert,
	Divider,
	IconButton,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { colors } from '../../utils/constants';
import { Button, Modal } from 'antd';
import AdminStatsCard from './Components/AdminStatsCard';

export default function SuperAdmin() {
	const [accounts, setAccounts] = useState([]);
	const [stats, setStats] = useState({
		total: 0,
		active: 0,
		inactive: 0,
		lastmonth: 0,
	});
	const navigate = useNavigate();
	const { searchInput, setSearchInput } = useContext(SearchContext);
	const [createAccModal, setCreateAccModal] = useState(false);
	const [mailSent, setMailSent] = useState(false);
	const [sending, setSending] = useState(false);

	const _getAllAccounts = async () => {
		const options = {
			headers: {
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		};
		let res = await axios.get(process.env.REACT_APP_URL + 'getallaccounts', options);
		if (res.status == 200) {
			let sortedArr = res?.data?.accounts?.sort(function (a, b) {
				return new Date(b.createdAt) - new Date(a.createdAt);
			});
			setAccounts(sortedArr);
			setStats({
				total: res?.data?.total,
				active: res?.data?.active,
				inactive: res?.data?.inactive,
				lastmonth: res?.data?.lastmonth,
			});
		}
	};

	const _updateAccount = async (id) => {
		const options = {
			headers: {
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		};
		const data = {
			id,
		};
		let res = await axios.put(process.env.REACT_APP_URL + 'updateAccount', data, options);
		if (res.status == 200) {
			_getAllAccounts();
		}
	};

	const sendFeedbackMail = async (accountId) => {
		setSending(true);
		const options = {
			headers: {
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		};
		const data = {
			accountId,
		};
		try {
			let res = await axios.post(process.env.REACT_APP_URL + 'feedback/sendFeedbackLink', data, options);
			if (res.status == 200) {
				setSending(false);
				setMailSent(true);
			}
		} catch (err) {
			console.log(err);
			setSending(false);
		}
	};

	useEffect(() => {
		_getAllAccounts();
	}, []);

	return (
		<PageWrapper hideRightCards>
			{sending && <Loader />}
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center ' }}
				open={mailSent}
				autoHideDuration={3000}
				onClose={() => setMailSent(false)}
			>
				<Alert sx={{ bgcolor: colors.successGreen }} severity="success" variant="filled">
					Email has been sent successfully
				</Alert>
			</Snackbar>

			<Modal open={createAccModal} footer={null} closeIcon={null}>
				<CreateAccount fetch={_getAllAccounts} setCreateAccModal={setCreateAccModal} />
			</Modal>

			<Stack flexDirection="row" alignItems="center" gap={3}>
				<AdminStatsCard heading="Total" value={stats?.total} />
				<AdminStatsCard heading="Active" value={stats?.active} />
				<AdminStatsCard heading="Inactive" value={stats?.inactive} />
				<AdminStatsCard heading="Last Month" value={stats?.lastmonth} />
			</Stack>

			<Stack gap={2} flexDirection="row" alignItems="center" justifyContent="space-between" mb={2}>
				<Typography fontSize={24} fontWeight={500} color={colors.tableBlack}>
					All Accounts
				</Typography>
				<Divider sx={{ flex: 1 }} />
				<Button size="large" type="primary" onClick={() => navigate('/dashboard/admin/super/campaign/users')}>
					Campaign Users
				</Button>
				<Button size="large" type="primary" onClick={() => navigate('/dashboard/admin/super/profanity')}>
					Profanity
				</Button>
				<Button size="large" type="primary" onClick={() => setCreateAccModal(true)}>
					Create Account
				</Button>
			</Stack>

			<TableContainer sx={{ bgcolor: colors.WHITE }}>
				<Table>
					<TableHead sx={{ bgcolor: '#deecfa' }}>
						<TableCell>S.No</TableCell>
						<TableCell>Account Name</TableCell>
						<TableCell>Creation Date</TableCell>
						<TableCell>Active</TableCell>
						<TableCell />
					</TableHead>

					<TableBody>
						{accounts.map(
							(acc, index) =>
								(searchInput == '' || acc.accountName.toLowerCase().includes(searchInput)) && (
									<TableRow>
										<TableCell>{index + 1}</TableCell>
										<TableCell>
											<Typography
												width="fit-content"
												fontWeight={500}
												color={colors.primaryThemeColor}
												sx={{ cursor: 'pointer' }}
												onClick={() => {
													navigate('users', { state: { id: acc?.id } });
													setSearchInput('');
												}}
											>
												{acc.accountName}
											</Typography>
										</TableCell>
										<TableCell>{moment(acc.createdAt).format('lll')}</TableCell>
										<TableCell>
											<CustomSwitch
												onChange={() => {
													_updateAccount(acc?.id);
												}}
												checked={acc.status == 'active' ? true : false}
											/>
										</TableCell>
										<TableCell>
											<Tooltip title="Send Feedback Form">
												<IconButton onClick={() => sendFeedbackMail(acc?.id)}>
													<ForwardToInboxOutlinedIcon sx={{ fontSize: 20, color: colors.tableBlack }} />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								),
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</PageWrapper>
	);
}
