import { Input, Stack, Typography } from "@mui/material";
import { colors } from "../utils/constants";

function MyInput(props) {
  return (
    <Stack>
      {props.label && (
        <Typography fontSize={14} fontWeight={500} color={colors.tableGrey}>
          {props.label}
        </Typography>
      )}
      <Input style={{fontWeight:500}} {...props} />
    </Stack>
  );
}

export default MyInput;
